<fieldset class="petitioner">
  <legend class="petitioner--legend float-none w-auto p-2">{{'requestPetitionerTitle' | i18next}}</legend>
  <section class="userInfo" *ngIf="user">
    <div class="column">
      <div class="column--item">
        <p class="title">{{'requestPetitionerCompany' | i18next}}</p>
        <p class="ml">{{ user.enterprise }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'requestPetitioner' |i18next}}</p>
        <p class="ml">{{ user.fullName }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'requestPetitionerCenter' | i18next}}</p>
        <p class="ml">{{ user.costCenter }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'requestPetitionerDependency' | i18next}}</p>
        <p class="ml">{{ user.dependency }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'email' | i18next}}</p>
        <p class="ml">{{ user.email }}</p>
      </div>
    </div>
    <div class="column column2">
      <div class="column--item">
        <p class="title">{{'phone' | i18next}}</p>
        <p >{{ user.telephone }} <span *ngIf="hasTelephones"> | </span> {{user.mobile}}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'nis' | i18next}}</p>
        <p>{{ user.nis }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'requestPetitionerCategory' | i18next}}</p>
        <p>{{ user.category }}</p>
      </div>
      <div class="column--item">
        <p class="title">{{'requestPetitionerDatePlan' | i18next}}</p>
        <p>{{ user.planEntryDate}}</p>
      </div>
      <div class="column--item" *ngIf="user.groupType === 'PSS'">
        <p class="title">{{'requestPetitionerRetirementDate' | i18next}}</p>
        <p>{{ user.pssFinalDate}}</p>
      </div>
    </div>
  </section>
</fieldset>