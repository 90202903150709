<div class="content-close animated fadeIn fast">
  <img
    class="icon"
    src="./assets/icons/close-icon.png"
    alt="..."
    ngbAutofocus
    (click)="activeModal.dismiss('Cross click')"
  />
</div>

<div class="content animated fadeIn fast">
  <div class="title">
    {{ "subTitleOrderStatus" | i18next }}
  </div>
  <div class="paragraph1">
    {{'modalOrderInfo' | i18next}}
  </div>
  <div class="user-container">
    <ul *ngIf="infoUser">
      <li><b>{{'nis' | i18next}}:</b> {{ infoUser.nis }}</li>
      <li><b>{{'email' | i18next}}:</b> {{ infoUser.email }}</li>
      <li *ngIf="leasedVehicleRest">
        <b>{{'modalOrderReturnVehicle' | i18next}}</b> {{ leasedVehicleRest.model }} -
        {{ leasedVehicleRest.plateNumber }}
      </li>
      <li *ngIf="leasedVehicleRest">
        <b>{{'modalOrderKms' | i18next}}</b> {{ leasedVehicleRest.kms }} {{'Km' | i18next}}
      </li>
    </ul>
  </div>
  <div class="botonera">
    <button
      type="button"
      class="btn button-status-order back"
      (click)="cancelOperation()"
    >
      {{ "btnBack" | i18next }}
    </button>
    <button
      type="button"
      class="btn button-status-order confirm ml-5"
      [disabled]="clicked"
      (click)="createOrderData(); clicked = true"
    >
      {{ "buttonConfirm" | i18next }}
    </button>
  </div>
</div>
