<div class="content">
  <!-- Body -->
  {{ message }}
</div>
<div class="footer">
  <!-- footer -->
  <div class="btn" (click)="close()">
    {{'btnCancel' | i18next}}
  </div>
  <div class="btn submit" (click)="submit()">
    {{'buttonConfirm' | i18next}}
  </div>
</div>
