import { getDownloadableLink } from './Links';
export const createFileFullName = (fileName: string, fileExtension: string) =>
  `${fileName}.${fileExtension}`;

export const download = (
  blob: Blob,
  fileExtension: string,
  fileName: string
) => {
  const fileFullName = createFileFullName(fileName, fileExtension);
  const url = URL.createObjectURL(blob);
  const link = getDownloadableLink(url, fileFullName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const onLoadInputFile = (
  file: File,
  onFinish: (formData: FormData) => void,
  onError?: (err: string) => void
) => {
  try {
    const data: FormData = new FormData();
    data.append('image', file);

    onFinish(data);
  } catch (err: any) {
    onError!(err);
  }
};
