import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VehiclesService } from 'src/app/core/services/vehicles.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { ModalOrderStatusComponent } from '../modal-order-status/modal-order-status.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CampaingsVehicles } from 'src/app/shared/model/campaign.model';
import { LeaseInfoRestDTO } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-modal-return-vehicle',
  templateUrl: './modal-return-vehicle.component.html',
  styleUrls: ['./modal-return-vehicle.component.scss']
})
export class ModalReturnVehicleComponent implements OnInit{
  // CONFIG
  displayVehicleSelection = true;
  hasSelectedAVehicle = false;
  // CHECKERS
  hasKMSet = false;
  hasOccurredAnError = false;
  hasExceedTheAmountOfPipelineVehicles = false;
  // DATA
  assignedVehicles: CampaingsVehicles[] = [];
  vehiclesInPipeline: CampaingsVehicles[] = [];
  vehicle?: CampaingsVehicles;
  replacementAvailable= false;
  kms = 0;
  selectedVehicleType = '';
  loading = false;
  leaseInfo : LeaseInfoRestDTO = this.storageService.get('config') ? this.storageService.get('config').leaseInfo : {} ;
  retribution : any = '';
  error= false;
  modalContent?: NgbModalRef;
  continueBtn? : boolean;
  type = '';

  constructor(
    public activeModal: NgbActiveModal,
    private storageService: StorageService,
    private vehicleService: VehiclesService,
    private modalConfig: NgbModal,
    private fb : FormBuilder
  ) {}

  ngOnInit(): void { 
    this.initForm();
    this.onSelectVehicleType(this.form.value.vehicleType);
    if (this.disableArrBtn()) {
      this.form.get('vehicleType')?.setValue('endowment');
    } else if (this.disableDotBtn()) {
      this.form.get('vehicleType')?.setValue('leasing');
    }
    if (this.storageService.get('config') ) {
      this.leaseInfo = this.storageService.get('config').leaseInfo;
    }
   
  }

  form: FormGroup = this.fb.group({});
  tableForm : FormGroup = this.fb.group({
    tableCheckbox: [false]
  });

  initForm () : void {
    this.form = this.fb.group({
      vehicleType: [this.checkBtn(), [Validators.required]],
      requestType: ['replacement', [Validators.required]]
    });
  }

  getInPipelineVehicles = (vehicles: CampaingsVehicles[]) => vehicles.filter(vehicle => vehicle.inPipeline);
  getAssignedVehicles = (vehicles: CampaingsVehicles[]) => {
    this.assignedVehicles = vehicles.filter(vehicle => !vehicle.inPipeline);
  };

  isFirstAssignAvailable = () : boolean => {
    const max = this.selectedVehicleType === 'leasing' ? this.leaseInfo.maxVehiclesArrendamiento : this.leaseInfo.maxVehiclesDotacion;    
    return this.assignedVehicles.length + this.vehiclesInPipeline.length >= max;
  };
  
  isReplacementAvailable = (vehicles: CampaingsVehicles[]) : boolean => {
    return this.replacementAvailable = vehicles.some((vehicle: CampaingsVehicles) => !vehicle.inPipeline);
  };

  isTheCurrentSelectedVehicle(vehicle: CampaingsVehicles) {
    return this.vehicle && this.vehicle.vin === vehicle.vin;
  }

  checkBtn() {
    if(this.leaseInfo.maxVehiclesArrendamiento > 0 && this.leaseInfo.maxVehiclesDotacion === 0 || 
      this.leaseInfo.maxVehiclesArrendamiento > 0  && this.leaseInfo.maxVehiclesDotacion > 0) {
        return 'leasing';
    } else if (this.leaseInfo.maxVehiclesDotacion > 0 && this.leaseInfo.maxVehiclesArrendamiento === 0 ) {
      return 'endowment';
    }  else {
      return '';
    }
  }

  disableArrBtn(){
    return this.leaseInfo.maxVehiclesArrendamiento === 0 ?'disable-radio' : '';
  }

  disableDotBtn(){
    if ((this.leaseInfo.maxVehiclesDotacion === 0) || 
        this.form.value.requestType === 'firstAssign' && !this.isFirstAssignAvailable()) {
      return 'disable-radio';
    } else {
      return '';
    }
  }

  disableFirstAssign() {
    return this.isFirstAssignAvailable() ? 'disable-radio' : '';
  }

  onChangeVehicleType() {
    this.tableForm.reset();
    this.form.controls['requestType'].setValue('replacement');
  }

  onChangeRequestType(data: HTMLInputElement) {
    this.disableDotBtn();
    this.vehicle = undefined;
    data.value === 'firstAssign' ? this.continueBtn = true : this.continueBtn = false;
  }

  onSetVehicle(newVehicle: CampaingsVehicles) {
    if (this.isTheCurrentSelectedVehicle(newVehicle)) {
      this.vehicle = undefined;
      this.hasSelectedAVehicle = false;
    } else {
      this.vehicle = newVehicle;
      this.hasSelectedAVehicle = true;
    }
  }

  onSetKM($event : any) {
    this.kms = $event!.target.value;
    this.hasKMSet = Boolean(this.kms > 0);
  }

  goToSelectVehicle() {
    this.displayVehicleSelection = true;
  }

  goToSetKM() {
    if (!this.vehicle) {
      this.confirmOperation();
    } else {
      this.displayVehicleSelection = false;
    }
  }
  onSelectVehicleType(vehicleType: string) {
    this.loading = true;
    this.tableForm.reset();
    this.vehicle = undefined;
    this.hasExceedTheAmountOfPipelineVehicles = false;
     if (vehicleType !== null) {
       this.selectedVehicleType = vehicleType;
     } else {
       this.selectedVehicleType = '';
     }
     const nis = this.storageService.get('config') ? this.storageService.get('config').nis : '' ;
     this.storageService.removeItem('leasedVehicleRest');
     this.vehicleService.checkEmployeeCanRentMoreVehicles(nis, this.selectedVehicleType).subscribe({
       next: (resp) => {
        this.loading = false;
        this.vehiclesInPipeline = this.getInPipelineVehicles(resp);
        this.getAssignedVehicles(resp);   
        this.isReplacementAvailable(resp);
        const max = this.selectedVehicleType === 'leasing' ? this.leaseInfo.maxVehiclesArrendamiento : this.leaseInfo.maxVehiclesDotacion;
        if (this.vehiclesInPipeline.length >= max) {
          this.form.value.vehicleType === 'leasing' ? this.type = 'Dotación' : this.type = 'Arrendamiento'; 
          this.hasExceedTheAmountOfPipelineVehicles = true;
        } else {
          this.hasExceedTheAmountOfPipelineVehicles = false;
        }
       },
       error: () => {
        this.hasOccurredAnError = true;
       },
     });
   }

  confirmOperation() {
    if (this.vehicle) {
      this.storageService.set('leasedVehicleRest', {
        ...this.vehicle,
        kms: this.kms,
      });
    }
    this.activeModal.close();
    this.modalContent = this.modalConfig.open(ModalOrderStatusComponent, {
      centered: true,
      backdropClass: 'modal-order-status',
    });
    this.modalContent.componentInstance.modalData = this.assignedVehicles;
    this.modalContent.componentInstance.vehicleType = this.form.value.vehicleType;
  }

  // TODO: Move this to a HostListener
  numberOnly(event :any): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.error = true;
      return false;
    }
    this.error = false;
    return true;
  }

}
