import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-feedback',
  templateUrl: './modal-feedback.component.html',
  styleUrls: ['./modal-feedback.component.scss']
})
export class ModalFeedbackComponent implements OnInit {
  @Input() type?: string;
  @Input() msg?: string;

  icon?: any;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.icon = this.type === 'success' ? faCheckCircle : faTimesCircle;
  }

  close() {
    this.activeModal.close();
  }
}
