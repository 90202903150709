<ul ngbNav #nav="ngbNav" class="nav-tabs">
	<li  class="nav-item" [ngbNavItem]="finish.id" *ngFor="let finish of trimLevels">
		<a ngbNavLink class="nav-link">{{finish.trimLevelName}}</a>
		<ng-template ngbNavContent>
      <div class="container-stock">
        <app-equipment-finishes [trimLevels]="finish.trimLevelInfo" [trimLevelName]="finish.trimLevelName"></app-equipment-finishes>
       </div>
		</ng-template>
	</li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>