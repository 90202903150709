import { AfterViewInit, Component, OnInit } from '@angular/core';
import { StorageService } from '../../core/storage/storage.service';
import { User } from '../../shared/model/user.model';
import { VehiclesService } from '../../core/services/vehicles.service';
import { Vehicles, PccVehicle } from '../../shared/model/vehicle.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderModalComponent } from './order-modal/order-modal.component';
import { ConfirmationModalComponent } from './order-modal/confirmation-modal/confirmation-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';
import { delay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NewRequestForm, OrderRequest, PetitionerRequest, ReplacementVehicleRequest, RequestedVehicleRequest } from 'src/app/shared/model/order.model';
import { HttpErrorResponse } from '@angular/common/http';
import i18next from 'i18next';
import { ControlErrors } from 'src/app/shared/utils/generateError';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss']
})
export class NewRequestComponent implements OnInit, AfterViewInit {

  codeSeat = '';
  disableVehicle = false;
  errorPcc = false;
  errorPccText = '';
  errorKms = false;
  existVehicles = false;
  frameNumberAssign = '';
  retribution: any = {};
  typeTranslation = '';
  user! : User;
  vehicleInfodata!: PccVehicle;
  vehicleType = '';
  vehiclesArr: Vehicles[]= [];
  vehicleSelected!: Vehicles;
  vehicle: Vehicles = this.storageService.get('vehicleSustitution');
  newRequest! : OrderRequest;
  spinner = false;
  removeData = false;
  modalContent? : NgbModalRef;
  pssFinalDate! : Date;
  isNewRequest = 'newRequest';
  isFirstAssign = false;
  requestForm?: NewRequestForm;
  requestedVehicle?: RequestedVehicleRequest;
  replacementVehicle?: ReplacementVehicleRequest | null;
  petitioner?: PetitionerRequest;
  requestSubtype = '';
  ad?: boolean;

  constructor( private storageService: StorageService,
               private vehiclesService: VehiclesService,
               private coreService: CoreService,
               private modalConfig: NgbModal,
               private route: ActivatedRoute,
               private router: Router,
               private controlErrors: ControlErrors
              ) { }

  ngOnInit(): void {
    this.user = this.storageService.get('config');
    if(this.vehicleSelected) {
      this.getVehicles();
    }
    if (this.storageService.get('ad')) {
      this.ad = this.storageService.get('ad');
    }
    if (this.ad === true) {
      this.router.navigateByUrl('/landing');
    }
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {  
      if (params['orderList'] === 'true'){
        this.router.navigateByUrl('/list-request');
      }
    }); 
  }

  requestFormUpdate(data: NewRequestForm) {
    this.requestForm = data;

    if(data !== null){
      this.disableVehicle = (this.allVehiclesHasPendingRequest() ||  this.vehiclesArr.length === 0) && data.subtype !== 'firstAssign';
    } else {
      this.disableVehicle = true;
    }
    this.requestSubtype = this.requestForm.subtype;
  }

  typeEvent(data: string) : void {
    this.vehicleType = data;
    this.getVehicles();
  }

  replaceEvent(data: Vehicles) : Vehicles {  
    this.vehicleSelected = data;
    this.getRetribution(this.vehicleSelected);
    return this.vehicleSelected;
  }

  vehicleInfo (data: string) : string {
    this.codeSeat = data;
    this.getVehicleInformation(this.codeSeat);   
    return this.codeSeat; 
  }

  isFirstAssingRequest(data: boolean): boolean {
    return this.isFirstAssign = data;
  }

  getType(data: string) {
    return data === 'leasing' ? this.typeTranslation = 'Arrendamiento' : this.typeTranslation = 'Dotación';
    
  }
  getVehicles() {
    this.vehiclesService.getVehiclesByVehicleTypeAndNIS(this.vehicleType)
      .subscribe((resp) => {
        this.vehiclesArr =  resp;
        this.vehiclesArr.length > 0 ? this.existVehicles = true : this.existVehicles = false; 
        this.typeTranslation = this.getType(this.vehicleType);
      });  
    }

    allVehiclesHasPendingRequest = () => this.vehiclesArr?.every((vehicle) => vehicle.pendingRequest === 1);

    getRetribution (vehicle: Vehicles) {
      if (vehicle) {
        this.vehiclesService.getVehiclesRetribution(this.frameNumberAssign, this.user.nis, vehicle.frameNumber, this.vehicleType)
          .subscribe({
            next: (resp) => {
            return this.retribution = resp;
            },
            error: () => {
              return this.retribution = 'Secundaria';
            }
        });      
      }
    }

    getVehicleInformation (seatCode : string) {
      this.spinner = true;
      this.removeData = true;
      this.vehiclesService.getPccVehicle(seatCode)
        .subscribe({
          next: (res) => {
            this.errorPcc = false;
            this.vehicleInfodata = res;                
            this.spinner = false;
            this.removeData = false;
            this.vehicleInfodata.descColor = res.color;
            this.vehicleInfodata.country === null ? this.vehicleInfodata.country = 'es' : this.vehicleInfodata.country = this.vehicleInfodata.country;     
            return this.vehicleInfodata;
          },
          error: (err: HttpErrorResponse) => {
            this.spinner = false;
            this.removeData = false;
            if (err.error?.detail === '5008') {
              this.errorPcc = true;
              this.errorPccText = this.controlErrors.generateError(err.error.detail, i18next.t('ErrorCode'));
            } else {
              this.errorPcc = true;
              this.errorPccText = this.controlErrors.generateError(err.error.message, i18next.t('errorTryLater'));
            }
          }
      });
    }

    generateRequestObject(resp: boolean) {
      //seteo email en prod
      let email = '';
      this.vehicle = this.storageService.get('vehicleSustitution');
      if( environment.env === 'prod') {
        this.user ? email = this.user.email : email = 'seat.code.sigeva.lite@nttdata.com';
      } else {
        email = 'seat.code.sigeva.lite@nttdata.com';
      }
      if (this.requestForm) {
        this.requestForm.subtype === 'firstAssign' ? this.requestSubtype = 'FIRST_ASSIGNMENT' : this.requestSubtype = 'SUBSTITUTION';
      }
      if (!!this.vehicleInfodata) { 
        this.requestedVehicle = {
          country : this.vehicleInfodata.country,
          modelYear: parseInt(this.vehicleInfodata.modelYear),
          model: this.vehicleInfodata.model,
          tmaimg: this.vehicleInfodata.tmaimg,
          optionals:  this.vehicleInfodata.optionalCodes.toString().replaceAll(',', ';'),
          extensions: this.vehicleInfodata.extensions.toString().replaceAll(',', ';'),
          fuel: this.vehicleInfodata.fuelType,
          frame: null,
          estimatedCo2: this.vehicleInfodata.emissions,
          carConfigCode : this.codeSeat,
          extColor: this.vehicleInfodata.extColor,
          descExtColor: this.vehicleInfodata.color,
          intColor: this.vehicleInfodata.intColor,
          descIntColor: this.vehicleInfodata.internalColor,
          prices: {
            fee: Number(this.vehicleInfodata.prices.total.replace('€', '').replace(',', '.').trim()),
            primaryRE: Number(this.vehicleInfodata.prices.firstRetribution.replace('€', '').replace(',', '.').trim()),
            primaryEndowmentRE: 0,
            secondaryRE: Number(this.vehicleInfodata.prices.secondRetribution.replace('€', '').replace(',', '.').trim()),
            pvpTotal: null,
          }
        };
      }
      if (this.requestForm) {
        if(this.requestForm.subtype === 'firstAssign') {
          this.replacementVehicle = null;
        } else {
          this.replacementVehicle = {
            plateNumber: null,
            deliveryDate: null,
            subsBastidor:this.vehicle.frameNumber,
            estimatedKms : this.requestForm.estimatedKms
          };
        }
      }
      if ( this.user ) {
        this.petitioner = {
          nisName: this.user ? this.user.fullName : '',
          nis: this.user.nis,
          phone: !!this.user.mobile ? this.user.mobile : this.user.telephone,
          pssFinalDate: this.storageService.get('config').pssFinalDate,
          registrationDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000 )) .toISOString() .split('T')[0],
          email: email,
          dependence: this.user.dependency,
          costCenter: this.user.costCenter
        };
      }
      this.newRequest = {
        petitionerDTO: !!this.petitioner ? this.petitioner : null,
        orderInfoDTO: {
          orderType: this.requestSubtype,
          typeSubs: this.vehicleType,
          requestNumber: null,
          orderDate: null,
          status: null,
          sendConfirmation: resp,
          campaignType: this.user?.groupType,
        },
        replacementVehicleDTO: !!this.replacementVehicle ? this.replacementVehicle : null,
        requestedVehicleDTO : !!this.requestedVehicle ? this.requestedVehicle : null,
    };      
  }
  
  openConfirmationModal() {
    Number(this.requestForm?.estimatedKms);

    if (this.requestSubtype !== 'FIRST_ASSIGNMENT' && !this.requestForm || (this.requestForm && (this.requestForm?.estimatedKms <= 0 && this.requestForm?.subtype === 'replacement'))) {
      this.errorKms = true;
    } else {
      this.errorKms = false;
      this.modalContent = this.modalConfig.open(ConfirmationModalComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
      this.modalContent.componentInstance?.confirmModalEmitter.subscribe({
        next: (resp: boolean) => {
          this.generateRequestObject(resp);
          if (!!this.newRequest) {          
            this.coreService.newOrder(this.newRequest)
              .subscribe({
                next: () => {
                  this.openModal(true); 
                },
                error: () => {
                  this.openModal(false);
                }
              });
          }
        }
      });
    }
  }

  openModal(resp : boolean){
    this.modalContent = this.modalConfig.open(OrderModalComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
    this.modalContent.componentInstance.modalData = resp;
    this.modalContent.componentInstance.spinnerModalEmitter
    .subscribe(
      (resp: boolean) => {
        this.spinner = resp;

      });
      this.modalContent.componentInstance.closeModalEmitter.
      pipe(delay(2000))
      .subscribe(() => {
        this.router.navigateByUrl('/list-request');
      });
  }
}
