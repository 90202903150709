import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  get(key: string) {
    return JSON.parse(sessionStorage.getItem(key)!);
  }

  set(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }
}
