<main>
  <app-navbar  data="isCampaigns"></app-navbar>
  <div class="text-center spinner-container" *ngIf="spinner">
    <div class="spinner-border spinner" role="status"></div>
  </div>
  <div  *ngIf="!spinner" class="container-custom content-title">
    {{ 'campaigns' | i18next }}  
    <span *ngIf="campaigns.length !== 1">{{ 'campaignAvailable_plural' | i18next: { count: campaigns.length } }}</span>
    <span *ngIf="campaigns.length === 1">{{ 'campaignAvailable' | i18next: { count: campaigns.length } }}</span>
  </div>
  <div class="text-center errorMsg" *ngIf="errorResp">
    <p>{{'errorLoading' | i18next}}</p>
  </div>
  <div *ngFor="let campaign of campaigns" class="content-campaign animated fadeIn">
    <div class="box-campaign mb-3">
      <div *ngIf="campaign" class="container-custom content-campaign-info" [class.half-width]="campaign.urlImage">
  
        <div class="content-name pb-3 mt-3">
          {{ campaign.title }}
        </div>
        <div class="content-date pb-3">
          {{ 'campaignValid' | i18next }}: <br>
          {{ campaign.endDate }}
        </div>
        <div class="content-description pb-3">
          {{ campaign.description }}
        </div>
        <div class="content-conditions pb-3" [class.inline]="">
          <span class="title">{{ 'campaignConditions' | i18next }}:</span>
          {{ campaign.additionalInformation }}
        </div>
        <div class="content-counter"   [class.inline]="campaign">
          <span class="title">{{ 'campaignStock' | i18next }}:</span>
          {{ campaign.remainingStock }}
        </div>
      </div>
      <div *ngIf="campaign.urlImage" [ngStyle]="{'background-image': 'url(' + campaign.urlImage + ')'}"
      class="container-custom content-image">
    </div>  </div>
    <app-finishes-campaign class="finishes-campaign"  *ngIf="campaign.remainingStock !== 0" [trimLevels]="campaign.trimLevels"></app-finishes-campaign>
    <div *ngIf="campaign.remainingStock== 0" class="empty-stock-message" >
      <span>
        {{ 'emptyStockMessage' | i18next }}
      </span>
      <br>
      <span>
        {{ 'emptyStockMessageSubtitle' | i18next }}
      </span>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  
  <ngb-alert type="danger" *ngIf="messageHandlerError" (close)="messageHandlerError = ''">{{ messageHandlerError }}</ngb-alert>
  
</main>
