<app-navbar data="isAdminControl"></app-navbar>
<main class="main">
  <h3 class="main--title">{{'handleAdminTitle' | i18next}}</h3>
  <form class="main--form" (ngSubmit)="onSearch()" [formGroup]="form">
    <section class="main--form__section nisContainer">
      <input type="text"
             class="main--form__input nis"
             placeholder="{{'nis' | i18next}}"
             formControlName="nis">
      <button class="button btn-search" type="submit">{{'btnSearch' | i18next}}</button>
    </section>
    <section class="main--form__section userInfo">
      <label for="userName" class="main--form__label">{{'handleAdminuser' | i18next}}
        <input type="text"
             class="main--form__input fullName"
             readonly
             name="userName"
             formControlName="fullName">
        </label>
      <label for="profile" class="main--form__label">{{'handleAdminCategory' | i18next}}
        <input type="text"
               class="main--form__input"
               readonly
               name="profile"
               formControlName="profile">
      </label>
      <div class="main--form__checkInput">
        <input class="form-check-input main--form__check"
               type="checkbox"
               value="false"
               id="isAdmin"
               formControlName="admin">
        <label class="form-check-label" for="isAdmin">{{'handleAdmin' | i18next}}</label>
      </div>
      <button class="button" type="button" (click)="save()">{{'btnAccept' | i18next}}</button>
    </section>
  </form>
  <span *ngIf="error" class="error">{{errorMsg}}</span>
  <h6 class="table--title">{{'handleAdminTableTitle' | i18next}}</h6>
  <table class="table table-responsive table-striped" >
    <thead>
      <th scope="col">{{'nis' | i18next}}</th>
      <th scope="col">{{'name' | i18next}}</th>
      <th scope="col">{{'handleAdminTableCategory' | i18next}}</th>
      <th scope="col">{{'email' | i18next}}</th>
      <th scope="col">{{'phone' | i18next}}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let user of adminsUsersList">
        <tr>
          <td>{{user.nis}}</td>
          <td>{{user.fullName}}</td>
          <td>{{user.category}}</td>
          <td>{{user.mail}}</td>
          <td>{{user.phone}}</td>
        </tr>
      </ng-container>
      
    </tbody>
  </table>
</main>