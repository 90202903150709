<header class="header">
  <div class="logo">
    <img class="logo--img" src="assets/img/seat-cupra.png" alt="{{'altLogo' | i18next}}">
  </div>
  <h2 class="header--title">{{'documentationTitle' | i18next}} {{'documentationGroup' | i18next}} {{groupTranslation}}</h2>
</header>

<main class="main">
  <div class="table-container">
    <table class="table table-striped table-hover" >
      <thead>
        <tr>
          <th class="table-title" scope="col">{{'documentsTableTile' | i18next}}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let document of documents">
          <tr>
            <td class="table-row">
              <p class="document-title">{{document}}</p>
              <button class="btn" type="button"  (click)="showDocument(document)" >{{'btnSee' | i18next}}</button>
            </td>
          </tr>
        </ng-container>
        
      </tbody>
    </table>
  </div>
</main>