import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import {  DocumentKey, Documents, PersonalInfo } from 'src/app/shared/model/request-detail.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DataModalComponent } from './data-modal/data-modal.component';
import { DragDropComponent } from 'src/app/shared/components/drag-drop/drag-drop.component';
import { StorageService } from 'src/app/core/storage/storage.service';
import { ModalFeedbackComponent } from './data-modal/modal-feedback/modal-feedback.component';
import i18next from 'i18next';
import { AddDataFine, RequestCertificateData } from 'src/app/shared/model/documentation.model';
import { OrderService } from 'src/app/core/services/order.service';
import { DataEntryService } from 'src/app/core/services/data-entry.service';
@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit{
  @Input() documentation! : Documents[];
  @Input() admin!: boolean;
  @Input() requestNumber! : string;
  @Input() certZoneInvalid!: boolean;
  @Input() hasPlateNumber!: boolean;
  /* mock del vehiculo del detalle */
  @Input() orderInfo: any;

  @Output() getDocumentsEmitter = new EventEmitter<NgbModalRef>();

  modalContent? : NgbModalRef;
  fineType = false;
  infoObj = {};
  
  modalDataObj = {};
  certZoneRequest = false;
  certDocument= false;
  certData?: PersonalInfo;
  requestCertificateData!: RequestCertificateData;
  addDataFine! : AddDataFine;
  addBtn = true; 
  loggedUserFullName = '';

  constructor( private dataEntryService : DataEntryService,
               private orderService : OrderService,
               private modalConfig : NgbModal,
               private storageService : StorageService
  ) {} 

  ngOnInit(): void {
    if(this.storageService.get('certificate')) {
      this.certData = this.storageService.get('certificate');
    } 
    this.storageService.get('requestNis') ? this.addBtn = true : this.addBtn = false;
  }

   //botón para adjuntar documentos (abre modal)
   openAddDocument() {
    this.modalDataObj = {
      orderInfo: this.orderInfo,
      documentation: this.documentation
    };
    this.modalContent = this.modalConfig.open(DragDropComponent, { windowClass: 'password-modal', centered: true, backdrop: 'static'});
    this.modalContent.componentInstance.modalData = this.modalDataObj;
    this.modalContent.componentInstance.getDocumentsEmitter.subscribe({
      next: (resp: boolean) => {
        if (resp === true) {
          this.getDocumentsEmitter.emit(this.modalContent);
        }
      },
      error: () => {
        this.modalContent = this.modalConfig.open(ModalFeedbackComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
        this.modalContent.componentInstance.modalData = i18next.t('fineModalFeedbackKo');
      }
    });
  }
  //editar e introducir datos
  editDocument(document: Documents) {
    const loggedUserFullName = this.storageService.get('config').fullName;
    this.modalContent = this.modalConfig.open(DataModalComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
    this.modalContent.componentInstance.dataEmitter?.subscribe((data : PersonalInfo) => {
     this.addDataFine = {
      ...data,
      vehicleModel: this.orderInfo.requestedVehicleDTO.model,
      plateNumber: this.orderInfo.registrationDataDTO.plateNumber,
      loggedUserFullName: loggedUserFullName,
      documentKey: document.fileName,
      type: 'TRAFFIC_FINE'
     };
     this.dataEntryService.sendInfo(this.addDataFine).subscribe({
        next: () => {
          this.modalContent = this.modalConfig.open(ModalFeedbackComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
          this.modalContent.componentInstance.modalData = i18next.t('fineModalFeedbackOk');
          
          this.getDocumentsEmitter.emit();
        },
        error: () => {
          this.modalContent = this.modalConfig.open(ModalFeedbackComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
          this.modalContent.componentInstance.modalData = i18next.t('fineModalFeedbackKo');
        }
      });
    }); 
  }

  //solicitar zona de aparcamiento 
  requestZoneCertificate() {
    this.modalContent = this.modalConfig.open(DataModalComponent, { windowClass: 'password-modal', centered: true, backdrop: 'static' });
    this.modalContent.componentInstance.modalCertData = true;
    this.modalContent.componentInstance.dataEmitter?.subscribe({
      next: (data : PersonalInfo) => {
        const loggedUserFullName = this.storageService.get('config').fullName;
        if (this.requestNumber) {

          this.requestCertificateData = {
            ...data,
            requestNumber: this.orderInfo.orderNumber,
            vehicleModel: this.orderInfo.requestedVehicleDTO.model,
            plateNumber: this.orderInfo.registrationDataDTO.plateNumber,
            loggedUserFullName: loggedUserFullName
          };
          this.certData = data;
          this.storageService.set('certificate', this.certData);
          this.orderService.requestCertificate(this.requestCertificateData)
            .subscribe({
              next: () => {
                this.modalContent = this.modalConfig.open(ModalFeedbackComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
                this.modalContent.componentInstance.modalData = i18next.t('certZoneModalFeedbackOk');
              },
              error: () => {
                this.modalContent = this.modalConfig.open(ModalFeedbackComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
                this.modalContent.componentInstance.modalData = i18next.t('certZoneModalFeedbackKo');
              }
            });
        }
      }
    });
  }
  //descargar documento
  downloadDocument(filename: string) {
    const documentKey: DocumentKey = {documentKey: filename};
    
    this.dataEntryService.downloadDocument(documentKey)
      .subscribe({
        next: (resp : any) => {
          saveAs(resp,filename);
        }
      });
  }

  notFilled(data: PersonalInfo | null | undefined) { 
    if (data === null || data === undefined) {
      return 'edit-icon';
    } else {
      return 'not-edit-icon';
    }
  }

  filledInfo(data: PersonalInfo | null) {
    if (data) {
      return 'showIcon';
    } else {
      return 'not-show-icon';
    }
  }

  showInfo(data: Documents) {
    const filledPersonalInfo = data.personalInfo;
    this.modalContent = this.modalConfig.open(DataModalComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
    this.modalContent.componentInstance.modalData = {filledPersonalInfo};
    if (data.documentType === 'GREEN_ZONE_CERTIFICATE') {
      this.modalContent.componentInstance.modalCertData = true;
    }
  }

  deleteDocument(document: Documents) {
    const documentKey: DocumentKey = {documentKey: document.fileName};
    this.modalContent = this.modalConfig.open(ConfirmModalComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
    this.modalContent.componentInstance.modalData = documentKey;
    this.modalContent.componentInstance.getDocumentsEmitter.subscribe(() => {
      this.dataEntryService.deleteDocument(documentKey)
      .subscribe({
        next: () => {
          if(document.documentType ==='GREEN_ZONE_CERTIFICATE') {
            this.storageService.removeItem('certificate');
            this.certZoneInvalid = false;
            this.certData = this.storageService.get('certificate');
          }
          this.getDocumentsEmitter.emit();
        },

      });
    });
  }
}
