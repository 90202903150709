<form [formGroup]="form" class="modal-container">
  <div class="modal-container--text">
    <h5 class="modal-title alertTitle">{{modalText}}</h5>
  </div>
  <div class="modal-container--checkbox">
    <input type="checkbox"
           class="form-check-input modal-container--input"
           id="sendEmailCheck"
           formControlName="sendEmailCheck"/>
    <label for="sendEmailCheck">{{'requestSendEmail' | i18next}}</label>
  </div>
  <div class="modal-container--btn-container" >
    <button type="button" class="btn btn-cancel"(click)="closeModal()" >{{'btnNo' | i18next}}</button>
    <button type="submit" class="btn" (click)="openModal()" >{{'btnYes' | i18next}}</button>
  </div>
</form>