import { Component, OnInit } from '@angular/core';

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { StorageService } from 'src/app/core/storage/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  iconSuccess = faCheckCircle;
  iconError = faTimesCircle;
  infoUser?: {
    nis: string,
    email: string
  };

  message?: string;
  status?: string;

  constructor(private storage: StorageService, private router: Router) {}

  ngOnInit() {
    this.infoUser = this.storage.get('config');
    
    const orderData = this.storage.get('order');
    this.status = orderData?.code;
    this.message = orderData?.message;
  }

  deleteOrder() {
    this.storage.removeItem('stock');
    this.storage.removeItem('order');
    this.router.navigateByUrl('/campaigns');
  }

}
