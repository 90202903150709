<div class="content-upload">
  {{ header }}
  <file-pond #filePondUpload
             name="file"
             [options]="pondOptions"
             [files]="pondFiles"
             (onprocessfilestart)="onprocessfilestart()"
             (onerror)="onerror($event)">
  </file-pond>
</div>
<app-process-result [messages]="errors"></app-process-result>
<div class="content-buttons pb-4">
  <button type="button"
          class="btn back mr-2"
          [routerLink]="['/landing']"
          [disabled]="btnCancelStatus"> {{'btnCancel' | i18next}}
  </button>
  <button *ngIf="msgCategory !== 'authorizers'"
           type="button"
           class="btn confirm bgColor"
           [routerLink]="['/upload/image']"
           [disabled]="isProcessFile"> {{'btnContinue' | i18next}}
  </button>
  <button *ngIf="msgCategory === 'authorizers'"
           type="button"
           class="btn confirm bgColor"
           [routerLink]="['/upload/finally']"
           [disabled]="isProcessFile"> {{'btnContinue' | i18next}}
    </button>
</div>
