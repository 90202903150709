import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ControlErrors {

  generateError(code: string, text: string) {
    return `Código de error ${code}: ${text}`;
  }
}