<div class="modal-container">
  <form [formGroup]="form" class="form">
    <div class="form--header">
      <p class="form--label">{{'yearSelectionTitle' | i18next}}</p>
      <div class="form--allYears">
        <label class="form--allYears__label" for="all">{{'everyYear' | i18next}}</label>
        <input class="form--allYears__input"
               formControlName="all"
               type="checkbox"
               id="all"
               #all
               [checked]="this.form.value.all === true"
               (change)="changeCheckInput()"/>
      </div>
    </div>
    <select (change)="changeYearInput()" formControlName="year" class="form--select" name="year" id="year" size="5" (ngModel)="selectedYear">
      <option *ngFor="let year of years" [value]="year">{{ year }} </option> 
    </select>
    <small class="form--info">{{'yearSelectionInfo' | i18next}} <span>{{year}}</span></small>
    <div class="form--btn-container">
      <button class="btn confirm cancel form--btn-container__cancel" (click)="close()">{{'btnCancel' | i18next}}</button>
      <button [disabled]="" class="btn confirm bgColor form--btn-container__downloadBtn" (click)="downloadStock()">{{'btnDownload' | i18next}}</button>
    </div>
  </form>
</div>