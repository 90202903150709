import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PccVehicle, Vehicles } from '../../model/vehicle.model';
import i18next from 'i18next';

@Component({
  selector: 'app-car-configurator',
  templateUrl: './car-configurator.component.html',
  styleUrls: ['./car-configurator.component.scss']
})
export class CarConfiguratorComponent {
  @Input() vehicleInfoData! : PccVehicle;
  @Input() disableVehicle? : boolean;
  @Input() errorPcc = false;
  @Input() errorPccText?: string;
  @Input() spinner? : boolean;
  @Input() removeData? : boolean;
  @Input() summaryRequest? :string;
  
  @Output() vehicleInfoEmitter = new EventEmitter<string>();

  pcc = 'https://configurador.seat.es/seat-cc/configurator/LIVE/003/SIGEVA/es/car-selector;reference-data=FAA9DA44E4B79163C12588F0004B82AE';
  vehicleCode = '';
  sustitutionVehicle?: Vehicles;
  pccCode = i18next.t('pccCode');
  numb? : number;

  constructor(private fb : FormBuilder) {}

  form : FormGroup = this.fb.group({
    vehicleCode: ''
  });

  openLink(): void {
    window.open(this.pcc, '_blank');
  }

  vehicleInfo(value: string) {
    this.vehicleInfoEmitter.emit(value);
  }

  showCarInfo() {
    this.vehicleCode = this.form.value.vehicleCode;
    this.vehicleInfo(this.vehicleCode); 
  }
}