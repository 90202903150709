<nav class="navbar container-custom">
  <div class="content-buttons">
    <button class="btn filled"
            type="button" 
            (click)="navigateTo('stock')"
            *ngIf="page !== 'orders'">{{'backofficeNavStock' | i18next}}
    </button>
    <button class="btn filled"
            type="button"
            (click)="navigateTo('campaigns')"
            *ngIf="page !== 'campaigns'">{{'backofficeNavCampaigns' | i18next}} 
    </button>
    <div class="downloadOrdersContainer">
      <button *ngIf="page === 'orders'"
              class="btn mr-3"
              type="button"
              (click)="generateOrdersExport()">{{ recordsFileUrlsLoadingMsg }}
      </button>
    </div>
    <a  href="/assets/templates/Campaign_Template.xlsm"
        download="Campaign_Template.xlsm"
        *ngIf="page === 'campaigns'">
      <button class="btn mr-3" type="button">
        {{'backofficeNavDownloadTemplate' | i18next}}
      </button>
    </a>
    <button class="btn"
            type="button"
            (click)="navigateTo('addCampaign')"
            *ngIf="uploadCampaign && page === 'campaigns'"> {{'backofficeNavNewCampaign' | i18next}}
    </button>
    <button  class="btn"
             type="button"
             (click)="navigateTo('authorizers')"
             *ngIf="uploadAuthorizers">{{'backofficeNavAuthorisers' | i18next}}
    </button>    
  </div>
</nav>