import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewRequestComponent } from './new-request.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OrderModalComponent } from './order-modal/order-modal.component';
import { I18NextModule } from 'angular-i18next';
import { ConfirmationModalComponent } from './order-modal/confirmation-modal/confirmation-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { InfoRequestComponent } from './info-request/info-request.component';



@NgModule({
  declarations: [
    NewRequestComponent,
    OrderModalComponent,
    ConfirmationModalComponent,
    InfoRequestComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18NextModule,
    SharedModule
  ]
})
export class NewRequestModule { }