<div class="text-center spinner-container" *ngIf="spinner">
  <div class="spinner-border spinner" role="status"></div>
</div>
<main *ngIf="!spinner">
  <h2 class="title">{{'smsInstructions' | i18next}}</h2>
  <ul class="list">
    <li class="list--item">{{'smsListItem1' | i18next}}</li>
    <li class="list--item">{{'smsListItem2' | i18next}}</li>
    <li class="list--item">{{'smsListItem3' | i18next}}</li>
  </ul>
  <p class="text"><span class="text--important">{{'important' | i18next}} </span> {{'smsImportantInfo' | i18next}}</p>
  
  <form [formGroup]="form" class="form">
    <div class="form--telfWithErr">
      <div class="form--telfContainer">
        <label class="form--telfContainer__label" for="phone">{{'phoneNumber' | i18next}}</label>
        <input class="form--telfContainer__input"
               type="text"
               formControlName="telephone"
               #phone
               (change)="updateTelephone()">
      </div>
      <small class="error" *ngIf="errorMsg">{{'errorTelephone' | i18next}}</small>
    </div>
    <div class="form--btnContainer">
      <div>
        <button class="btn confirm bgColor" 
                type="button" 
                (click)="submit()"
                [disabled]="form.value.instructionsCheck === false">{{'btnSendSMS' | i18next}}
        </button>
        <div class="form--btnContainer__checkInfo">
          <input type="checkbox"
                 id="check"
                 formControlName="instructionsCheck"
                 class="form--btnContainer__checkInput"/>
          <label for="check" class="form--btnContainer__checkLabel">{{'instructionsCheck' | i18next}}</label>
        </div>
      </div>
      <button class="btn confirm cancel"
              type="button"
              [routerLink]="['/register/mfa-authentication']">{{'btnBack' | i18next}}
      </button>
    </div>
    <div *ngIf="submitErr">
      <small class="error">{{submitErrText}}</small>
    </div>
  </form>
</main>