import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSummaryComponent } from './order-summary.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DocumentationComponent } from './documentation/documentation.component';
import { InfoReplacementVehicleComponent } from './info-replacement-vehicle/info-replacement-vehicle.component';
import { FrameInsuranceDataComponent } from './frame-insurance-data/frame-insurance-data.component';
import { QuotaInfoComponent } from './quota-info/quota-info.component';
import { TrackingComponent } from './tracking/tracking.component';
import { BookDataComponent } from './book-data/book-data.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AppraisalModalComponent } from './appraisal-modal/appraisal-modal.component';
import { ConfirmationModalComponent } from './appraisal-modal/confirmation-modal/confirmation-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    OrderSummaryComponent,
    DocumentationComponent,
    InfoReplacementVehicleComponent,
    FrameInsuranceDataComponent,
    QuotaInfoComponent,
    TrackingComponent,
    BookDataComponent,
    AppraisalModalComponent,
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class OrderSummaryModule { }
