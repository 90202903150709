<fieldset class="request">
  <legend class="request--legend float-none w-auto p-2">{{'requestPcc' | i18next}}</legend>
  <form class="form" *ngIf="!summaryRequest">
    <div [formGroup]="form" class="pcc-btn-container">
      <button class="btn" (click)="openLink()" [disabled]="disableVehicle" type="button">{{'requestPccConfig' | i18next}}</button>
      <div class="showCarInfo">
        <input class="pcc-text"
               type="text"
               [placeholder]="pccCode"
               formControlName="vehicleCode">
        <button class="btn-showInfo"
                (click)="showCarInfo()"
                (keyup.enter)="showCarInfo()"
                [disabled]="disableVehicle || !form.value.vehicleCode"
                type="submit"> {{'requestPccShowVeicle' | i18next}}
        </button>  
        <p class="errorMsg" *ngIf="errorPcc">{{errorPccText}}</p>
      </div>
    </div>
    <div class="text-center spinner-container" *ngIf="spinner">
			<div class="spinner-border spinner" role="status"></div>
		</div>
  </form>
  <section class="section" *ngIf="vehicleInfoData && !errorPcc && !removeData">
    <div class="vehicleForm">
      <div class="column">
        <div class="rowInfo">
          <p class="label">{{'requestPccCountry' | i18next}}<span class="required"> {{'required' | i18next}}</span> </p>
          <p class="text">{{vehicleInfoData.country | uppercase}}</p>
        </div>
        <div class="rowInfo">
          <p class="label">{{'requestPccMY' | i18next}}<span class="required"> {{'required' | i18next}}</span> </p>
          <p class="text">{{vehicleInfoData.modelYear}}</p>
        </div>
        <div class="rowInfo">
          <p class="label">{{'requestPccOptionalCodes' | i18next}}</p>
          <p class="text" *ngIf="vehicleInfoData.optionalCodes">{{vehicleInfoData.optionalCodes.join(' ')}}</p>
          <p class="text" *ngIf="vehicleInfoData.optionalCodes.length === 0"> {{'emptyField' | i18next}} </p>
        </div>
        <div class="rowInfo">
          <p class="label">{{'requestPccDescription' | i18next}}</p>
          <p class="text" *ngIf="vehicleInfoData.descriptionOptionalCodes">{{vehicleInfoData.descriptionOptionalCodes.join(' / ')}}</p>
          <p class="text" *ngIf="vehicleInfoData.descriptionOptionalCodes.length === 0"> {{'emptyField' | i18next}} </p>
        </div>
      </div>
      <div class="column">
        <div class="rowInfo">
          <p class="label lrow">{{'model' | i18next}} <span class="required"> {{'required' | i18next}}</span></p>
          <p class="text">{{vehicleInfoData.model}}</p>
        </div>
        <div class="rowInfo">
          <p class="label lrow">{{'requestPccTMAIMG' | i18next}} <span class="required"> {{'required' | i18next}}</span></p>
          <p class="text">{{vehicleInfoData.tmaimg}}</p>
        </div>
        <div class="rowInfo">
          <p class="label lrow">{{'requestPccEmissions' | i18next}}</p>
          <p class="text">{{vehicleInfoData.emissions}}</p>
        </div>
        <div class="rowInfo">
          <p class="label lrow">{{'requestPccColor' | i18next}} <span class="required"> {{'required' | i18next}}</span></p>
          <p class="text">{{vehicleInfoData.descColor}}</p>
        </div>
      </div>
    </div>
    <div class="retribution" *ngIf="!summaryRequest">
      <div class="rowInfo cuota">
        <p class="label">{{'requestPccFee' | i18next}}</p>
        <p class="text">{{vehicleInfoData.prices.total}}</p>
      </div>
      <div class="rowInfo ret">
        <p class="label lrow">{{'requestPccRemKind' | i18next}}</p>
        <p class="text">{{vehicleInfoData.prices.secondRetribution}}</p>
      </div>
    </div>
    <div class="retribution" *ngIf="summaryRequest">
      <div class="rowInfo cuota">
        <p class="label">{{'requestPccFee' | i18next }} </p>
        <p class="text">{{vehicleInfoData.prices.total }}€ </p>
      </div>
      <div class="rowInfo ret">
        <p class="label lrow">{{'requestPccRemKind' | i18next}}</p>
        <p class="text">{{vehicleInfoData.prices.retribution}}€</p>
      </div>
    </div>
  </section>
  <section class="extra-info" *ngIf="vehicleInfoData && !errorPcc && !removeData">
    <div class="rowInfo">
      <p class="label">{{'requestPccNote' | i18next}}</p>
      <p class="text">{{'requestPccNoteText1' | i18next}}<br> {{'requestPccNoteText2' | i18next}}</p>
    </div>
    <div class="rowInfo">
      <p class="label">{{'requestPccPenalty' | i18next}} </p>
      <p class="text"><strong>{{'requestPccPenaltyInc1' | i18next}}</strong>{{'requestPccPenaltyInc1Num' | i18next}} <strong>{{'requestPccPenaltyInc2' | i18next}}</strong> {{'requestPccPenaltyInc2Num' | i18next}}<br> {{'requestPccPenaltyDesc' | i18next}} </p>
    </div>
    <br>
    <p>{{'requestPccEndNote' | i18next}}</p>
  </section>
</fieldset>
