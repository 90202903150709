import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StorageService } from '../../../core/storage/storage.service';
import { SessionInfo, UserRegister } from 'src/app/shared/model/register.model';
import {  NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
//import { RegisterModalComponent } from '../register-modal/register-modal.component';
import { Router } from '@angular/router';
import { UserService } from '../../../core/services/user.service';
import i18next from 'i18next';
import { User } from 'src/app/shared/model/user.model';
import { CoreService } from 'src/app/core/services/core.service';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ControlErrors } from 'src/app/shared/utils/generateError';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mfa-authentication',
  templateUrl: './mfa-authentication.component.html',
  styleUrls: ['./mfa-authentication.component.scss']
})
export class MfaAuthenticationComponent implements OnInit, AfterViewInit {

  @ViewChild('autofocus') autofocus!: ElementRef<HTMLInputElement>;

  user! : UserRegister;
  sessionInfo! : SessionInfo;
  token = '';
  code = '';
  secretCode = '';
  errorMessage = '';
  session= '';
  show = false;
  public QRCode = 'data:image/png;base64,';
  spinner = false;
  spinnerAD = false;
  isAD = false;
  showSMSOption = false;
  loginSession = true;
  prod?: boolean;
  
  authenticationForm : FormGroup = this.fb.group({
    code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
  });
  modalContent?: NgbModalRef;

  constructor( private fb: FormBuilder,
               private storageService : StorageService,
               private coreService : CoreService,
               private modalConfig: NgbModal,
               private router: Router,
               private userService : UserService,
               private controlErrors: ControlErrors) { }

  ngOnInit(): void {
    environment.env === 'prod' ? this.prod = true : this.prod = false;

    this.user = this.storageService.get('userRegister');
    this.sessionInfo =  this.storageService.get('sessionInfo');

    if (window.location.href.includes('code=')) {
      this.spinnerAD = true;
      this.isAD = true;
      this.verifyCodeAD(window.location.href.split('code=')[1]);
    } else if ( !this.sessionInfo || ( this.sessionInfo.qrCodeResponseDTO === null &&  this.sessionInfo.smsResponseDTO === null)) {
        this.router.navigateByUrl('login');
    } else if(!!this.sessionInfo.qrCodeResponseDTO) {
        this.secretCode = this.sessionInfo.qrCodeResponseDTO.secretCode;
        this.session = this.sessionInfo.qrCodeResponseDTO.session;
        this.QRCode += this.sessionInfo.qrCodeResponseDTO.qrCode;
    } else if(!!this.sessionInfo.smsResponseDTO){
        this.session = this.sessionInfo.smsResponseDTO.session;
    }

    if (this.sessionInfo.qrCodeResponseDTO?.qrCode) {
      this.loginSession = false;
    } else { 
      this.loginSession = true;
    }
  }

  ngAfterViewInit() {
    this.autofocus?.nativeElement?.focus();
  }
  
  controlInput(input : string): boolean {
    return (
      this.authenticationForm?.controls[input]?.invalid &&
      this.authenticationForm?.controls[input]?.touched
    );
  }
  codeVerification() {
    this.errorMessage = '';
    this.spinner = true;
    this.hasQr();
    if ((!!this.sessionInfo.qrCodeResponseDTO && !!this.sessionInfo.qrCodeResponseDTO.qrCode) || (!!this.sessionInfo.smsResponseDTO && this.sessionInfo.smsResponseDTO.firstTime)) {
      this.coreService.verifyMfa(this.user.nis, this.user.password, this.authenticationForm.value.code, this.session)
        .subscribe({
          next: () => {
            this.spinner = false;
            this.modalContent = this.modalConfig.open(FeedbackModalComponent, { windowClass: 'register-modal', centered: true, backdrop : 'static'});
            this.modalContent.componentInstance.text = i18next.t('mfaModalSuccess');
            this.modalContent.componentInstance.redirect = true;
          },
          error: (err: HttpErrorResponse) => {
            if ((err.error.message === '5011' && !this.prod)|| (err.error.message === '5011' && this.prod && this.user.nis === '000000')) {
              this.spinner = false;
              this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('qrError'));
              this.showSMSOption = true;
            } else if (err.error.status === 408) {
              this.spinner = false;
              this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('sessionErr'));
            } else {
              this.spinner = false;
              this.errorMessage = i18next.t('qrError');
            }
         }
        });
    } else {
      this.coreService.authchallenge(this.user.nis, this.authenticationForm.value.code, this.session)
        .subscribe({
          next: (res: string) => {
            this.setToken(res);
          },
            error: (err: HttpErrorResponse) => {
              if (err.error?.status === 408) {
                this.spinner = false;
                this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('sessionErr'));
              } else {
                this.spinner = false;
                this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('genericErr'));
              }  
          }
      });
    }
  }

  hasQr() {
    if ( this.showSMSOption) {
      return 'hasNoQr';
    } else if (!!this.sessionInfo.qrCodeResponseDTO && !!this.sessionInfo.qrCodeResponseDTO.qrCode) {
      return 'hasQr';
    } else {
      return '';
    }
  }

  verifyCodeAD(code: string) {
    this.coreService.verifyAuthCode(code).subscribe({
      next: (res: string) => {
        this.spinnerAD = false;
        this.setToken(res);
      },
      error: (err: HttpErrorResponse) => {
        this.spinnerAD = false;
        if (err.error.message === '5012') {
          this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('unregisteredUser'));
        } else if (err.error.message === '5005') {
          this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('categoryError'));
        }
      }
    });
  }
  
  setToken(token: string) {
    this.storageService.set('token', token);
    this.userService.getUser().subscribe({
      next: (resp: User) => {
        const user = {
          ...resp,
          'isAd': this.isAD
        };
        this.spinner = false;
        this.storageService.set('config', user);
        this.router.navigateByUrl('confidentiality');    
      }
    });
  }

  navigateTo() {
    this.isAD ? this.router.navigateByUrl('access-login') : this.router.navigateByUrl('login');
  }
}

