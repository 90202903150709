<header>
  <img class="logo" src="assets/img/seat-logo-horizontal.png" alt="{{'altLogo' | i18next}}">
</header>
<div class="text-center spinnerAD-container" [hidden]="!spinnerAD">
  <div class="spinner-border spinnerAD" role="status"></div>
</div>
<main class="authentication" *ngIf="!isAD">
  <form [formGroup]="authenticationForm" [ngClass]="hasQr()"  (ngSubmit)="codeVerification()" class="form">
    <h2 *ngIf="!loginSession">{{'userCreation' | i18next}}</h2>
    <h2 *ngIf="loginSession">{{'loginUser' | i18next}}</h2>
    <p  class="form--subtitle">{{'mfaTitle' | i18next}}</p>
    <div *ngIf="!!sessionInfo.qrCodeResponseDTO && sessionInfo.qrCodeResponseDTO.qrCode "  [hidden]="showSMSOption" class="form--qr-container">
      <img [src]="QRCode" class="form--qr-container__img" alt="{{'altQr' | i18next}}"/>
      <p class="form--qr-container__text">{{secretCode}}</p>
    </div>
    <p *ngIf="!!sessionInfo.qrCodeResponseDTO"  [hidden]="showSMSOption" class="form--input__text">{{'mfaTextQR' | i18next}}</p>
    <p *ngIf="!!sessionInfo.smsResponseDTO"  [hidden]="showSMSOption" class="form--input__text">{{'mfaTextSMS' | i18next}}</p>
    <input class="form--input"
           #autofocus
           type="text"
           formControlName="code"
           required
           placeholder="{{'mfaCode' | i18next}}"
           [hidden]="showSMSOption"/>
    <div class="error-message"> {{errorMessage}} </div>
    <span *ngIf="controlInput('authenCode')" class="error-message">
      <p class="error-text">{{'emailVerificationInputErr' | i18next}}
      </p>
    </span>
    <div class="btn-container" [hidden]="showSMSOption">
      <button type="button" class="btn cancel" (click)="navigateTo()">{{'btnCancel' | i18next}}</button>
      <button type="submit" [disabled]="!authenticationForm.valid" class="btn confirm bgColor mfa-btn">{{'btnAccept' | i18next}}</button>
      <div class="text-center spinner-container" [hidden]="!spinner">
        <div class="spinner-border spinner" role="status"></div>
      </div>
    </div>
    <div class="sms-container" *ngIf="showSMSOption">
      <button  type="button" class="btn confirm bgColor smsBtn" [routerLink]="['/register/sms-authentication']">{{'btnSms' | i18next}}</button>
      <small class="text">{{'smsAuthInfoBtn' | i18next}}</small>
    </div>
  </form>
</main>
<div class="adError" *ngIf="isAD">
  <p>{{errorMessage}}</p>
</div>