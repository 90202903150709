import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor{
  constructor( private storageService : StorageService ,
               private router : Router
             ) {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.storageService.get('token');
    let authReq: any;
    if(authToken) {
        authReq = req.clone({
        headers: req.headers.append('Authorization', 'Bearer ' + authToken.token)
      }); 
      return next.handle(authReq).pipe(catchError((error: HttpErrorResponse) => {
        if(error.status === HttpStatusCode.Unauthorized){
          this.storageService.removeItem('token');
          this.router.navigateByUrl('/login');
        }
        return throwError(() => error);
      }
      ));
    }
    return next.handle(req);
  }
}