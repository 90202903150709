import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Filter, ITableHeaders, Sort, Stock } from 'src/app/shared/model/backoffice.model';

@Component({
  selector: 'app-table-orders',
  templateUrl: './table-orders.component.html',
  styleUrls: ['./table-orders.component.scss'],
  providers: [NgbTooltipConfig]
})
export class TableOrdersComponent implements OnInit {
  @Input() headersTable!: ITableHeaders[] ;
  @Input() title!: string;
  @Input() items!: Stock[];
  @Input() options? : any;

  @Output() sortOrders: EventEmitter<any> = new EventEmitter();
  @Output() filterOrders: EventEmitter<Filter> = new EventEmitter();
  @Output() resetOrders: EventEmitter<any> = new EventEmitter();
  @Output() deleteOrder: EventEmitter<any> = new EventEmitter();

  handledOrders = false;
  activeFilterKey: EventEmitter<string> = new EventEmitter();

  iconDelete = faTrashAlt;
  iconDeleteRequestItem = faUnlink;

  constructor(private tooltipConfig: NgbTooltipConfig) {}

  ngOnInit() {
    this.tooltipConfig.placement = 'bottom';
  }

  sort(sort: Sort) {    
    this.handledOrders = true;
    this.activeFilterKey.emit(sort.key);
    this.sortOrders.emit(sort);
  }

  filter(filters: Filter) {
    this.handledOrders = true;
    this.activeFilterKey.emit(filters.key);
    this.filterOrders.emit(filters);
  }

  deleteFilters() {
    this.handledOrders = false;
    this.activeFilterKey.emit('');
    this.resetOrders.emit();
  }

  delete(stock : Stock) {
    this.deleteOrder.emit(stock);
  }
}
