<fieldset class="addInfo">
  <legend class="addInfo--legend float-none w-auto p-2">{{'summaryExtraDataTitle' | i18next}}</legend>
  <section class="addInfo--section" *ngIf="vehicleData">
    <div class="addInfo--item">
      <label class="addInfo--item__label">{{'summaryNewFrameNumber' | i18next}}</label>
      <p class="addInfo--item__text">{{vehicleData.plateNumber}}</p> 
    </div>
    <div class="addInfo--item">
      <label class="addInfo--item__label">{{'summaryInsuranceType' | i18next}}</label>
      <p class="addInfo--item__text">{{vehicleData.insuranceType}}</p>
    </div>
    <div class="addInfo--item">
      <label class="addInfo--item__label">{{'summaryFrameNumberDate' | i18next}}</label>
      <p class="addInfo--item__text">{{vehicleData.frameNumberDate}}</p>
    </div>
    <div class="addInfo--item">
      <label class="addInfo--item__label">{{'summaryDeliveryDate' | i18next}}</label>
      <p class="addInfo--item__text">{{vehicleData.deliveryDate}}</p>
    </div>
  </section>
</fieldset>