import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef,  } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { CoreService } from '../../core/services/core.service';
import { SessionInfo } from '../../shared/model/register.model';
import { StorageService } from '../../core/storage/storage.service';
import { UserRegister } from 'src/app/shared/model/register.model';
import { ModalBlockedComponent } from './modal-blocked/modal-blocked.component';
import i18next from 'i18next';
import { HttpErrorResponse } from '@angular/common/http';
import { ControlErrors } from 'src/app/shared/utils/generateError';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit{

  @ViewChild('autofocus') autofocus!: ElementRef<HTMLInputElement>;

  showLayout = true;
  errorMessage = '';
  userRegister: UserRegister = {
    nis : '',
    password : ''
  };
  sessionInfo?: SessionInfo;
  token? = '';
  modalContent? : NgbModalRef;
  spinner = false;

  userForm : FormGroup = this.fb.group({
    nis: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    password: ['', Validators.required],
  });
  
  constructor(private fb: FormBuilder,
              private modalConfig: NgbModal,
              private router: Router,
              private coreService: CoreService,
              private storageService: StorageService,
              private controlErrors: ControlErrors) {}

  onSubmit() {
    this.userRegister.nis = this.userForm.value.nis || '';
    this.userRegister.password = this.userForm.value.password || '';
    this.storageService.set('userRegister', this.userRegister);
    this.spinner = true;
    this.setUser();
  }
  
  ngAfterViewInit() {
    this.autofocus.nativeElement.focus();
  }

  setUser() {
    this.errorMessage = '';
    if (this.userRegister.nis.length === 0) {
      return;
    }
    this.coreService.signin(this.userRegister.nis, this.userRegister.password)
      .subscribe({
        next: (res) =>{
          this.spinner = false;
          this.sessionInfo = res;
          this.storageService.set('sessionInfo', this.sessionInfo);
          this.router.navigateByUrl('/register/mfa-authentication');
        },
        error: (err: HttpErrorResponse) => {
          this.spinner = false;
          if (err.error.status === 423) {
            this.modalContent = this.modalConfig.open(ModalBlockedComponent, {windowClass: 'modal-block', centered: true, backdrop : 'static'});
            this.modalContent.componentInstance.modalData = this.controlErrors.generateError(err.error.message, i18next.t('loginBlocked'));
          } else if (err.error.status === 412) {
            this.modalContent = this.modalConfig.open(ModalBlockedComponent, {windowClass: 'modal-block', centered: true, backdrop : 'static'});
            this.modalContent.componentInstance.modalData = this.controlErrors.generateError(err.error.message, i18next.t('loginNoPss'));
          } else if (err.error.status === 406 && err.error.message === '5003') {
            this.modalContent = this.modalConfig.open(ModalBlockedComponent, {windowClass: 'modal-block', centered: true, backdrop : 'static'});
            this.modalContent.componentInstance.modalData = this.controlErrors.generateError(err.error.message, i18next.t('errorNoPhones'));
          } else if (err.error.status === 406 && err.error.message === '5005') {
            this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('categoryError'));
          } else {
            this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('genericErr'));
        }
      }  
    });
  }

  forgotPassword() {
    this.modalConfig.open(ForgotPasswordModalComponent, { windowClass: 'forgot-password', centered: true, backdrop : 'static'});
  }
}
