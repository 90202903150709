import { Documents, PersonalInfo } from './request-detail.model';

interface DocuType {
  value: string,
  viewValue: string
}

export const documentationType: DocuType[] =[
  {
    value: 'TRAFFIC_FINE',
    viewValue: 'TRAFFIC_FINE'
  },
  {
    value: 'GREEN_ZONE_CERTIFICATE',
    viewValue: 'GREEN_ZONE_CERTIFICATE'
  },
  {
    value: 'OTHERS',
    viewValue: 'OTHERS'
  },
];

export interface UploadDocument {
  document: string,
  nis: string,
  nisFileUploader: string,
  requestNumber: string,
  type: string,
  email: string,
  model: string,
  plateNumber: string
}

export interface DragDropDocuments {
  orderInfo: {
    creationDate : string | Date,
    deliveryDate : string | Date,
    nis : string,
    plateNumber : string,
    requestNumber : string,
    requestState : string,
    requestType : string,
    retribution : string
    vehicleModel : string
  },
  documentation: Documents[]
}

export interface RequestCertificateData {
  fullName: string,
  address: string,
  city: string,
  postalCode: string,
  requestNumber: string,
  vehicleModel: string,
  plateNumber: string,
  loggedUserFullName: string
}

export interface AddDataFine {
  fullName: string,
  address: string,
  city: string,
  postalCode: string,
  vehicleModel: string,
  plateNumber: string,
  loggedUserFullName: string,
  documentKey: string,
  type: string
}

export interface Document {
  documentType: string,
  fileName: string,
  lastUpdate: string,
  personalInfo: PersonalInfo,
  showName: string
}