import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { Order } from '../../shared/model/campaign.model';
import { HttpResponse } from 'src/app/shared/model/httpResponse.model';
import { Campaign, Stock } from '../../shared/model/backoffice.model';
export interface ProfileKeys {
  at: string,
  mk: string,
  omk: string,
  tmk: string
}
@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  urlCampaigns = environment.env === 'dev' ? 'https://campaign-service' + `${environment.API_CONTEXT}/campaign-service` : `${environment.API_CONTEXT}/campaign-service`;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}
  
  PROFILES_KEYS: ProfileKeys = {
    at: 'AT',
    mk: 'MK',
    omk: 'OMK',
    tmk: 'TMK'
  };

  ERROR_MESSAGES = {
    wrongProfiles: 'Campaña Invalida, debe ingresar al menos un perfil valido',
    wrongDates:
      'Campaña Invalida, la fecha de inicio debe ser menor a la fecha de finalización'
  };

  getListCampaigns(): Observable<any> {
    let config;
    if(this.storageService.get('config')) {
      config = this.storageService.get('config');
    }
    return this.http.get(`${this.urlCampaigns}/v1/campaigns`, {
      params: {
        urp: config?.admin ? '2fa1Bp8df5' : config?.profile
      },
    });
  }

  generateOrder( order: Order, userInfo: any, leasedVehicleRest?: any ): Observable<any> {
    let emailInfo = '';
    environment.env === 'prod' ? emailInfo = userInfo.email : emailInfo = 'seat.code.sigeva.lite@nttdata.com';
    const body: any = {
      orderRest: order,
      userRest: {
        email: emailInfo,
        name: 'Fake Name', // userInfo.name
      },
    };
     if (leasedVehicleRest) {
      body['leasedVehicleRest'] = {
        ...leasedVehicleRest,
        mileage: leasedVehicleRest.kms,
      };
    }

    return this.http.post(
     `${this.urlCampaigns}/v2/campaigns/${order.idCampaign}/order`,
      body
    );
  }

  //backoffice
  getCampaignsBackoffice(status: string) : Observable<any> {
    return this.http.get(`${this.urlCampaigns}/v1/backoffice/campaigns`, {
      params: {
        status: status,
        nocache: Date.now().toString()
      }
    });
  }

  validateProfiles(campaign: Campaign): boolean {
    let isValid = false;
    for (const key in this.PROFILES_KEYS) {
      isValid = true;
      if (campaign[key as keyof Campaign]) {
        isValid = true;
      }
    }
    return isValid;
  }
  validateDates(campaign: Campaign): boolean {
    return (
      new Date(campaign.startDate).getTime() <=
      new Date(campaign.endDate).getTime()
    );
  }

  validateCampaign(campaign: Campaign): Error[] {
    const errors: Error[] = [];

    if (!this.validateProfiles(campaign)) {
      errors.push(new Error(this.ERROR_MESSAGES.wrongProfiles));
    }

    if (!this.validateDates(campaign)) {
      errors.push(new Error(this.ERROR_MESSAGES.wrongDates));
    }

    return errors;
  }

  updateCampaign(campaign: Campaign) {
    const errors = this.validateCampaign(campaign);

    if (errors?.length > 0) {
      return throwError(errors);
    }

    return this.http.put<any>(
      `${this.urlCampaigns}/v2/campaigns/${campaign.id}`,
       campaign
    );
  }

  updateStatusCampaign(id: number, status: boolean): Observable<HttpResponse> {
    return this.http.put<HttpResponse>(
      `${this.urlCampaigns}/v1/backoffice/campaigns/${id}`,
      {id, status},
      {
        params: new HttpParams({
          fromObject: {
            action: status ? 'enabled' : 'disabled'
          }
        })
      }
    );
  }

  removeCampaignImage(idCampaign: number) {
    return this.http.delete<any>(
      `${this.urlCampaigns}/v1/campaigns/${idCampaign}/image`
    );
  }

  getProfiles(profiles: string) {
    const profilesArray = profiles?.split(', ');

    return {
      at: profilesArray?.includes(this.PROFILES_KEYS.at),
      mk: profilesArray?.includes(this.PROFILES_KEYS.mk),
      omk: profilesArray?.includes(this.PROFILES_KEYS.omk),
      tmk: profilesArray?.includes(this.PROFILES_KEYS.tmk)
    };
  }

  //backoffice stock
  getVehiclesStock(page: number, size: number): Observable<any> {
    const params = {
      ascendentSorted: 'false',
      page: page.toString(),
      size: size.toString()
      // sortedBy
    };

    return this.http.get(
      `${this.urlCampaigns}/v1/backoffice/vehicleStorage`, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  getSortedVehiclesStock(
    page: number,
    size: number,
    key: string,
    sortType: string
  ): Observable<any> {
    const params = {
      ascendentSorted: sortType === 'asc' ? 'true' : 'false',
      page: page.toString(),
      size: size.toString(),
      sortedBy: key
    };

    return this.http.get(`${this.urlCampaigns}/v1/backoffice/vehicleStorage`, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  getFilteredVehiclesStock(
    page: number,
    size: number,
    key: string,
    value: string
  ): Observable<any> {
    const params = {
      ascendentSorted: 'true',
      page: page.toString(),
      size: size.toString(),
      filteredBy: key,
      filterValue: value
    };

    return this.http.get(`${this.urlCampaigns}/v1/backoffice/vehicleStorage`, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  deleteVehicleOrder(order : Stock) {
    return this.http.delete(`${this.urlCampaigns}/v1/vehicleStorage/${order.id}`);
  }

  cancelVehicleOrder(campaingId: number, orderId: number, email: string, name: string) {
    return this.http.delete(`${this.urlCampaigns}/v2/campaigns/${campaingId}/orders/${orderId}`, {
      body: {
        email: email,
        name: name
      }
    });
  }

  downloadVehiclesOrders(year: string | null) {
    let params = new HttpParams();
    if(year) {
      params = new HttpParams().set('year', year);
    } else {
      params = new HttpParams().set('year', '');
    }
    return this.http.get(`${this.urlCampaigns}/v1/reports/order`, 
    { 
      params: params,
      responseType: 'arraybuffer'
    });
  }
}