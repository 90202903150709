import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { RouterModule } from '@angular/router';
import { MfaAuthenticationComponent } from './mfa-authentication/mfa-authentication.component';
import { I18NextModule } from 'angular-i18next';
import { SmsAuthenticationComponent } from './sms-authentication/sms-authentication.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18NextModule
  ],
  declarations: [
    RegisterComponent,
    EmailVerificationComponent,
    MfaAuthenticationComponent,
    SmsAuthenticationComponent
  ]
})
export class RegisterModule { }
