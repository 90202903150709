import { Component, Input } from '@angular/core';
import { RegistrationDataDTO } from 'src/app/shared/model/order.model';

@Component({
  selector: 'app-frame-insurance-data',
  templateUrl: './frame-insurance-data.component.html',
  styleUrls: ['./frame-insurance-data.component.scss']
})
export class FrameInsuranceDataComponent {
  @Input() vehicleData! : RegistrationDataDTO;

}
