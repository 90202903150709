import { Component, Input } from '@angular/core';
import { BookedOrderDataDTO } from 'src/app/shared/model/order.model';

@Component({
  selector: 'app-book-data',
  templateUrl: './book-data.component.html',
  styleUrls: ['./book-data.component.scss']
})
export class BookDataComponent {
  @Input() bookData! : BookedOrderDataDTO;
}
