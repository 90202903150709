import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EquipmentModalStock } from 'src/app/shared/model/campaign.model';

@Component({
  selector: 'app-modal-equipment',
  templateUrl: './modal-equipment.component.html',
  styleUrls: ['./modal-equipment.component.scss']
})
export class ModalEquipmentComponent {

  @Input() data! : EquipmentModalStock;

  constructor( public activeModal: NgbActiveModal ) { }

}
