<div
  *ngIf="actualStep === 'campaign' || actualStep === 'image'"
  class="upload-campaign-flow"
>
  <div
    class="content-step"
    [ngClass]="actualStep === 'campaign' ? 'active' : 'done'"
  >
    <div class="step">
      <div class="ball-border">
        <div class="ball"></div>
      </div>
      <span class="text">Cargar Excel</span>
    </div>
  </div>
  <div
    class="content-step"
    [ngClass]="actualStep !== 'campaign' ? 'active' : ''"
  >
    <div class="content-bar">
      <div class="progress-bar"></div>
    </div>
    <div class="step">
      <div class="ball-border">
        <div class="ball"></div>
      </div>
      <span class="text">Subir Imagen</span>
    </div>
  </div>
</div>

<div
  *ngIf="actualStep !== 'campaign' && actualStep !== 'image' && actualStep !== 'authorizers'"
  class="upload-users-flow"
>
  <div class="content-step active">
    <div class="step">
      <div class="ball-border">
        <div class="ball"></div>
      </div>
      <span class="text">Cargar Excel</span>
    </div>
  </div>
</div>

<div
  *ngIf="actualStep ==='authorizers'"
  class="upload-users-flow"
>
  <div class="content-step active">
    <div class="step">
      <div class="ball-border">
        <div class="ball"></div>
      </div>
      <span class="text">Cargar csv</span>
    </div>
  </div>
</div>
