<nav class="nav">
  <ul class="nav--list">
    <li [ngStyle]="{'border-bottom': data === 'isCampaigns' ? 'solid 0.1em white' : 'none' }" class="nav--list__item" *ngIf="isAd || !prod || isMasterUser">
      <button class="btn" id="campaigns" (click)="navigateTo('/campaigns')">{{'campaigns' | i18next}}</button>
    </li>
    <li [ngStyle]="{'border-bottom': data === 'isNewRequest' ? 'solid 0.1em white' : 'none' }" class="nav--list__item" *ngIf="!isAd || user.admin">
      <button class="btn" id="newRequest" (click)="navigateTo('/new-request')">{{'newRequestBtn' | i18next}}</button>
    </li>
    <li [ngStyle]="{'border-bottom': data === 'isListRequest' ? 'solid 0.1em white' : 'none' }"  class="nav--list__item"  *ngIf="!isAd">
      <button class="btn" id="listRequest" (click)="navigateTo('/list-request')">{{'showRequestBtn' | i18next}}</button>
    </li>
    <li [ngStyle]="{'border-bottom': data === 'isBackoffice' ? 'solid 0.1em white' : 'none' }" class="nav--list__item">
      <button class="btn" id="backoffice" (click)="navigateTo('/backoffice')" *ngIf="user?.admin">Backoffice</button>
    </li>
    <li [ngStyle]="{'border-bottom': data === 'isAdministrator' ? 'solid 0.1em white' : 'none' }" class="nav--list__item">
      <button class="btn" id="administrator" (click)="navigateTo('/administrator')" *ngIf="user?.admin ">{{'adminConsole' | i18next}}</button>
    </li>
    <li [ngStyle]="{'border-bottom': data === 'isAdminControl' ? 'solid 0.1em white' : 'none' }" class="nav--list__item">
      <button class="btn" id="administrator" (click)="navigateTo('/handleAdminUsers')" *ngIf="user?.admin">{{'handleAdminTitle' | i18next}}</button>
    </li>
  </ul>
</nav>