import { Component, Input } from '@angular/core';
import { OrderInfoDTO, ReplacementVehicleDTO } from '../../../shared/model/order.model';
@Component({
  selector: 'app-info-replacement-vehicle',
  templateUrl: './info-replacement-vehicle.component.html',
  styleUrls: ['./info-replacement-vehicle.component.scss']
})
export class InfoReplacementVehicleComponent {
  @Input() vehicle! : ReplacementVehicleDTO;
  @Input() orderInfo! : OrderInfoDTO;

}
