<div  class="modal-container">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()"  class="form">
      <div class="form--nis">
        <label  class="form--label" for="nis">{{'forgotPasswordTitle' | i18next}} </label>
        <input class="form--input" 
             type="text"
             required
             placeholder="{{'nis' | i18next}}"
             formControlName="nis"
             #inp/>
        <span class="error-message">
          <p [ngClass]="errorMsg('nis')">
            {{'inputInvalid' | i18next}}
          </p>
          <div class="error-message"> {{errorMessage}} </div>
        </span>
      </div>
    </form> 
    <div class="modal-container--btn-container">
      <button type="button" class="btn cancel modalBtn mg-left" (click)="closeModal()">{{'btnCancel' | i18next}}</button>
      <button type="submit" class="btn confirm bgColor modalBtn mg-left" (click)="onSubmit()" [disabled]="!forgotPasswordForm.valid">{{'btnContinue' | i18next}}</button>
      <div class="text-center spinner-container mg-left" *ngIf="spinner">
        <div class="spinner-border spinner" role="status"></div>
      </div>
    </div>
</div>