import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  Data,
  ParamMap
} from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  //type RepalySubject<NavigationEnd>
  private navigationEnd: ReplaySubject<any> = new ReplaySubject(1);

  public url: Observable<string> = this.navigationEnd.pipe(
    map(({ urlAfterRedirects }) => urlAfterRedirects)
  );

  public activatedRoute: Observable<ActivatedRoute> = this.navigationEnd.pipe(
    map(() => {
      let route = this.router.routerState.root;

      while (route.firstChild) {
        route = route.firstChild;
      }

      return route;
    })
  );

  public data: Observable<Data> = this.activatedRoute.pipe(
    switchMap(route => route.data)
  );

  public query: Observable<ParamMap> = this.activatedRoute.pipe(
    switchMap(route => route.queryParamMap)
  );

  constructor(private router: Router) {
    router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(this.navigationEnd);
  }
}
