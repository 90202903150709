import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Colour } from 'src/app/shared/model/colour.model';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';
import { StockService } from 'src/app/core/services/stock.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { Stock } from 'src/app/shared/model/campaign.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-colours',
  templateUrl: './modal-colours.component.html',
  styleUrls: ['./modal-colours.component.scss']
})
export class ModalColoursComponent implements OnInit {

  @Input() colours!: Colour[];
  @Input() stockSelected!: Stock;

  colourSelected?: Colour;
  count = 0;
  iconCircle = faCircle;
  iconCheckCircle = faCheckCircle;

  constructor(public activeModal: NgbActiveModal,
              private router: Router,
              private stockService: StockService,
              private storageService: StorageService,
              private fb: FormBuilder
  ) { }      
  
  ngOnInit() {
    this.colourSelected = this.colours[0];
    this.count = this.colours.length;
    this.form.setValue({
      colour: this.colourSelected
    });
  }
  
  form : FormGroup = this.fb.group({
    colour: []
  });

  setColour(value: Colour) {
    this.form.value.colour = value;
  }

  next() {
    const stockOrder = {
        ...this.stockSelected,
        colour: this.form.value.colour
    };

    this.stockService.selectedStock(stockOrder);
    this.storageService.set('stock', stockOrder);

    this.activeModal.close();
    this.router.navigateByUrl('/campaigns-summary');
  }

}
