<fieldset class="bookData">
  <legend class="bookData--legend float-none w-auto p-2">{{'summaryBookTitle' | i18next}}</legend>
  <section class="bookData--info" *ngIf="bookData">
    <div class="column-1">
      <div class="item">
        <p class="label">{{'summaryBookFor' | i18next}}</p>
        <p class="input">{{bookData.petitionerType}}</p>
      </div>
      <div class="item">
        <p class="label">{{'name' | i18next}}</p>
        <p class="input">{{bookData.name}}</p>
      </div>
      <div class="item">
        <p class="label">{{'firstSurname' | i18next}}</p>
        <p class="input">{{bookData.firstSurname}}</p>
      </div>
      <div class="item">
        <p class="label">{{'secondSurname' | i18next}}</p>
        <p class="input">{{bookData.secondSurname}}</p>
      </div>
    </div>
    <div class="column-2">
      <div class="item">
        <p class="label">{{'bookDate' | i18next}}</p>
        <p class="input">{{bookData.creationDate | date}}</p>
      </div>
      <div class="item">
        <p class="label">{{'phone' | i18next}}</p>
        <p class="input">{{bookData.telephone}}</p>
      </div>
      <div class="item">
        <p class="label">{{'email' | i18next}}</p>
        <p class="input">{{bookData.email}}</p>
      </div>
    </div>
  </section>
</fieldset>