import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import i18next from 'i18next';
import { CoreService } from 'src/app/core/services/core.service';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-handle-admin-users',
  templateUrl: './handle-admin-users.component.html',
  styleUrls: ['./handle-admin-users.component.scss']
})
export class HandleAdminUsersComponent implements OnInit {


  constructor( private fb: FormBuilder,
               private coreService: CoreService,
               private userService : UserService
  ) {  }

  form: FormGroup = this.fb.group({
    nis: [''],
    fullName: [''],
    profile: [''],
    admin: false
  });

  user! : User;
  adminsUsersList : any = [];
  errorMsg = '';
  error = false;
  isAdminControl = 'isAdminControl';

  ngOnInit(): void {
    this.getAdminsUsersList();
  }

  getAdminsUsersList() {
    this.coreService.getAdministrators().subscribe({
      next: (resp)=> {
        this.adminsUsersList = resp;
      }
    });
  }

  onSearch() {
   this.userService.getSelectedUser(this.form.value.nis).subscribe({
      next: (resp) => {
        this.form.setValue({
          nis: resp.nis,
          fullName: resp.fullName,
          profile: resp.category,
          admin: resp.admin
        });
      }, 
      error: (err) => {
        if (err.status === 404) {
          this.error = true;
          this.errorMsg = i18next.t('nisErr');
        } 
      }
    });
  }
  
  save() {
    if(this.form.value.admin) {
      this.coreService.addAdmin(this.form.value.nis).subscribe({
        next: () => {
          this.getAdminsUsersList();
          this.form.setValue({
            nis: '',
            fullName: '',
            profile: '',
            admin: true
          });
        }
      });
    } else {
      this.coreService.deleteAdmin(this.form.value.nis).subscribe({
        next: () => {
          this.getAdminsUsersList();
          this.form.setValue({
            nis: '',
            fullName: '',
            profile: '',
            admin: false
          });
        }
      });
    }
  }
}
