<div class="header" xmlns="http://www.w3.org/1999/html">
  <!-- header -->
  <div class="title">
    {{'backofficeEditCampaign' | i18next}}
  </div>
  <fa-icon
    [icon]="iconClose"
    class="close-icon"
    alt="Cerrar"
    (click)="close()"
  ></fa-icon>
</div>
<div class="content">
  <!-- Body -->
  <form [formGroup]="editForm">
    <div class="fields-content">
      <div class="content-input">
        <input type="text" required formControlName="title" maxlength="100" />
        <span class="highlight"></span>
        <span class="border-bottom"></span>
        <label class="placeholder">{{'backofficeNameCampaign' | i18next}}</label>
      </div>
      <div class="input-title">{{'backofficeType' | i18next}}</div>
      <div class="content-input">
        <select required formControlName="type">
          <option value="ISC">{{'backofficeISC' | i18next}}</option>
          <option value="STO">{{'backofficeSTO' | i18next}}</option>
        </select>
      </div>
      <div class="content-input">
        <textarea type="text" 
                  required
                  maxlength="500"
                  formControlName="description">
        </textarea>
        <span class="highlight"></span>
        <span class="border-bottom"></span>
        <label class="placeholder">{{'backofficeDescription' | i18next}}</label>
      </div>
      <div class="content-input">
        <textarea type="text"
                  required
                  maxlength="500"
                  formControlName="additionalInformation">
        </textarea>
        <span class="highlight"></span>
        <span class="border-bottom"></span>
        <label class="placeholder">{{'backofficeAdditionalInfo' | i18next}}</label>
      </div>
      <div class="input-title">{{'backofficeVisibility' | i18next}}</div>
      <div class="content-input">
        <div class="checkbox-container">
          <input type="checkbox" id="cb-at" formControlName="at" />
          <label for="cb-at">{{'backofficeAT' | i18next}}</label>
        </div>
        <div class="checkbox-container">
          <input type="checkbox" id="cb-mk" formControlName="mk" />
          <label for="cb-mk">{{'backofficeMK' | i18next}}</label>
        </div>
        <div class="checkbox-container">
          <input type="checkbox" id="cb-omk" formControlName="omk" />
          <label for="cb-omk">{{'backofficeOMK' | i18next}}</label>
        </div>
        <div class="checkbox-container">
          <input type="checkbox" id="cb-tmk" formControlName="tmk" />
          <label for="cb-tmk">{{'backofficeTMK' | i18next}}</label>
        </div>
      </div>
       <div class="content-input date">
       <input type="date"
                required
                [max]="campaignEndDate"
                formControlName="startDate"
                (ngModelChange)="changeStartDate($event)"/>
        <span class="highlight"></span>
        <span class="border-bottom"></span>
        <label class="placeholder">{{'backofficeInitialDate' | i18next}}</label>
      </div>
      <div class="content-input date">
        <input type="date"
                required
                [min]="campaignStartDate"
                formControlName="endDate"
                (ngModelChange)="changeEndDate($event)"/>
        <span class="highlight"></span>
        <span class="border-bottom"></span>
        <label class="placeholder">{{'backofficeEndDate' | i18next}}</label>
      </div>
    </div>

    <div class="image-content">
      <div class="image-preview" [ngStyle]="imagePreview">
        <div class="button-wrapper">
          <fa-icon [icon]="iconEdit" class="upload-icon"></fa-icon>
          <input type="file"
                 (change)="onLoadImage($event)"
                 accept="image/*"
                 class="input-file"/>
        </div>
        <div *ngIf="campaignImageUrl" class="button-wrapper button-wrapper-trash">
          <fa-icon
            (click)="onRemoveImage()"
            [icon]="iconTrash"
            class="upload-icon"
          ></fa-icon>
        </div>
        <div *ngIf="uploadingImage" class="upload-process">
          <span class="uploading">{{'backofficeLoadingImg' | i18next}}</span>
        </div>
      </div>
      <div class="image-text-info">
       {{'backofficeImgInfo1' | i18next}}
        {{'backofficeImgInfo2' | i18next}}
      </div>
    </div>
  </form>
  <div class="error-container">
    {{ errorMsg }}
  </div>
</div>
<div class="footer">
  <!-- footer -->
  <div class="btn" (click)="close()">
    {{'btnCancel' | i18next}}
  </div>
  <div class="btn submit" (click)="submit()" [class.disabled]="!editForm.valid">
    {{'btnSave' | i18next}}
  </div>
</div>
