<div class="info-box">
  <div class="content-icon">
    <fa-icon [icon]="iconInfo" class="icon"></fa-icon>
  </div>
  <div class="text">
    {{'backofficeImgText' | i18next}}
  </div>
</div>
<div class="content-upload">
  <div class="title">{{ 'backoffcieIdTitle' | i18next }} {{ info?.campaignId }}</div>
  <div class="description">
    {{'backofficeImgDescription' | i18next}}
  </div>
  <file-pond #filePondUpload name="image" [options]="pondOptions" [files]="pondFiles" (onerror)="onerror($event)"
    class="upload">
  </file-pond>
</div>
<app-process-result [messages]="errorMessage?.errors"></app-process-result>
<div class="content-buttons pb-4">
  <button type="button" class="btn back mr-2 btnNoImg" [routerLink]="['/upload/finally']" [disabled]="btnNoImageStatus">
    {{'btnEndWithoutImg' | i18next}}
  </button>
  <button type="button" class="btn confirm bgColor" [routerLink]="['/upload/finally']" [disabled]="btnImageStatus">
    {{'btnEnd' | i18next}}
  </button>
</div>