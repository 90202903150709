import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class IsNotAuthorizedGuard implements CanActivate {
  user: any;

  constructor(
    private router: Router,
    private authorization: AuthenticationService,
    private storageService: StorageService) {}

  canActivate(): boolean {
    this.storageService.get('config') ? this.user = this.storageService.get('config') : this.user = undefined;
    if (!this.authorization.isAuthorized()) {
      this.user?.isAd ? this.router.navigateByUrl('/access-login') : this.router.navigateByUrl('/login');      
      return false;
    }

    return true;
  }
}