<app-uploader-file
  [serverURL]="serverURL"
  [msgCategory]="msgCategory"
  [header]="header"
  (errorEmmitter)="handleError($event)"
></app-uploader-file>
<ul>
  <li class="text-danger" *ngFor="let message of errorMessage">
      {{ message.value }}
  </li>
</ul>
