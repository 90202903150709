<form class="modal-container">
  <h2>{{'addDocumentation' | i18next}}</h2>
  <div>
    <select formControlName="documentType" 
            class="form-select" 
            name="requestType" 
            #type
            (change)="changeDocumentType(type.value)">
      <option selected="true" disabled="disabled">{{'chooseDocu' | i18next}}</option>
      <option *ngFor="let document of documentation" [value]="document.value">{{document.viewValue}}</option>
    </select>
  </div>
  <div class="drag-drop">
    <file-pond #myPond
               [options]="pondOptions"
               [files]="pondFiles"
               (onaddfile)="pondHandleAddFile($event)">
    </file-pond>
    <p *ngIf="filenameErr || greenZoneErr || genericErr" class="error">{{errorMsg}}</p>
  </div>
  <div class="btn-container">
    <button type="button" class="btn-cancel" (click)="closeModal()">{{'btnBack' | i18next}}</button>
    <button type="button" class="btn" [disabled]="disabled || filenameErr || emptyErr" (click)="uploadFiles()">{{'btnSend' | i18next}}</button>
  </div>
</form>
