import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionInfo } from 'src/app/shared/model/register.model';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs';
import { OrderRequest } from 'src/app/shared/model/order.model';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  urlCore = environment.env === 'dev' ? 'https://core-service' + `${environment.API_CONTEXT}/core-service` : `${environment.API_CONTEXT}/core-service`;
  urlAdmin = this.urlCore + '/administrator';
  constructor(private http: HttpClient) { }

  registerUser(nis: string, password: string, code?: string) {
    const bodyParams = {nis, password, code};
    return this.http.post(
      `${this.urlCore}/identity/signup`,
      bodyParams,
    );
  }

  confirmSignup(nis: string, password: string, confirmCode? : string) {
    const bodyParms = {nis, password, confirmCode};
    return this.http.post(
      `${this.urlCore}/identity/confirmsignup`,
      bodyParms,
    );
  }

  resendConfirmationCode(nis: string) {
    const bodyParamas = {nis};
    return this.http.post(
      `${this.urlCore}/identity/resendconfirmationcode`,
      bodyParamas
    );
  }

  verifyMfa(nis: string, password: string, verificationCode : string, session: string | undefined) {
    const bodyParms = {nis, password, verificationCode, session};
    return this.http.post(
      `${this.urlCore}/identity/verifyMfa`,
      bodyParms,
    );
  }

  signin(nis: string, password: string, telephone?: string){
    const bodyParms = {nis, password, telephone};
    return this.http.post<SessionInfo>(
      `${this.urlCore}/identity/signin`,
      bodyParms,
    );
  }

  forgotPassword(nis: string){
    const bodyParms = {nis};
    return this.http.post(
      `${this.urlCore}/identity/forgotpassword`,
      bodyParms,
    );
  }

  confirmPassword(nis: string, confirmationCode: string, newPassword: string){
    const bodyParmas = {nis, confirmationCode, newPassword};
    return this.http.post(
      `${this.urlCore}/identity/confirmpassword`,
      bodyParmas,
      );
    }

  authchallenge(nis: string, verificationCode: string, session: string) {
    const bodyParams = {nis, verificationCode, session};
    return this.http.post<string>(
      `${this.urlCore}/identity/authchallenge`,
      bodyParams,
    );
  }

  logout(token : string) {
    const bodyParams = { token };
    return this.http.post<string>(
      `${this.urlCore}/identity/signout`,
      bodyParams
      );
  }

  newOrder(orderRequest : OrderRequest) {
    return this.http.post(
      `${this.urlCore}/orders-legacy`,
      orderRequest,
    ).pipe(
      tap(resp =>  {
        return resp;
      })
    );
  }

  verifyAuthCode(code : string) {
    const params = new HttpParams().set('authorizationCode', code);
    return this.http.get<string>(
      `${this.urlCore}/identity/verifyauthcode`,
      {params: params}
      );
  }

  getAdministrators() {
    return this.http.get(`${this.urlAdmin}/administrators`);
  }

  addAdmin(nis: string) {
    const bodyParams = { nis };
    return this.http.post(
      `${this.urlAdmin}/administrator`,
      bodyParams,
    );
  }
  deleteAdmin(nis: string) {
    const bodyParams = { nis };
    return this.http.delete(
      `${this.urlAdmin}/administrator`,
      {body: bodyParams},
    );
  }
}