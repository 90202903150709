<app-navbar data="isBackoffice"></app-navbar>
<app-backoffice-header></app-backoffice-header>
<div class="container-custom">
  <app-table-orders
    [items]="orders"
    [title]="'Stock'"
    [headersTable]="headersTable"
    [options]="{ actionButton: true, actionText: 'Activar' }"
    (sortOrders)="sortTable($event)"
    (filterOrders)="filterTable($event)"
    (resetOrders)="resetOrders()"
    (deleteOrder)="deleteOrder($event)"
  ></app-table-orders>
  <ngb-pagination
    class="pagination"
    [collectionSize]="totalOrdersCount"
    [pageSize]="ordersByPage"
    [(page)]="actualTablePage"
    [maxSize]="5"
    [rotate]="true"
    [boundaryLinks]="true"
    (pageChange)="changePage($event)"
  ></ngb-pagination>
</div>
