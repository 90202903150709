import { Component, Input, OnInit } from '@angular/core';
import { TrimLevel } from 'src/app/shared/model/campaign.model';
@Component({
  selector: 'app-finishes-campaign',
  standalone: false,
  templateUrl: './finishes-campaign.component.html',
  styleUrls: ['./finishes-campaign.component.scss'],
})
export class FinishesCampaignComponent implements OnInit {
  @Input() trimLevels?: TrimLevel[];
  @Input() availableForUser?: boolean;

  constructor() {
  //
  }
  
  ngOnInit(): void {
   //
  }
}
