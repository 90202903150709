import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/shared/model/user.model';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  urlUsers =  environment.env === 'dev' ? 'https://user-service' + `${environment.API_CONTEXT}/user-service` : `${environment.API_CONTEXT}/user-service`;
  
  constructor(
    private http: HttpClient
  ) {}

  getSelectedUser(nis: string): Observable<User> {
    return this.http.get<User>(`${this.urlUsers}/users/${nis}`);
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`${this.urlUsers}/users/nis`);
  }

  getEmails(nis: string[]) {
    return this.http.post(
      `${this.urlUsers}/users-email/emails`,
      {nis: nis}
    ).pipe(
      tap((resp : any) => {
        return  resp;
      })
    );
  }
}
