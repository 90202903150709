<div class="modal-component">
  <header class="content-close animated fadeIn fast">
    <img class="icon" src="./assets/icons/close-icon.png" alt="..." ngbAutofocus
      (click)="activeModal.dismiss('Cross click')" />
  </header>
  
  <main class="content animated fadeIn fast"
    [class.d-none]="!displayVehicleSelection || hasOccurredAnError ">
    <h3 class="title">{{'returnModalVehicleTitle' | i18next}}</h3>
    <p class="useful-message">
      {{'returnModalVehicleSelect' | i18next}}
    </p>
    <form [formGroup]="form" class="form" >
      <div class="form--vehicleType">
        <div class="form--vehicleType__leasing">
          <input type="radio"
          id="leasing"
          formControlName="vehicleType"
          value="leasing"
          [ngClass]="disableArrBtn()"
          #typeArr
          (change)="onChangeVehicleType()"
          (click)="onSelectVehicleType(typeArr.value)"/>
          <label class="check-label" for="leasing" [ngClass]="disableArrBtn()"> {{'typeArr' | i18next}} </label>
        </div>
        <div class="form--vehicleType__endowment">
          <input type="radio"
                 id="endowment"
                 formControlName="vehicleType"
                 value="endowment"
                 [ngClass]="disableDotBtn()"
                 [disabled]="isFirstAssignAvailable()"
                 #typeDot
                 (change)="onChangeVehicleType()"
                 (click)="onSelectVehicleType(typeDot.value)"/>
          <label class="check-label" for="endowment"  [ngClass]="disableDotBtn()"> {{'typeEndowment' | i18next}} </label>
        </div>
      </div>  
      <div class="form--requestType">
        <div class="form--requestType__replacement">
          <input type="radio"
                 id="replacement"
                 formControlName="requestType"
                 value="replacement"
                 #replacement
                 [disabled]="hasExceedTheAmountOfPipelineVehicles || !replacementAvailable"
                 (change)="onChangeRequestType(replacement)"
                 />
          <label for="replacement" class="check-label">{{'subtypeSus' | i18next}}</label>
        </div>
        <div class="form--vehicleType__firstAssign">
          <input type="radio"
                 id="firstAssign"
                 formControlName="requestType"
                 value="firstAssign"
                 [ngClass]="disableFirstAssign()"
                 #firstAssign
                 [disabled]="!isFirstAssignAvailable"
                 (change)="onChangeRequestType(firstAssign)"
          />
          <label for="firstAssign" class="check-label" [ngClass]="disableFirstAssign()">{{'subtypeFirstAssign' | i18next}}</label>
        </div>
      </div>
    </form>
    <div >
    <div *ngIf="loading" class="loading">
      <img src="./assets/img/loader.gif"/><br/>{{'returnModalVehicleWaiting' | i18next}}
    </div>
    <div >
      <p *ngIf="!loading && !hasExceedTheAmountOfPipelineVehicles && !replacementAvailable" class="noSubsAvailable">{{'notAssignedVehicles' | i18next}}</p>
      <p *ngIf="!loading && hasExceedTheAmountOfPipelineVehicles && !replacementAvailable" class="noSubsAvailable">{{'returnModalVehicleExcessText' | i18next}} <br> {{'returnModalTryAgain' | i18next}} <strong>{{type}}</strong></p>
    </div>
    <section *ngIf="!loading && assignedVehicles.length > 0" class="table-cars-for-returning">
      <form [formGroup]="tableForm">
        <table>
          <tr>
            <th scope="col">{{'model' | i18next}}</th>
            <th scope="col">{{'plateNumber' | i18next}}</th>
            <th scope="col">{{'retribution' | i18next}}</th>
            <th scope="col">{{'requestTableFrameNumber' | i18next}}</th>
            <th scope="col"></th>
          </tr>
          <tr *ngFor="let vehicleSelection of assignedVehicles">
            <td> {{ vehicleSelection.model }} </td>
            <td> {{ vehicleSelection.plateNumber }} </td>
            <td> {{ vehicleSelection.retribution }}</td>
            <td> {{ vehicleSelection.vin }} </td>
            <th scope="col">
              <input type="checkbox" 
                     formControlName="tableCheckbox"
                     class="checkbox-vehicle-to-return"
                     [disabled]="form.value.requestType !== 'replacement'"
                     [checked]="isTheCurrentSelectedVehicle(vehicleSelection) && form.value.requestType != 'firstAssign' " 
                     (click)="onSetVehicle(vehicleSelection)" />
            </th>
          </tr>
        </table>
      </form>
    </section>    
    <section class="botonera">
      <button type="button" class="btn button-status-order back" (click)="activeModal.close()">
        {{ "btnBack" | i18next }}
      </button>
      <button type="button"
              class="btn button-status-order confirm ml-5"
              [disabled]="(!continueBtn && !hasSelectedAVehicle) || (form.value.requestType === 'replacement' && vehicle === undefined)"
              (click)="goToSetKM();">
        {{ "btnContinue" | i18next }}
      </button>
    </section>
  </div>
  </main>
  <main class="content animated fadeIn fast d-none" [class.d-none]="displayVehicleSelection || hasOccurredAnError">
    <h3 class="title">{{'returnModalVehicleEnding' | i18next}}</h3>
    <p class="useful-message">
      {{'returnModalVehicleReturnKms' | i18next}}
    </p>
    <section class="km-input">
      <input type="text" placeholder="{{'Km' | i18next}}" (keypress)="numberOnly($event)" (keyup)="onSetKM($event)" />
      <p class="error-message" *ngIf="error">{{'numberError' | i18next}}</p>
    </section>
    <section class="botonera">
      <button type="button" class="btn button-status-order back" (click)="goToSelectVehicle()">
        {{ "btnBack" | i18next }}
      </button>
      <button type="button" class="btn button-status-order confirm ml-5" [disabled]="!hasKMSet"
        (click)="confirmOperation();">
        {{ "btnContinue" | i18next }}
      </button>
    </section>
  </main>
  
  <main class="content animated fadeIn fast d-none" [class.d-none]="!hasOccurredAnError">
    <h3 class="title"> {{'returnModalVehicleErrorTitle' | i18next}} </h3>
    <p class="useful-message">
      {{'returnModalVehicleErrorText' | i18next}}
    </p>
  </main>
</div>