import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-basic-header',
  templateUrl: './basic-header.component.html',
  styleUrls: ['./basic-header.component.scss'],
  providers: []
})
export class BasicHeaderComponent implements OnInit {
  @Input() title!: string;

  constructor() {
    //
  }

  ngOnInit() {
    //
  }
}
