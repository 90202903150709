<app-navbar data="isListRequest"></app-navbar>
<div class="table-striped list">
	<!-- <form class="form" [formGroup]="form">
		<label class="form-check-label form-label">
			<input class="form-check-input" 
			type="radio" 
			formControlName="listRequests" 
			value="ACTIVE"
			id="newRequest"
			#newRequest
			(change)="showRequests(newRequest.value)"> 
		{{'activeRequestBtn' | i18next}}</label>
		<label class="form-check-label form-label" >
			<input class="form-check-input" 
					 	 type="radio" 
						 formControlName="listRequests" 
						 value="INACTIVE"
						 id="oldRequest"
						 #oldRequest
						 (change)="showRequests(oldRequest.value)">
		{{'inactiveRequestBtn' | i18next}}</label>
	</form> -->
	<div class="error" *ngIf="errorList && !spinner">
		<p>{{'errorTryLater' | i18next}}</p>
	</div>
	<div class="main">
		<div class="text-center spinner-container" *ngIf="spinner">
			<div class="spinner-border spinner" role="status"></div>
		</div>
		<div class="empty-container" *ngIf="empty && !spinner">
			<p class="empty-text">{{'requestTableNoResults' | i18next}}</p>
		</div>
		<div class="main--table">
			<app-table *ngIf="!empty && requests && !spinner" 
									[requests]='requests'
									[pagination]='pagination'
									[requestList]='requestList'>
			</app-table>
		</div>
	</div>
  </div>