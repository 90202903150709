import { Component, OnInit, Input } from '@angular/core';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-process-result',
  templateUrl: './process-result.component.html',
  styleUrls: ['./process-result.component.scss']
})
export class ProcessResultComponent implements OnInit {
  @Input() messages!: string[];

  constructor() {
    //
  }

  iconError = faTimes;
  iconSuccess = faCheck;

  ngOnInit() {
    //
  }
}
