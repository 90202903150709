<fieldset class="request">
  <legend class="request--legend float-none w-auto p-2">{{'summaryTitle' | i18next}}</legend>
  <section class="request--section">
    <div class="request--fieldSubs" *ngIf="orderInfo">
      <div class="request--item">
        <p class="request--label">{{'requestType' | i18next}}</p>
        <p class="request--text">{{orderInfo.orderType}}</p>
      </div>
      <div class="request--item">
        <p class="request--label">{{'summaryRetType' | i18next}}</p>
        <p class="request--text">{{orderInfo.retributionType}}</p>
      </div>
      <div class="request--item">
        <p class="request--label">{{'requestSubtype' | i18next}}</p>
        <p class="request--text">{{orderInfo.subsType}}</p>
      </div>      
      <div class="request--item">
        <p class="request--label">{{'requestCampaign' | i18next}}</p>
        <p class="request--text">{{orderInfo.campaignId}}</p>
      </div>
      <div class="request--item">
        <p class="request--label">{{'requestCampaignType' | i18next}}</p>
        <p class="request--text"></p>
      </div>
    </div>
    <div>
      <p  class="subs-title">{{'summaryVehicleSubsTitel' | i18next}}</p>
      <div class="request--fieldCar" *ngIf="vehicle">
        <div>
         <div class="request--item">
            <p class="request--label">{{'model' | i18next}}</p>
            <p class="request--text">{{vehicle.model}}</p>
          </div>
         <div class="request--item">
            <p class="request--label">{{'summaryDeliveryDate' | i18next}} </p>
            <p class="request--text">{{vehicle.deliveryDate}}</p>
          </div>  
          <div class="request--item">
            <p class="request--label">{{'summaryReturnDate' | i18next}}</p>
            <p class="request--text">{{vehicle.returnDate}}</p>
          </div>
          <div class="request--item">
            <p class="request--label">{{'plateNumber' | i18next }}</p>
            <p class="request--text">{{vehicle.plateNumber}}</p>
          </div>
        </div>
        <div>
          <div class="request--item">
            <p class="request--label km">{{'summaryActualKms' | i18next}}</p>
            <p class="request--text ">{{vehicle.actualKms}} {{'Km' | i18next }}</p>
          </div>
          <div class="request--item">
            <p class="request--label km">{{'summaryEndKms' | i18next}}</p>
            <p class="request--text">{{vehicle.finalKms}} {{'Km' | i18next }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</fieldset>