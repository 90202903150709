
<app-navbar data="isAdministrator"></app-navbar>
<main class="administrator">
 
  <h3 class="administrator--title">{{'adminConsole' | i18next}}</h3>
  <h5 class="administrator--subtitle">{{'adminConsoleSubtitle' | i18next}}</h5>
  <form class="administrator--form" [formGroup]="form" (submit)="searchOrders(1)">
    <div class="administrator--form__row">
      <!-- nis -->
      <div class="inputErrorContainer">
        <input class="administrator--form__input textInput"
               formControlName="nis"
               type="text"
               placeholder="{{'nis' | i18next}}"
               [title]="nisValue"
               #autofocus>
        <span class="content-error" *ngIf="nisError">{{'nisErr' | i18next}}</span>
      </div>
      <!-- Bastidor/es -->
      <div class="inputErrorContainer horizontalPadding">
       <input class="administrator--form__input textInput"
              formControlName="frameNumber"
              type="text"
              placeholder="{{'frameNumber' | i18next}}"
              [title]="frameValue">
      </div>
      <!--  Matricula/s -->
      <div class="inputErrorContainer">
        <input class="administrator--form__input textInput"
               formControlName="plateNumbers"
               type="text"
               placeholder="{{'framePlaceholder' | i18next}}"
               [title]="plateValue">
      </div>
      <!--  Nombre completo -->
      <div class="inputErrorContainer horizontalPadding">
        <input class="administrator--form__input textInput"
               formControlName="fullName"
               type="text"
               placeholder="{{'namePlaceholder' | i18next}}"
               [title]="fullName">
       </div>  
    </div>
    <div class="administrator--form__row">
      
      <!-- fecha de solicitud -->
      <div class="administrator--form__dateContainer">
        <div class="inputErrorContainer">
          <div class="inputContainer">
            <label for="orderDate" class="label">{{'adminDateOrder' | i18next}}</label>
            <div>
              <input class="dateInput"
                     type="date"
                     name="orderDate"
                     formControlName="orderDateFrom"
                     #orderDateFrom 
                     (change)="orderDate(orderDateFrom.value)"/> 
              <span class="dateText">{{'adminDateTo' | i18next}}</span>
              <input class="dateInput"
                     type="date"
                     name="orderDate"
                     formControlName="orderDateTo"
                     #orderDateTo 
                     (change)="orderDate(orderDateTo.value)"/> 
            </div>
          </div>
          <span *ngIf="orderDateError" class="content-error">{{errorDateMsg}}</span>
        </div>
      </div>
      <!-- fecha fin de jubilación -->
      <div class="administrator--form__dateContainer horizontalPadding">
        <div class="inputErrorContainer">
          <div class="inputContainer">
            <label for="retirementDate" class="label">{{'adminDateRetirement' | i18next}}</label>
            <div>
              <input class="dateInput"
                     type="date"
                     name="retirementDate"
                     formControlName="pssDateFrom"
                     #pssDateFrom 
                     (change)="retirementDate(pssDateFrom.value)"/> 
              <span class="dateText">{{'adminDateTo' | i18next}}</span>
              <input class="dateInput"
                     type="date"
                     name="retirementDate"
                     formControlName="pssDateTo"
                     #pssDateTo 
                     (change)="retirementDate(pssDateTo.value)"/> 
            </div>
          </div>
          <span *ngIf="retirementDateError" class="content-error">{{errorWrongDateMsg}}</span>
        </div>
      </div>
       <!-- tipo de solicitud -->
       <div class="administrator--form__typeContainer type">
        <label for="orderType" class="label ">{{'requestType' | i18next}}</label>
        <div class="radioInput">
            <input type="radio"
                   name="orderType"
                   value="all"
                   formControlName="orderType"
                   id="orderTypeAll"
                   #typeAll
                   (change)="orderType(typeAll.value)"
                   checked>
            <label class="check-label" for="orderTypeAll">
              {{'typeAll' |i18next}}
            </label>
            <input type="radio"
                   value="leasing"
                   formControlName="orderType"
                   id="orderTypeLeasing" 
                   #typeLeasing
                   (change)="orderType(typeLeasing.value)">
            <label class="check-label" for="orderTypeLeasing">
              {{'typeArr' | i18next}}
            </label>
            <input type="radio"
                   value="endowment"
                   formControlName="orderType"
                   id="orderTypeEndwoment"
                   #typeEndowment
                   (change)="orderType(typeEndowment.value)">
            <label class="check-label" for="orderTypeEndwoment">
              {{'typeEndowment' | i18next}}
            </label>
        </div>
      </div>
      <!-- Checkbox multas -->
      <div class="administrator--form__checkbox">
        <div>
          <input formControlName="alert" type="checkbox" value="" id="checkFine">
          <label class="check-box--label" for="checkFine">
             {{'adminFrame' | i18next}}
          </label>
        </div>
        <div class="alert" >
          <img *ngIf="fineAlert" title="{{'adminFrame' | i18next}}" class="alert--icon" src="../../../../assets/icons/error-16.svg" alt="{{'altAlert' | i18next}}">
        </div>
      </div>
    </div>
    <div class="filterBtnContainer">
      <button type="button" class="filterBtn" (click)="cleanForm()">{{'btnDeleteFilter' | i18next}}</button>
      <button type="submit" class="filterBtn" [disabled]="nisError || orderDateError || retirementDateError">{{'btnSearch' | i18next}}</button>
    </div>
  </form>
  <section class="administrator--results">
    <!-- resultados -->
    <h5 class="administrator--subtitle">{{'adminResults' | i18next}}</h5>
    <div class="text-center spinner-container" *ngIf="spinner">
      <div class="spinner-border spinner" role="status"></div>
    </div>
    <app-table *ngIf="!spinner && !empty"
               [requests]="searchedOrders" 
               [pagination]="pagination"
               [itemsPerPage]="itemsPerPage"
               [totalItems]="totalItems"
               [admin]="admin"
               (pageEmitter)="searchOrders($event)">
    </app-table>
    <p>{{adminText}}</p>
  </section>
  <div class="btnContainer">
    <div class="btnContainer--info">
      <button class="btn downloadBtn" *ngIf="!empty && !spinner" (click)="exportData()">{{'downloadCSV' | i18next}}</button>
      <button class="btn emailBtn" *ngIf="!empty && !spinner" (click)="sendEmail()">{{'adminSendEmail' | i18next}}</button>
    </div>
  </div>
</main>
