import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../core/services/navigation.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  actualStep = 'campaign';
  title = '';

  constructor(private navigation: NavigationService) {}

  ngOnInit() {
    this.navigation.data.subscribe(data => {
      this.actualStep = data['url'];
      this.setTitle(data['url']);
    });
  }

  setTitle(url: string) {
    if (url === 'users') {
      this.title = 'Nuevos usuarios';
    } else if (url === 'campaign') {
      this.title = 'Nueva campaña';
    } else if (url === 'authorizers') {
      this.title = 'Añadir autorizadores';
    }
  }
}
