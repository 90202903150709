<app-navbar data="isBackoffice"></app-navbar>
<app-backoffice-header></app-backoffice-header>
<div
  class="container-custom"
  [ngClass]="actualStep === 'finally' ? 'hide-layout full-height' : ''"
>
  <div class="title">{{ title }}</div>
  <div class="content">
    <div class="content-stepper">
      <app-stepper [actualStep]="actualStep"></app-stepper>
    </div>
    <div class="content-upload">
       <router-outlet></router-outlet>
    </div>
  </div>
</div>
