import { Injectable } from '@angular/core';
import { Stock } from 'src/app/shared/model/campaign.model';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  private stock!: Stock;
  selectedStock(stock: Stock) {
    this.stock = stock;
  }

  getSelectedStock() {
    return this.stock;
  }

}
