import { NgModule } from '@angular/core';
import { UploadComponent } from './upload.component';
import { CampaignComponent } from './campaign/campaign.component';
import { AuthorizersComponent} from './authorizers/authorizers.component';
import { ImageComponent } from './image/image.component';
import { FinallyComponent } from './finally/finally.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ButtonGoBackComponent } from './shared/button-go-back/button-go-back.component';
import { StepperComponent } from './shared/stepper/stepper.component';
import { UsersComponent } from './users/users.component';

// import filepond module
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilepondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import { UploaderComponent } from 'src/app/shared/upload/file/file-uploader.component';
import { ProcessResultComponent } from 'src/app/shared/process-result/process-result.component';
import { BackofficeHeaderComponent } from 'src/app/layout/backoffice-header/backoffice-header.component';
import { I18NextModule } from 'angular-i18next';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilepondPluginImageValidateSize
);

@NgModule({
  declarations: [
    UploadComponent,
    CampaignComponent,
    AuthorizersComponent,
    ImageComponent,
    FinallyComponent,
    ButtonGoBackComponent,
    StepperComponent,
    UsersComponent,
    UploaderComponent,
    ProcessResultComponent,
  ],
  imports: [
    SharedModule, 
    FilePondModule,
    BackofficeHeaderComponent,
    I18NextModule.forRoot()
  ],
  exports: [UploadComponent]
})
export class UploadModule {}
