import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { OrderResponse } from '../../model/request-list.model';
import { Router } from '@angular/router';

import { StorageService } from 'src/app/core/storage/storage.service';
import { PersonalInfo } from '../../model/request-detail.model';
import { I18NextModule } from 'angular-i18next';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-table',
  standalone: true,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  imports: [
    NgxPaginationModule,
    I18NextModule,
    CommonModule
  ]
})
export class TableComponent implements OnChanges {
  @Input() requests!: OrderResponse[];
  @Input() pagination! : boolean;
  @Input() itemsPerPage! : number;
  @Input() totalItems = 8;
  @Input() admin? : boolean;
  @Input() requestList? : boolean;

  @Output() pageEmitter = new EventEmitter<number>();
  empty= false;
  page = 1;
  spinner = false;
  bookId!: number;

  constructor( private router : Router,
               private storageService : StorageService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['requests']){
      this.spinner = false;
    }
  }

  

  getOrder(requestNumber: string, requestNis: string, requestCertificate: PersonalInfo | undefined, requestPhone: string, bookingId: number | null) {
    //mock storage service para poder acceder a la info desde el detalle.
    this.storageService.set('certificate', requestCertificate);
    if (!!requestNis ) {
      this.storageService.set('requestNis', requestNis);
      this.storageService.set('requestNisPhone', requestPhone);
    } else {
      this.storageService.set('requestNis', '');
    }
    bookingId ? this.storageService.set('bookingId', bookingId) : this.storageService.set('bookingId', null);
    this.router.navigateByUrl(`order-summary/${requestNumber}`);
  }

  changePage(ev: number) {
    this.spinner = true;
    this.page = ev;
    this.pageEmitter.emit(ev);
  }

}
