<div class="modal-container">
  <p class="modal-container--text">{{text}}</p>
  <div *ngIf="!cancelOrder" class="modal-container--btn-container">
    <button *ngIf="!redirect" type="button" class="btn confirm bgColor" (click)="closeModal()">{{'btnContinue' | i18next}}</button>
    <button *ngIf="redirect && !list" type="button" class="btn confirm bgColor" (click)="closeModal()" [routerLink]="['/login']">{{'btnContinue' | i18next}}</button>
    <button *ngIf="redirect && list" type="button" class="btn confirm bgColor" (click)="closeModal()" [routerLink]="['/list-request']">{{'btnContinue' | i18next}}</button>
  </div>
  <div *ngIf="cancelOrder" class="modal-container--btn-container">
    <button class="btn back" type="button" (click)="closeModal()">{{'btnNo' | i18next}}</button>
    <button class="btn confirm bgColor" type="button" (click)="confirmCancel()">{{'btnYes' | i18next}}</button>
  </div>
</div>