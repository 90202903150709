import { Component, OnInit } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { StorageService } from 'src/app/core/storage/storage.service';
@Component({
  selector: 'app-finally',
  templateUrl: './finally.component.html',
  styleUrls: ['./finally.component.scss']
})
export class FinallyComponent implements OnInit {
  iconCheck = faCheckCircle;
  info : any;

  constructor(private storage: StorageService) {}

  ngOnInit() {
    this.info = this.storage.get('info');
    this.storage.removeItem('info');
  }
}
