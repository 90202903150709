import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PccVehicle } from 'src/app/shared/model/vehicle.model';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

  urlVehicle =  environment.env === 'dev' ? 'https://vehicle-service' + `${environment.API_CONTEXT}/vehicle-service/v1/vehicles` : `${environment.API_CONTEXT}/vehicle-service/v1/vehicles`;
  urlVehicleRetribution = this.urlVehicle + '/retribution';

  constructor( private http: HttpClient ) { }

  getVehiclesByVehicleTypeAndNIS(vehicleType: string): Observable<any> {
    const params = {vehicleType};

    return this.http.get(
      `${this.urlVehicle}/user-vehicles`,
      {params},
    ).pipe(tap((resp) => {
          return resp;})
      );
  }

  checkEmployeeCanRentMoreVehicles(nis: string, vehicleType: string): Observable<any> {
      return this.http.get(
        `${this.urlVehicle}/nis?nis=${nis}&vehicleType=${vehicleType}`
      );
    }

  getVehiclesRetribution(frameNumberAssign: string, nis: string, frameNumberSubs: string, vehicleType: string){  
    return this.http.post(
      `${this.urlVehicleRetribution}`,
        {frameNumberAssign, nis, frameNumberSubs, vehicleType}
    ).pipe(
      tap((resp) => {
        return resp;
      })
    );
  }

  getPccVehicle(seatCode: string) {
    const params = {seatCode};
    return this.http.get<PccVehicle>(
      `${this.urlVehicle}/vehicle-configuration`,
      {params},
    ).pipe(
      tap((resp) => {
        return resp;
      })
    );
  }
}
