import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import i18next from 'i18next';
import { saveAs } from 'file-saver';

import { DataEntryService } from 'src/app/core/services/data-entry.service';
@Component({
  selector: 'app-documentation-info',
  templateUrl: './documentation-info.component.html',
  styleUrls: ['./documentation-info.component.scss'],
})
export class DocumentationInfoComponent implements OnInit {
  group!: string;
  groupTranslation = '';
  documentsResponse!: string[];
  documents:string[] = [];

  constructor( private route: ActivatedRoute,
               private dataEntryService: DataEntryService) {}

  ngOnInit(): void {
    this.route.queryParams?.subscribe((params) => {
      const group = params['group'];
      if (!!group) {
        this.group = group;
        this.dataEntryService.getPublicDocumentation(this.group).subscribe({
          next: (resp: string[]) => {     
            this.documentsResponse = resp;        
            this.documentsResponse.map((document: string) => {
              const documentation =  document.split('/').pop();
              if (!!documentation) {
                return this.documents.push(documentation);
              } else {
                return '';
              }
            });
            this.group === 'VDAF' ? this.groupTranslation = i18next.t('vdaf') : this.groupTranslation = i18next.t('vppsg');
          }
        });
      }
    });
    

    
  }

  showDocument(document: string) {
    const documentName = `${this.group}/${document}`;
    const documentKey = {
      documentKey: documentName,
      group: this.group
    };
    this.dataEntryService.viewPublicDocument(documentKey).subscribe({
      next: (resp: any) => {
        const blob = new Blob([resp], {type: 'application/pdf'});
        saveAs(blob,documentName);
      }
      
    });
  }

}
