import { Component, Input } from '@angular/core';
import { QuotaRegularizationDTO } from 'src/app/shared/model/order.model';

@Component({
  selector: 'app-quota-info',
  templateUrl: './quota-info.component.html',
  styleUrls: ['./quota-info.component.scss']
})
export class QuotaInfoComponent {
  @Input() quotaData! : QuotaRegularizationDTO;
}
