import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { FilePondModule } from 'ngx-filepond';
import { environment } from 'src/environments/environment';

import i18next from 'i18next';
import { StorageService } from 'src/app/core/storage/storage.service';
import { documentationType } from '../../model/documentation.model';
import { Documents } from '../../model/request-detail.model';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  standalone: true,
  imports: [
    NgbDropdownModule,
    I18NextModule,
    FilePondModule,
    CommonModule
  ],
  styleUrls: ['./drag-drop.component.scss']
})
export class DragDropComponent {

  @ViewChild('myPond') myPond: any;

  @Input() modalData! : any;
  @Output() getDocumentsEmitter = new EventEmitter<boolean>();

  pondOptions: any = {
    labelIdle: i18next.t('dragAndDrop'),
    server: null,
    class: 'my-filepond',
    multiple: false,
    acceptedFileTypes: 'application/pdf',
    allowDrop: true,
    allowBrowse: true,
    allowMultiple: false,
    onremovefile: () => {
      this.emptyErr = true;
      this.filenameErr = false;
      this.greenZoneErr = false;
    },
  };
  pondFiles = [];

  form: FormGroup = this.fb.group({
    documentType: [null]
  });

  documentation = documentationType.map(type => {
    return {
      value : type.value,
      viewValue: i18next.t(type.viewValue)
    };
  });
  disabled = true;
  addDocuError = false;
  getDocuments = true;
  url = environment.env === 'dev' ? 'https://data-entry-service' + `${environment.API_CONTEXT}/data-entry-service/v1/private` : `${environment.API_CONTEXT}/data-entry-service/v1/private`;
  //errores
  filenameErr = false;
  emptyErr?: boolean;
  errorMsg = '';
  greenZoneErr = false;
  genericErr = false;
  token = '';
  greenZone = false;
  nameErrorController? : boolean;

  constructor( private activeModal : NgbActiveModal,
               private fb : FormBuilder,
               private storageService: StorageService
    ) {}
  
  pondHandleAddFile(event: any) {
    if (this.modalData?.documentation?.length > 0) {
      this.nameErrorController = this.modalData.documentation.some((item: Documents) => {
        const listedFilename = this.storageService.get('requestNis') + '/' + this.modalData.orderInfo.orderNumber + '/' + event.file.filename;
        return listedFilename === item.fileName;
      });
      if(this.nameErrorController) {
        this.filenameErr = true;
        this.errorMsg = i18next.t('fileNameInvalid');
      } else {
        this.errorMsg = '';
        this.filenameErr = false;
        this.emptyErr = false;
      }
      } else {
        this.emptyErr = false;
      }
    }
  
  changeDocumentType(value: string) {
    if (value === 'null') {
      this.form.value.documentType = null;
      this.disabled = true; 
    } else {
      this.disabled = false;
      this.form.value.documentType = value;
    }
  }
  
  uploadFiles() {
    if (this.modalData?.documentation?.length > 0) {
        this.greenZone = this.modalData.documentation.some((item: Documents) => {
        return item.documentType === 'GREEN_ZONE_CERTIFICATE';
      });
    }
    this.storageService.get('token') ? this.token = this.storageService.get('token').token : this.token = '';
    if (this.greenZone && this.form.value.documentType === 'GREEN_ZONE_CERTIFICATE') {
      this.greenZoneErr = true;
      this.errorMsg = i18next.t('certZoneError');
    } else {
      this.greenZoneErr = false;
      this.errorMsg = '';
      this.token = this.storageService?.get('token') ? this.storageService?.get('token').token : '';
      this.myPond?.pond?.setOptions({
        server: {
          process: {
            url: `${this.url}/docs/u`,
            headers: { Authorization: 'Bearer ' + this.token },
            ondata: (formData:any) => { 
              // > POST META DATA 
              const formJson= JSON.stringify({
                nis: this.storageService.get('requestNis'),
                nisDocumentUploader: this.storageService.get('config').nis,
                requestNumber: this.modalData.orderInfo.orderNumber,
                type: this.form.value.documentType,
                email:'seat.code.sigeva.lite@nttdata.com',
                phoneNumber: this.storageService.get('requestNisPhone'),
                model: this.modalData.orderInfo.replacementVehicleDTO.model,
                plateNumber: this.modalData.orderInfo.registrationDataDTO.plateNumber
              });
              formData.append('extraInfo', new Blob([formJson], {type:'application/json'})); 
              return formData; 
            }, 
            onload: () => {
              this.getDocumentsEmitter.emit(this.getDocuments);
            },
            onerror: ()=> {
              this.genericErr = true;
              this.errorMsg = i18next.t('responseError');
            },
            onremovefile: () => {
              this.emptyErr = true;
            },
          } 
        } 
      });
      this.myPond?.processFile();
    }
  }

  closeModal() { 
    this.activeModal.close();
  }
}
