<fieldset class="vehicleData" *ngIf="vehicleInfo">
  <legend class="vehicleData--legend float-none w-auto p-2">{{'bookVehicleDataTitle' | i18next}}</legend>
  <div class="vehicleData--container">
    <section class="vehicleData--container__model">
      <div class="vehicleData--item">
        <p class="vehicleData--title">{{'model' | i18next}}</p>
        <p class="vehicleData--text">{{vehicleInfo.requestedVehicleDTO.tmaimg}}</p>
      </div>
      <div class="vehicleData--item">
        <p class="vehicleData--title">{{'modelDesc' | i18next}}</p>
        <p class="vehicleData--text">{{vehicleInfo.requestedVehicleDTO.model}}</p>
      </div>
      <div class="vehicleData--item">
        <p class="vehicleData--title">{{'plateNumber' | i18next}}</p>
        <p class="vehicleData--text">{{vehicleInfo.registrationDataDTO.plateNumber}}</p>
      </div>
    </section>
    <section class="vehicleData--container__date">
      <div class="vehicleData--item">
        <p class="vehicleData--title date">{{'summaryDeliveryDate' | i18next}}</p>
        <p class="vehicleData--text">{{vehicleInfo.registrationDataDTO.deliveryDate}}</p>
      </div>
      <div class="vehicleData--item">
        <p class="vehicleData--title date">{{'summaryFrameNumberDate' | i18next}}</p>
        <p class="vehicleData--text">{{vehicleInfo.registrationDataDTO.frameNumberDate}}</p>
      </div>
    </section>
  </div>
</fieldset>