<nav class="navbar container-custom">
    <div class="logo">
        <button (click)= "onClickLogo()">
        <img class="logo--img" src="assets/img/seat-logo-horizontal.png" alt="{{'altLogo' | i18next}}">
        </button>
    </div>
    <p class="menu">Dotación y Arrendamiento</p>
    <div class="nav">
        <div class="user" *ngIf="user">
            <img class="user--icon" src="assets/icons/user-32.svg" alt="{{'altUser' | i18next}}">
            <p class="user--name">{{user.fullName}} ({{user.nis}})</p>
        </div>
        <button class="exit" (click)="onClickLogOut()" title="{{'navbarTooltip' | i18next}}"> <img src="assets/icons/logout-24.svg" class="exit--icon" alt="{{'altExit' | i18next}}"></button>
    </div>
</nav>
