import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Campaign } from '../../../shared/model/backoffice.model';
import { FormGroup } from '@angular/forms';
import { CampaignsService } from '../../../core/services/campaigns.service';
import GroupModalEditCampaignBuilder from './config/form-group-builder';
import {
  getFromGroupModalEditCampaignDates,
  getFromGroupModalEditCampaignInfo,
  getFromGroupModalEditCampaignProfilesVisibility
} from './config/form-group';
import { onLoadInputFile } from 'src/utils/Files';
import { ModalFeedbackComponent } from '../modal-feedback/modal-feedback.component';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { DataEntryService } from 'src/app/core/services/data-entry.service';

export const DEFAULT_CAR_IMAGE = '/assets/img/default-car.jpg';

const generateImagePreview = (url: string) => ({
  background: `url(${url}) center center / cover no-repeat`
});

@Component({
  selector: 'app-edit-campaign',
  templateUrl: './modal-edit-campaign.component.html',
  styleUrls: ['./modal-edit-campaign.component.scss']
})
export class ModalEditCampaignComponent implements OnInit {
  @Input() campaign!: Campaign;

  campaignEndDate?: string;
  campaignStartDate?: string;
  errorMsg = '';
  // Current Image's URL
  campaignImageUrl = '';
  // Image Preview
  uploadingImage = false;
  imagePreview = {
    background: ''
  };
  // Icons
  iconClose = faTimes;
  iconEdit = faEdit;
  iconTrash = faTrashAlt;
  constructor(
    public activeModal: NgbActiveModal,
    private campaignService: CampaignsService,
    private dataEntryService: DataEntryService,
    private modalConfig: NgbModal,
  ) {}
    
  ngOnInit() {
    this.initForm();
    this.initDates();
    this.setImagePreview(this.campaign?.urlImage);
  }
    
  editForm!: FormGroup;
  updatedCampaign?: any;

  get f() {
    return this.editForm.controls;
  }

  initDates() {
    this.campaignStartDate = this.campaign?.startDate;
    this.campaignEndDate = this.campaign?.endDate;   
  }

  initForm() {
    const profiles = this.campaignService.getProfiles(
      this.campaign?.profiles.trim()
    );
    this.campaignImageUrl = this.campaign?.urlImage;

    this.editForm = new GroupModalEditCampaignBuilder()
      .setProfilesVisibility(
        getFromGroupModalEditCampaignProfilesVisibility(profiles)
      )
      .setInfo(getFromGroupModalEditCampaignInfo(this.campaign))
      .setDates(
        getFromGroupModalEditCampaignDates(
          this.campaign?.startDate,
          this.campaign?.endDate
        )
      )
      .build(); 
  }

  changeStartDate(date: any) {
    this.campaignStartDate = date;
  }

  changeEndDate(date : string) {
    this.campaignEndDate = date;
  }

  onLoadImage($event : any) {
    const file = $event.target?.files[0];
    this.uploadingImage = true;

    onLoadInputFile(
      file,
      (data:any) => this.uploadImageToS3(data),
      () => (this.uploadingImage = false)
    );
  }

  onRemoveImage() {
    this.setImagePreview(null);
    this.campaignImageUrl = '';
    this.removeCampaignImage();
  }

  uploadImageToS3(file: any) {
    this.dataEntryService.uploadImageToS3(file).subscribe({
      next: (resp : any) => {
        this.setImagePreview(resp.data);
        this.campaignImageUrl = resp.data;
      },
      error: (msg : string) => {
        this.errorMsg = msg;
      },
      complete: () => {
        this.uploadingImage = false;
      }
    });
  }

  removeCampaignImage() {
    if(this.campaign?.id) {
      this.campaignService.removeCampaignImage(this.campaign.id).subscribe({
        next: () => {
          this.campaignImageUrl = '';
        },
        error: msg => {
          this.errorMsg = msg;
        }
      });
    }
  }

  setImagePreview(url: any) {
    this.imagePreview = generateImagePreview(url || DEFAULT_CAR_IMAGE);
  }

  submit() {
    if (this.editForm.valid) {
      this.updatedCampaign = this.editForm.value;
      this.updatedCampaign.urlImage = this.campaignImageUrl;

      this.campaignService.updateCampaign(this.updatedCampaign).subscribe({
        next: () => {
          this.activeModal.close(true);
        },
        error: messages => this.onSubmitError(messages)
      });
    }
  }

  onSubmitError(messages: string[]) {
    const fullMessage = messages.join('.');

    this.openErrorModal(fullMessage);
    this.errorMsg = fullMessage;
  }

  openErrorModal(msg: string) {
    const modalRef = this.modalConfig.open(ModalFeedbackComponent, {
      centered: true
    });

    modalRef.componentInstance.type = 'error';
    modalRef.componentInstance.msg = msg;
  }

  close() {
    this.activeModal.close();
  }
}
