import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent {
  @Input() data: any;
  @Input() message?: string;

  // icons
  iconClose = faTimes;

  constructor(public activeModal: NgbActiveModal) {}

  submit() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(null);
  }
}
