export interface Campaign {
  additionalInformation: string;
  endDate: string;
  description: string;
  id: number;
  isEnabled: boolean;
  isOutOfTimeRange: boolean;
  key?: string
  model: string;
  profiles: string;
  remainingStock: number;
  startDate: string;
  title: string;
  type: string;
  urlImage: string;
}

export interface updateStatusCampaing {
  campaign: Campaign,
  status: boolean
}

export interface Stock {
  nis: string,
  actionModelPackages: string,
  baseFee: number,
  campaignId: number,
  co2: string, 
  commissionCode: string,
  externalColour: string,
  fiscalFee: number,
  frame: string,
  fuelType: string,
  id: number,
  increaseB: number,
  increaseC: number,
  internalColour: string,
  mkAt: number,
  modelYear: string,
  omkTmk: number,
  orderCode: number,
  prOptionalIncluded: string,
  requestNumber: string,
  tmaimg: string,
  tradeName: string,
  transmission: string,
  trimLevel: string,
  updateDate: string
}

export interface Order {
  id: string;
}
/*
  Fields to show:
  - Id Campaña
  - Año
  – Modelo
  – TMAIMG
  – Request Number
  – Base Fee
  – Comision
  – Order code
  – Update date
*/
export interface ITableHeaders {
  name: string;
  filterable: boolean;
  sortable: boolean;
  key: string;
}

export const tableHeaders = [
  {
    name: 'ID Campaña',
    key: 'campaignId',
    filterable: true,
    sortable: true
  },
  {
    name: 'Año',
    key: 'modelYear',
    filterable: false,
    sortable: false
  },
  {
    name: 'Model',
    key: 'tradeName',
    filterable: false,
    sortable: false
  },
  {
    name: 'TMAIMG',
    key: 'tmaimg',
    filterable: false,
    sortable: false
  },
  {
    name: 'NIS',
    key: 'nis',
    filterable: true,
    sortable: true
  },
  {
    name: 'Número de solicitud',
    key: 'requestNumber',
    filterable: false,
    sortable: false
  },
  {
    name: 'Tarifa base',
    key: 'baseFee',
    filterable: false,
    sortable: true
  },
  {
    name: 'Comisión',
    key: 'commissionCode',
    filterable: true,
    sortable: false
  },
  {
    name: 'Código',
    key: 'orderCode',
    filterable: true,
    sortable: false
  },
  {
    name: 'Fecha actualización',
    key: 'updateDate',
    filterable: false,
    sortable: true
  }
];

export interface Filter {
  value: string,
  key: string
}

export interface Sort {
  type: string,
  key: string
}

export interface BackofficeHeader {
  header: boolean,
  footer: boolean,
  uploadCampaign?: boolean,
  uploadAuthorizers?: boolean,
  page: string
}