<header>
  <img class="logo" src="assets/img/seat-logo-horizontal.png" alt="{{'altUser'}}">
</header>
<main class="password">
  <h2 class="title">{{'newPasswordTitle' | i18next}}</h2>
  <form [formGroup]="formPassword" (onSubmit)="onSubmit()"  class="form">
    <div  class="form--inputContainer">
      <label  class="form--label" for="nis">{{'emailVerificationText' | i18next}}
        <span class="new-code-text">{{'emailVerificationResendCodeText' | i18next}}<button type="button" class="new-code-btn" (click)="resendCode()">{{'emailVerificationResendCodeBtn' | i18next}}</button></span>
        <span *ngIf="resendCodeText">
        <p class="new-code-feedback">{{resendCodeText}}</p>
      </span>
      </label>
      <input class="form--input" 
             type="text"
             required
             [placeholder]="codeTranslate"
             formControlName="code"
             #autofocus/>
      <span *ngIf="controlInput('code')" class="error-message">
        {{'ErrorCode' | i18next}}
      </span>  
    </div>
    <div class="form--inputContainer">
      <label  class="form--label labelInput" for="password">{{'newPassword' | i18next}}</label>
      <input class="form--input"
             type="password"
             required
             [placeholder]="passwordTranslate"
             formControlName="password"/>
      <p [ngClass]="errorMsg('password')" id="errorMsg" class="error-text text">{{'passwordPattern' | i18next}}</p> 
    </div>
    <div class="form--inputContainer">
      <label  class="form--label labelInput" for="repeatPassword">{{'repeatPassword' | i18next}}</label>
      <input class="form--input"
             type="password"
             required
            [placeholder]="passwordTranslate"
             formControlName="repeatPassword"/>
      <p [ngClass]="errorMsg('repeatPassword')" class="error-text text">{{'passwordPattern' | i18next}}</p> 
      <span class="error-message"> {{errorMessage}} </span>
    </div>
    <div class="btn-container">
      <button type="button" class="btn btn--cancel" [routerLink]="['/login']">{{'btnCancel' | i18next}}</button>
      <button type="submit" class="btn" (click)="onSubmit()" [disabled]="!formPassword.valid">{{'btnAccept' | i18next}}</button>
      <div class="text-center spinner-container" *ngIf="spinner">
        <div class="spinner-border spinner" role="status"></div>
      </div>
    </div>
  </form>
</main>