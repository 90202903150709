import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddDataFine } from 'src/app/shared/model/documentation.model';
import { DocumentKey, DocumentationModel } from 'src/app/shared/model/request-detail.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataEntryService {

  urlPrivate = environment.env === 'dev' ? 'https://data-entry-service' + `${environment.API_CONTEXT}/data-entry-service/v1/private` : `${environment.API_CONTEXT}/data-entry-service/v1/private`;
  urlPublic = environment.env === 'dev' ? 'https://data-entry-service' + `${environment.API_CONTEXT}/data-entry-service/v1/docs/public` : `${environment.API_CONTEXT}/data-entry-service/v1/docs/public`;
  urlCampaigns = environment.env === 'dev' ? 'https://data-entry-service' + `${environment.API_CONTEXT}/data-entry-service/v1` : `${environment.API_CONTEXT}/data-entry-service/v1`;
  
  constructor( private http: HttpClient) { }

  getDocumentation(administrator: string, nis: string, requestNumber: string) {
    const headers=  new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Content-Type': 'application/json; charset=utf-8'
    });
    return this.http.post<DocumentationModel>(
      `${this.urlPrivate}/docs/l`,
      {administrator, nis, requestNumber},
      {
        headers
      }
    );
  }

  downloadDocument(filename: DocumentKey) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob', Accept: 'application/octet-stream', observe: 'response',
    });
     return this.http.post(
      `${this.urlPrivate}/docs/d`,
      filename,
      {headers, responseType: 'blob' as 'json'}
    );
  }

  deleteDocument(filename: DocumentKey) {
    return this.http.delete(
      `${this.urlPrivate}/docs/d`,
      {body: filename},
    );
  }

  sendInfo(addDataFine: AddDataFine) {
    const bodyParams = addDataFine;
    return this.http.post(
      `${this.urlPrivate}/docs/info`,
      bodyParams
    );
  }

  //Backoffice 
  uploadImageToS3(image: any) {
    return this.http.post<any>(`${this.urlCampaigns}/campaigns/image_upload`, image);
  }

  //Public documentation
  getPublicDocumentation(group: string){
    return this.http.get<string[]>(
      `${this.urlPublic}?group=${group}`
    );
  }

  viewPublicDocument(filename : any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob', Accept: 'application/octet-stream', observe: 'response'});
    return this.http.post(
      `${this.urlPublic}`,
      filename,
      {headers, responseType: 'blob' as 'arraybuffer'}
    );
  }
}
