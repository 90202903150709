import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import i18next from 'i18next';
import { CoreService } from 'src/app/core/services/core.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { SessionInfo } from 'src/app/shared/model/register.model';
import { ControlErrors } from 'src/app/shared/utils/generateError';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sms-authentication',
  templateUrl: './sms-authentication.component.html',
  styleUrls: ['./sms-authentication.component.scss']
})
export class SmsAuthenticationComponent implements OnInit {
  
  user: any;
  errorMsg = false;
  submitErr = false;
  submitErrText = '';
  spinner = true;
  prod?: boolean;
  
  constructor( private fb: FormBuilder,
               private storageService: StorageService,
               private coreService: CoreService,
               private router: Router,
               private controlErrors: ControlErrors
               )
 { }

  ngOnInit(): void {
    environment.env === 'prod' ? this.prod = true : this.prod = false; 

    if (this.prod && this.user?.nis !== '000000') {
      this.router.navigateByUrl('/register/mfa-authentication');
    }

    if(this.storageService.get('userRegister')) {
      this.user = this.storageService.get('userRegister');
    } 
    this.initForm();
  }

  form!: FormGroup;

  initForm() : void {
    this.form = this.fb.group({
      telephone: ['', [ Validators.minLength(9), Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
      instructionsCheck: [false, [Validators.required]]
    });
    this.spinner = false;
  }

  updateTelephone() {
    this.form.controls['telephone'].valid ? this.errorMsg = false : this.errorMsg= true;
  }

  submit() {
    this.form.setValue({
      telephone: this.form.value.telephone.replaceAll(' ', '').trim(),
      instructionsCheck: this.form.value.instructionsCheck
    });
    this.form.controls['telephone'].valid ? this.errorMsg = false : this.errorMsg= true;
    this.coreService.signin(this.user?.nis, this.user?.password, this.form.value.telephone).subscribe({
      next: (res) => {
        this.submitErr = false;
        const sessionInfo : SessionInfo = {
          qrCodeResponseDTO: null,
          smsResponseDTO : res.smsResponseDTO
        };
        this.storageService.set('sessionInfo', sessionInfo);
        this.router.navigateByUrl('/register/mfa-authentication');
      },
      error: (e: HttpErrorResponse) => {
        this.submitErr = true;
        this.submitErrText = this.controlErrors.generateError(e.error.message, i18next.t('errorTryLater'));
      }
    });
  }
}
