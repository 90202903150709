import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html'
})

export class UsersComponent {
  url = environment.env === 'dev' ? 'https://data-entry-service' + `${environment.API_CONTEXT}/data-entry-service/v1` : `${environment.API_CONTEXT}/data-entry-service/v1`;
  serverURL = `${this.url}/users`;
  msgCategory = 'users';
  header =
    'Añade el excel con los datos de los nuevos usuarios.<br /> El proceso puede tardar unos minutos.';
}
