import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StockService } from 'src/app/core/services/stock.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { Stock } from 'src/app/shared/model/campaign.model';
import { ModalReturnVehicleComponent } from '../modal-return-vehicle/modal-return-vehicle.component';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  stockSelected! : Stock;
  iconTransmision = faCogs;
  campaignTitle = '';

  constructor(private stockService: StockService,
    private storageService: StorageService,
    private router: Router,
    private modalConfig: NgbModal
    ) { }

  ngOnInit() {
    if (this.stockService.getSelectedStock()) {
      this.stockSelected = this.stockService.getSelectedStock();
    } else {
      this.stockSelected = this.storageService.get('stock');
    }

    if (this.stockSelected && this.stockSelected.titleCampaign) {
      this.campaignTitle = this.stockSelected.titleCampaign;
    }
  }

  backToCampaigns() {
    this.storageService.removeItem('stock');
    this.router.navigate(['/campaigns']);
  }

  openModal() {
    this.modalConfig.open(ModalReturnVehicleComponent, { centered: true, backdropClass: 'modal-order-status' });
  }

}
