import { Component, OnInit } from '@angular/core';
import { CampaignsService } from 'src/app/core/services/campaigns.service';
import { Campaign } from 'src/app/shared/model/campaign.model';
import { environment } from 'src/environments/environment';
//import campaignsData from './db.json';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  isCampaigns = 'isCampaigns';
  campaigns! : Campaign[];
  messageHandlerError = '';
  spinner = true;
  errorResp = false;
  prod!: boolean;

  constructor( private campaignService: CampaignsService) {
  }

  ngOnInit(): void {
    environment.env === 'prod' ? this.prod = true : this.prod = false;
    this.setDataCampaign(); 
  }

  setDataCampaign() {
    this.campaignService.getListCampaigns().subscribe({
      next: (res) => {
        this.spinner = false;
        this.campaigns = res.data;
        this.campaigns = this.campaigns?.map(campaign => {
          const retributionType: string = campaign.retributionType;
          const model: string = campaign.model;
          const urlImgCampaign: string = campaign.urlImage;
          const titleCampaign: string = campaign.title;
          const idCampaign: number = campaign.id;
          const remainingStock: number = campaign.remainingStock;
          return {
            ...campaign,
            trimLevels: campaign.trimLevels.map(trimLevel => {
              const trimLevelName = trimLevel.trimLevelName;
              return {
                ...trimLevel,
                trimLevelInfo: trimLevel.trimLevelInfo.map(trimLevelInfo => {
                  return {
                    ...trimLevelInfo,
                    retributionType: retributionType,
                    model: model,
                    trimLevelName: trimLevelName,
                    urlImage: urlImgCampaign,
                    titleCampaign: titleCampaign,
                    idCampaign: idCampaign,
                    remainingStock: remainingStock
                  };
                })
              };
            })
          };
        }); 
      },
     error: () => {
      this.spinner = false; 
      this.errorResp = true;
     }
      
    });
      
  }
}
