<fieldset class="tracking" *ngIf="oldStatus">
  <legend class="tracking--legend float-none w-auto p-2">{{'stateTitle' | i18next}}</legend>
  <table class="table table-responsive table-striped">
    <thead>
      <th>{{'documentationTableDate' | i18next}}</th>
      <th>{{'altUser' | i18next}}</th>
      <th>{{'state' | i18next}}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let state of oldStatus">
        <tr>
          <td>{{state.date}}</td>
          <td>{{state.user}}</td>
          <td>{{state.descStatus}}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</fieldset>