import { FormGroup, FormControl } from '@angular/forms';

export interface GroupModalEditCampaignProfilesVisibility {
  at: FormControl;
  mk: FormControl;
  tmk: FormControl;
  omk: FormControl;
}

export interface GroupModalEditCampaignInfo {
  id: FormControl;
  title: FormControl;
  type: FormControl;
  model: FormControl;
  description: FormControl;
  additionalInformation: FormControl;
  urlImage: FormControl;
  brand: FormControl;
  enabled: FormControl;
}

export interface GroupModalEditCampaignDates {
  startDate: FormControl;
  endDate: FormControl;
}

export interface GroupModalEditCampaign {
  profilesVisibility: GroupModalEditCampaignProfilesVisibility;
  info: GroupModalEditCampaignInfo;
  dates: GroupModalEditCampaignDates;
}

class GroupModalEditCampaignBuilder implements GroupModalEditCampaign {
  profilesVisibility!: GroupModalEditCampaignProfilesVisibility;
  info!: GroupModalEditCampaignInfo;
  dates!: GroupModalEditCampaignDates;

  setProfilesVisibility(
    profilesVisibility: GroupModalEditCampaignProfilesVisibility
  ) {
    this.profilesVisibility = profilesVisibility;
    return this;
  }

  setInfo(info: GroupModalEditCampaignInfo) {
    this.info = info;
    return this;
  }

  setDates(dates: GroupModalEditCampaignDates) {
    this.dates = dates;
    return this;
  }

  build(): FormGroup {
    return new FormGroup({
      ...this.profilesVisibility,
      ...this.info,
      ...this.dates
    });
  }
}

export default GroupModalEditCampaignBuilder;
