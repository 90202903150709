<app-navbar data="isBackoffice"></app-navbar>
<app-backoffice-header></app-backoffice-header>
<div class="container-custom">
  <app-table-backoffice
    [items]="activeCampaigns"
    [title]="'Campañas activas'"
    [headersTable]="activeHeadersTable"
    (selectedCampaign)="onToggle($event)"
    [options]="{ actionButton: true, actionText: 'Desactivar' }"
    [edit]="true"
    (editCampaign)="editCampaign($event)"
  >
  </app-table-backoffice>
  <app-table-backoffice
    [items]="inactiveCampaigns"
    [title]="'Campañas inactivas'"
    [headersTable]="inactiveHeadersTable"
    (selectedCampaign)="onToggle($event)"
    [options]="{ actionButton: true, actionText: 'Activar' }"
    [edit]="true"
    (editCampaign)="editCampaign($event)"
  >
  </app-table-backoffice>
  <app-table-backoffice
    [items]="finishedCampagins"
    [title]="'Campañas finalizadas'"
    [headersTable]="finishedHeadersTable"
    [options]="{ actionButton: false, actionText: '' }"
  >
  </app-table-backoffice>
</div>
