import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { ConfirmNewPasswordComponent } from '../confirm-new-password/confirm-new-password.component';
import { I18NextModule } from 'angular-i18next';
import { ModalBlockedComponent } from './modal-blocked/modal-blocked.component';
@NgModule({
  imports: 
  [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    I18NextModule
  ],
  declarations: 
  [
    LoginComponent,
    ForgotPasswordModalComponent,
    ConfirmNewPasswordComponent,
    ModalBlockedComponent
  ]
})
export class LoginModule {}
