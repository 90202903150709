import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup  } from '@angular/forms';
import { OrderService } from '../../core/services/order.service';
import i18next from 'i18next';
import {   AdminSearch, FilterSearch } from 'src/app/shared/model/admin.model';
import { OrderResponse } from 'src/app/shared/model/request-list.model';
import { StorageService } from 'src/app/core/storage/storage.service';
import { distinct, from, map } from 'rxjs';
import { saveAs } from 'file-saver';
import { base64StringToBlob } from 'blob-util';
import { PersonalInfo } from '../../shared/model/request-detail.model';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss']
})
export class AdministratorComponent implements OnInit, AfterViewInit {
  
  @ViewChild('autofocus') autofocus?: ElementRef<HTMLInputElement>;

  constructor( private fb: FormBuilder,
               private orderService: OrderService,
               private userService: UserService,
               private storageService: StorageService) { }

  form : FormGroup = this.fb.group({
    nis: [null],
    orderType: ['all'],
    orderDateFrom: [null],
    orderDateTo: [null],
    pssDateFrom: [null],
    pssDateTo: [null],
    frameNumber: [null],
    plateNumbers: [null],
    fullName: [null],
    alert: false
  });
  nisArr?: string[];
  nisValue = '';
  frameValue = '';
  plateValue = '';
  fullName= '';
  isAdministrator = 'administrator';
  //errors
  nisError = false;
  orderDateError = false;
  fineAlert = false;
  retirementDateError = false;
  adminText= i18next.t('adminText');
  errorDateMsg = '';
  errorWrongDateMsg = '';
  //data for table
  pagination = true;
  searchedOrders! : OrderResponse[];
  empty = true;
  spinner = false;
  itemsPerPage = 50;
  totalItems! : number;
  filterObj!: FilterSearch;
  admin = true;
  //emails list
  emails = '';
  emailsString = '';
  //data for detail
  certificateInfo?: PersonalInfo;

  ngOnInit(): void { 
    this.orderService.getRequestWithAlert().subscribe((resp) => resp === true ? this.fineAlert = true : this.fineAlert = false);                                                                                    
  }

  ngAfterViewInit() {
    this.autofocus?.nativeElement.focus();
  }

  resetForm() {
    this.form = this.fb.group({
      nis: [null],
      orderType: ['all'],
      orderDateFrom: [null],
      orderDateTo: [null],
      pssDateFrom: [null],
      pssDateTo: [null],
      frameNumber: [null],
      plateNumbers: [null],
      fullName: [null],
      alert: false
    });
  }
  orderType(value: string) {
    value === 'null' ? this.form.value.orderType = null : this.form.value.orderType = value;
  }

  orderDate(value: string) {
    if ((this.form.value.orderDateFrom !== '' && this.form.value.orderDateTo !== '') && (this.form.value.orderDateTo < this.form.value.orderDateFrom) ) {
      this.orderDateError = true;
      this.errorDateMsg = i18next.t('dateErr');
    } else if (value === '') {
      this.form.value.orderDateTo === '' ? this.form.controls['orderDateTo'].reset() : this.form.controls['orderDateFrom'].reset();
      this.errorDateMsg = '';
      this.orderDateError = false;
    } else if (this.form.value.orderDateTo < this.form.value.orderDateFrom && value === '') {
      this.orderDateError = true;
      this.errorDateMsg = i18next.t('dateInvalid');
    } else {
      this.orderDateError = false;
    }
  }

  retirementDate(value: string) {
    if ((this.form.value.pssDateTo < this.form.value.pssDateFrom) && (this.form.value.pssDateFrom !== '' && this.form.value.pssDateTo !== '')) {
      this.retirementDateError = true;
      this.errorWrongDateMsg = i18next.t('dateErr');
    } else if (value === '') {
      this.form.value.pssDateTo === '' ? this.form.controls['pssDateTo'].reset() : this.form.controls['pssDateFrom'].reset();
      this.errorWrongDateMsg = '';
      this.retirementDateError = false;
    } else if (this.form.value.pssDateTo < this.form.value.pssDateFrom && value === '') {
      this.retirementDateError = true;
      this.errorWrongDateMsg = i18next.t('dateInvalid');
    } else {
      this.retirementDateError = false;
    }
  }

  generateRequestFilterObject(pageNumber: number) {
    if (typeof this.form.value.nis === 'string' && this.form.value.nis.length > 0) {
      this.form.controls['nis'].setValue(
        this.form.value.nis.replaceAll('\t', ' ').replaceAll(', ', ' ').replaceAll(',', ' ').split(' ')
      ); 
    } else if (this.form.value.nis && this.form.value.nis.length >= 0) {
      this.form.value.nis;
    } else {
      this.form.controls['nis'].setValue(null);
    }
    if (typeof this.form.value.frameNumber === 'string' && this.form.value.frameNumber.length > 0) {
      this.form.controls['frameNumber'].setValue(
        this.form.value.frameNumber.replaceAll('\t', ' ').replaceAll(', ', ' ').replaceAll(',', ' ').split(' ')
      ); 
    }else if (this.form.value.frameNumber && this.form.value.frameNumber.length >= 0) {
      this.form.value.frameNumber;
    } else {
      this.form.controls['frameNumber'].setValue(null);
    }
    if (typeof this.form.value.plateNumbers === 'string' && this.form.value.plateNumbers.length > 0) {
      this.form.controls['plateNumbers'].setValue(
        this.form.value.plateNumbers.replaceAll('\t', ' ').replaceAll(', ', ' ').replaceAll(',', ' ').split(' ')
      ); 
    } else if (this.form.value.plateNumbers && this.form.value.plateNumbers.length >= 0) {
      this.form.value.plateNumbers;
    } else {
      this.form.controls['plateNumbers'].setValue(null);
    }
    
    this.form.value.nis === null ? this.nisValue = '' : this.nisValue = this.form.value.nis;
    this.form.value.plateNumbers === null ? this.plateValue = '' : this.plateValue = this.form.value.plateNumbers;
    this.form.value.frameNumber === null ? this.frameValue = '' : this.frameValue = this.form.value.frameNumber;
    this.form.value.fullName === null ? this.fullName = '' : this.fullName = this.form.value.fullName;

    this.filterObj = {
      ...this.form.value,
      pageNumber: pageNumber,
      pageSize: this.itemsPerPage
    };

  }

  searchOrders (pageNumber: number) {
    //cuando haya servicio del detalle eliminar el guardado del nis en storage
    this.storageService.set('requestNis', this.form.value.nis);
    this.adminText = i18next.t('');
    this.spinner = pageNumber === 1;

    this.generateRequestFilterObject(pageNumber);
    this.orderService.getFilteredData(this.filterObj, false).subscribe({
      next: (resp : AdminSearch) => {
        this.searchedOrders = resp.listOrders;     
        this.searchedOrders.forEach((request) => {
          request.requestType === 'endowment' ? request.requestType = i18next.t('typeEndowment') : request.requestType = i18next.t('typeArr');
        });
        this.totalItems = resp.totalCount;
        this.spinner = false;

        if (this.searchedOrders) {
          if (this.searchedOrders.length > 0) {
            return this.empty = false;
          } else {
            this.empty = true;
            return this.adminText = i18next.t('adminNoResults');
          }
        } else {
          return this.empty = true;
        }
      },
      error: () => {
        this.spinner = false;
        this.adminText = i18next.t('errorFilterEndpoint');
      }
    });
  }

  sendEmail() {
    const uniqueNis: string[] = [];
    this.emails= '';
    this.orderService.getFilteredData(this.filterObj, false).subscribe({
      //filtrar para que no se repitan los nis
      next: (resp) => {
        const nisArr = resp.listOrders;
        from(nisArr)
        .pipe(distinct((e: OrderResponse) => e.nis))
        .pipe(map((e: OrderResponse) => {
          return (e.nis);
        }))
        .subscribe((e: string) => {
          uniqueNis.push(e);
        });
        this.userService.getEmails(uniqueNis).subscribe({
          //obtener los emails
          next: (resp: any) => {            
            resp.emails.forEach((string: string) => this.emails += `${string};`);

            window.open(`mailto:${this.emails}`);
          }
        });
      },
    });
  }

  exportData() {
    this.orderService.getFilteredData(this.filterObj, true).subscribe({
      next: (resp) =>{
        if (resp.csvFile) {
          const data: Blob = base64StringToBlob(resp.csvFile, 'text/csv');
          saveAs(data, 'documento');
        }
      },
    });
  }

  cleanForm() {
    this.resetForm();
  }
}
