import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-access-login',
  templateUrl: './access-login.component.html',
  styleUrls: ['./access-login.component.scss']
})
export class AccessLoginComponent {

  activeDirectory() {
    
      window.open(environment.idpUrl,'_self');
    
  }
}
