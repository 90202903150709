import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CoreService } from 'src/app/core/services/core.service';
import { UserService } from 'src/app/core/services/user.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { User } from 'src/app/shared/model/user.model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user!: User;
  token = '';
  nis = '';
  
  constructor(
    private router: Router,
    private storageService: StorageService,
    private userService : UserService,
    private coreService : CoreService
  ) {}

  ngOnInit() {
    if (this.storageService.get('config')) {
      if(this.nis = this.storageService.get('config').nis) {
        firstValueFrom(this.userService.getUser())
          .then((res : User) => {
            this.user = res;
        });
      }
    }
    
  }
  
  onClickLogOut() {
    this.storageService.get('token') ? this.token = this.storageService.get('token').token : this.token = '';
    this.coreService.logout(this.token)
      .subscribe({
        next: () => {
          sessionStorage.removeItem('config');
          this.storageService.removeItem('token');
          this.router.navigateByUrl('/access-login');
        },
      });
  }

  onClickLogo() {
    this.router.navigateByUrl('/landing');
  }
  
  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }
}
