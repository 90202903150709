<fieldset class="quota" *ngIf="quotaData">
  <legend class="quota--legend float-none w-auto p-2">{{'summaryQuotaTitle' | i18next}}</legend>
  <section class="quota--section" *ngIf="quotaData">
    <div class="quota--item">
      <label class="quota--item__label">{{'summaryQuotaType' | i18next}}</label>
      <p class="quota--item__text">{{quotaData.quotaType}}</p> 
    </div>
    <div class="quota--item">
      <label class="quota--item__label">{{'summaryQuotaNew' | i18next}}</label>
      <p class="quota--item__text">{{quotaData.newQuota}}</p>
    </div>
    <div class="quota--item">
      <label class="quota--item__label">{{'summaryQuotaRegularization' | i18next}}</label>
      <p class="quota--item__text">{{quotaData.regularization}}</p>
    </div>
  </section>
</fieldset>