<header>
  <img class="logo" src="assets/img/seat-logo-horizontal.png" alt="{{'altLogo' | i18next}}">
</header>
<main class="confirmation">
  <form [formGroup]="form" class="form">
    <h2 class="title">{{'userCreation' | i18next}}</h2>
    <p>{{'emailVerificationText' | i18next}}</p>
    <label class="form--label" for="code">{{'emailVerificationLabel' | i18next}}
      <input class="form--input"
             type="text"
             formControlName="code"
             [placeholder]="codeTranslate"
             #autofocus/>
    </label>
    <span *ngIf="controlInput('code')" class="error-message">
      <p class="error-text">{{'emailVerificationInputErr' | i18next}}
      </p> 
    </span>
    <span class="error-message"> {{errorMessage}} </span>
    <span class="new-code-text">{{'emailVerificationResendCodeText' | i18next}}<button type="button" class="new-code-btn" (click)="resendCode()">{{'emailVerificationResendCodeBtn' | i18next}}</button></span>
    <span *ngIf="resendCodeText">
      <p class="new-code-feedback">{{resendCodeText}}</p>
    </span>
    <div class="btn-container">
      <button type="button" class="btn btn--cancel" [routerLink]="['/register']">{{'btnCancel' | i18next}}</button>
      <button type="submit" class="btn" (click)="codeVerification()" [disabled]="!form.valid">{{'btnAccept' | i18next}}</button>
      <div class="text-center spinner-container" *ngIf="spinner">
        <div class="spinner-border spinner" role="status"></div>
      </div>
    </div>
  </form>