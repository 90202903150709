const getCommonPondOptions = (
  serverURL: string,
  onLoad: (parsedJSON: any) => void,
  token: string,
) => ({
  labelIdle: '<b>Arrastra</b> o haz <b>clic</b> para añadir los archivos',
  labelFileProcessing: 'Procesando',
  labelFileProcessingComplete: 'Archivo procesado correctamente',
  labelFileProcessingAborted: 'Subida del archivo cancelada',
  labelFileProcessingError: 'Error durante la carga del archivo',
  labelTapToCancel: '',
  labelFileTypeNotAllowed: 'Formato inválido',
  labelTapToRetry: 'Clic para reintentar',
  fileValidateTypeLabelExpectedTypes: 'El formato esperado es {lastType}',
  labelMaxFileSizeExceeded: 'El archivo excede el tamaño esperado',
  labelMaxFileSize: 'El tamaño máximo es {filesize}',
  maxFileSize: '10MB',
  allowRevert: 'false',
  maxFiles: 1,
  acceptedExcelFileTypes:
    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx',
  acceptedCsvFileTypes:
    'text/csv , .csv',
  server: {
    process: {
      url: serverURL,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        user_key: 'everis'
      },
      onload: (response: any) => {
        const parsedResponse = JSON.parse(response);
        onLoad(parsedResponse);
      },
      onerror: (res: any) => {
        return JSON.parse(res);
      }
    }
  }
});

export default getCommonPondOptions;
