import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import i18next from 'i18next';

import { OrderResponse } from '../../shared/model/request-list.model';
import RequestType from 'src/app/shared/model/requestType.enum';

import { OrderService } from '../../core/services/order.service';
import { StorageService } from '../../core/storage/storage.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-list-request',
  templateUrl: './list-request.component.html',
  styleUrls: ['./list-request.component.scss']
})
export class ListRequestComponent implements OnInit {

  spinner = false; 
  i18next = i18next;
  nis = '';
  requests? : OrderResponse[];
  form : FormGroup = this.fb.group({});
  empty= false;
  pagination = false;
  requestList = true;
  modalContent?: NgbModalRef;
  isListRequest = 'listRequest';
  errorList = false;

  ngOnInit(): void {
    this.initForm();
    this.storageService.get('config') ? this.nis = this.storageService.get('config').nis : this.nis = '';
    this.getOrdersList(this.form.value.listRequests);
  }
  
  constructor( private orderService : OrderService,
               private storageService: StorageService,
               private fb : FormBuilder,
  ) {}
  
  initForm() {
    this.form = this.fb.group({
      listRequests: ['ACTIVE']
    });
  }
  showRequests(value: string) {
    this.getOrdersList(value);
    
  }
  async getOrdersList(requestGroup: string) {
    this.spinner = true;
    this.requests = [];
    this.orderService.showOrdersList(requestGroup).subscribe({
      next: (res) => {
        this.errorList = false;
        this.requests = res;
        this.spinner = false;
        if (this.requests) {
          this.requests.length <= 0 ? this.empty = true : this.empty = false;
          for (const request of this.requests) {
            request.requestType = RequestType[Number(request.requestType)];
          } 
        } else {
          this.spinner = false;
          this.empty = true;
        }
      },
      error: () => {
        this.errorList = true;
      }
    });
 }

}