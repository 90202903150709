import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../../../core/storage/storage.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionInfo, UserRegister } from 'src/app/shared/model/register.model';
import { CoreService } from 'src/app/core/services/core.service';
import i18next from 'i18next';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
//import { RegisterModalComponent } from '../register-modal/register-modal.component';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';
import { ControlErrors } from 'src/app/shared/utils/generateError';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit, AfterViewInit {

  @ViewChild('autofocus') autofocus!: ElementRef<HTMLInputElement>;

  user! : UserRegister;

  spinner = false;
  code = '';
  errorMessage = '';
  resendCodeText = '';
  codeTranslate = i18next.t('code');
  form: FormGroup = this.fb.group({
    code: ['', Validators.required]
  });
  modalContent?: NgbModalRef;

  constructor(private storageService : StorageService,
              private fb : FormBuilder,
              private coreService : CoreService,
              private router : Router,
              private modalConfig: NgbModal,
              private controlErrors: ControlErrors
              ) { }

  ngOnInit(): void {
    this.user = this.storageService.get('userRegister');  
    if(!this.user || this.user.nis === '' || this.user.password === ''){
      this.router.navigateByUrl('login');
    } 
  }

  ngAfterViewInit() {
    this.autofocus.nativeElement.focus();
  }
  
  controlInput(input : string): boolean {
    return this.form?.controls[input]?.invalid && this.form?.controls[input]?.touched;
  }
  codeVerification() {
    this.spinner = true;
    
    const emailCode =  this.form.value.code.trim();
    this.coreService.confirmSignup(this.user.nis, this.user.password, emailCode)
      .subscribe({
        next: () => {
          this.spinner = false;
          this.coreService.signin(this.user.nis, this.user.password)
            .subscribe({
              next: (res) => {
                if(res.qrCodeResponseDTO){
                  const sessionInfo : SessionInfo ={
                    qrCodeResponseDTO: {
                      emailCode: emailCode,
                      qrCode: res.qrCodeResponseDTO.qrCode,
                      session: res.qrCodeResponseDTO.session,
                      secretCode: res.qrCodeResponseDTO.secretCode
                    },
                    smsResponseDTO: null
                  };
                  this.storageService.set('sessionInfo', sessionInfo);
                  this.router.navigateByUrl('/register/mfa-authentication');
                } else {
                  this.router.navigateByUrl('login');
                } 
              },
              error: (err: HttpErrorResponse) => {
                if (err.status === 406 && err.error?.message === '5005') {
                  this.errorMessage = this.controlErrors.generateError(err.error?.message, i18next.t('categoryError'));
                } else if (err.status === 406 && err.error?.message === '5003') {
                  this.errorMessage = this.controlErrors.generateError(err.error?.message, i18next.t('errorNoPhones'));
                } else if (err.status === 412) {
                  this.errorMessage = this.controlErrors.generateError(err.error?.message, i18next.t('loginNoPss'));
                }else {
                  this.errorMessage = this.controlErrors.generateError(err.error?.message, i18next.t('genericErr'));
                }
                 
              }
            });
        },
        error: (e : HttpErrorResponse) => {
          this.spinner = false;
          if(e.error.message === '5004'){
            this.modalContent = this.modalConfig.open(FeedbackModalComponent, { windowClass: 'register-modal', centered: true, backdrop : 'static'});
            this.modalContent.componentInstance.text = this.controlErrors.generateError(e.error?.message, i18next.t('ErrorCode'));
            this.modalContent.componentInstance.redirect = false;
          } else {
            this.errorMessage = this.controlErrors.generateError(e.error?.message, i18next.t('genericErr'));
          }   
        }
      });
  }

  resendCode() {
    this.coreService.resendConfirmationCode(this.user.nis).subscribe({
      next: () => {
        this.resendCodeText = i18next.t('emailVerificationResendFeedbackOk');
      },
      error: (e: HttpErrorResponse) => {
        this.resendCodeText = this.controlErrors.generateError(e.error?.message, i18next.t('emailVerificationResendFeedbackKo'));
      }
    });
  }
}
