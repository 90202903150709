<div class="content-close animated fadeIn fast">
  <img class="icon" src="./assets/icons/close-icon.png" alt= "..." (click)="activeModal.dismiss('Cross click')">
</div>
<div *ngIf="data" class="content animated fadeIn fast">
  <div class="title">
    {{ data.trimLevelName    }}
    <div class="text-bottom">
      {{ 'stockModelYear' | i18next }} {{ data.modelYear }}
    </div>
  </div>
  <app-equipment-list [equipments]="data.equipments"></app-equipment-list>
</div>