import { Component, Input, OnInit } from '@angular/core';
import { faInfo, faTimes, faCogs } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { NgbModal, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { omit } from 'lodash';
import { StockService } from 'src/app/core/services/stock.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { Colour, Stock } from 'src/app/shared/model/campaign.model';
//import { CampaignsService } from 'src/app/core/services/campaigns.service';
import { ModalEquipmentComponent } from './modal-equipment/modal-equipment.component';
import { ModalColoursComponent } from './modal-colours/modal-colours.component';

@Component({
  selector: 'app-equipment-finishes',
  templateUrl: './equipment-finishes.component.html',
  styleUrls: ['./equipment-finishes.component.scss']
})
export class EquipmentFinishesComponent implements OnInit {

  @Input() trimLevels!: Stock[];
  @Input() trimLevelName! : string;

  iconInfo = faInfo;
  closeIcon = faTimes;
  iconTransmision = faCogs;

  constructor(private tooltipConfig: NgbTooltipConfig,
              private modalConfig: NgbModal,
              private router: Router,
              private stockService: StockService,
              //private campaignService: CampaignsService,
              private storageService: StorageService
              ) {
    this.tooltipConfig.placement = 'bottom';
  }

  ngOnInit() {
    this.trimLevels = this.trimLevels?.map(stock => {
      let equipmentsVisible = [];

      if (stock.equipments.length > 6) {
        equipmentsVisible = stock.equipments.slice(0, 6);
      } else {
        equipmentsVisible = stock.equipments;
      }

      return {
        ...stock,
        equipmentsVisible: equipmentsVisible
      };
    });
  }
  openEquipment(stock : Stock) {
    const modalRef = this.modalConfig.open(ModalEquipmentComponent, { centered: true });
    const stockData = {
      trimLevelName: this.trimLevelName,
      modelYear: stock.modelYear,
      equipments: stock.equipments
    };

    modalRef.componentInstance.data = stockData;
  }

  checkCarLease(colours: Colour[], stock: any) {

    this.openColours(colours, stock); 
    /*
    TODO:
    this.campaignService.checkCarLease().subscribe(resp => {
      if(resp) {
        this.modalConfig.open(ModalCarLeasingComponent, { centered: true });
      } else {
        this.openColours(colours, stock);
      }
    }); */
  }

  openColours(colours: Colour[], stock: Stock) {
    const stockWithoutColours : any = omit(stock, ['colours']);

    if (colours.length > 1) {
      const modalRef = this.modalConfig.open(ModalColoursComponent, { centered: true });

      modalRef.componentInstance.colours = colours;
      modalRef.componentInstance.stockSelected = stockWithoutColours;
    } else {
      const stockSelected = {
        ...stockWithoutColours,
        colour: colours[0]
      };
      this.stockService.selectedStock(stockSelected);
      this.storageService.set('stock', stockSelected);

      this.router.navigate(['/campaigns-summary']);
    }
  }

}


