<div class="content">
  <!-- Body -->
  {{ msg }}
  <div class="content-icon">
    <fa-icon class="icon" [icon]="icon"></fa-icon>
  </div>
</div>
<div class="footer">
  <!-- footer -->
  <div class="btn" (click)="close()">
    {{'btnClose' | i18next}}
  </div>
</div>
