import { Component } from '@angular/core';
import i18next from 'i18next';
import { StorageService } from 'src/app/core/storage/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html'
})
export class CampaignComponent {
  constructor (private storageService : StorageService) {}

  token = this.storageService.get('token');
  url = environment.env === 'dev' ? 'https://data-entry-service' + `${environment.API_CONTEXT}/data-entry-service/v1` : `${environment.API_CONTEXT}/data-entry-service/v1`;
  serverURL = `${this.url}/campaigns`;
  msgCategory = 'campaign';
  header = i18next.t('backofficeAddCampaignExcel');
  errorMessage?: ErrorCustom[];

  handleError(error: ErrorCustom[]) {
    this.errorMessage = error;
  }
}

interface ErrorCustom {
  name: string,
  value: string;
}

