import { Component, Input } from '@angular/core';
import { HistoricalStatus } from 'src/app/shared/model/request-list.model';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent  {

  @Input() oldStatus! : HistoricalStatus[];
  


}
