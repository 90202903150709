<div class="header-title"
     placement="bottom"
     [ngbTooltip]="fieldTooltip"
     #t="ngbTooltip"
     [autoClose]="'outside'"
     triggers="click"
     tooltipClass="custom-tooltip">{{ title }}
  <span>
    <!-- Desc sort icon -->
    <fa-icon
      *ngIf="sortType === 'des'"
      class="icon"
      [icon]="iconSortDesc"
    ></fa-icon>

    <!-- Asc sort icon -->
    <fa-icon
      *ngIf="sortType === 'asc'"
      class="icon"
      [icon]="iconSortAsc"
    ></fa-icon>
  </span>
</div>

<div class="content-tooltip">
  <ng-template #fieldTooltip>
    <div *ngIf="sorted === true">
      <!-- Sortable -->
      <div class="sorted-title">{{'sort' | i18next}}</div>
      <div class="sorted-element" (click)="sort('asc', key); t.close()">
        {{'sortAscendant' | i18next}}
      </div>
      <div class="sorted-element" (click)="sort('des', key); t.close()">
        {{'sortDescend' | i18next}}
      </div>
    </div>
    <div *ngIf="sorted && filterable" class="separator"></div>
    <div *ngIf="filterable === true" class="filterable-container">
      <!-- Filterable -->
      <input type="text"
             placeholder="Buscar por {{ title }}"
             [ngModel]="inputSearch"
             (ngModelChange)="inputSearch = $event" />
      <div class="btn-search" (click)="filter(key); t.close()" alt="Search">
        <fa-icon class="icon-filter" [icon]="iconFilter"></fa-icon>
      </div>
    </div>
  </ng-template>
</div>
