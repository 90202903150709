<div class="content-close animated fadeIn fast">
  <img class="icon" src="./assets/icons/close-icon.png" alt="..." ngbAutofocus (click)="activeModal.dismiss('Cross click')">
</div>
<div class="content animated fadeIn fast">
  <div class="title">
    <span>{{count}} {{ 'availableColours_plural' | i18next }}</span>
    {{ 'selectYourColor' | i18next }}
  </div>
  <form [formGroup]="form">
    <div class="content-colours pt-5" ngbRadioGroup ngDefaultControl name="colourRadio" [(ngModel)]="colourSelected">
      <div *ngFor="let colour of colours; let i = index">
        <label class="colour-label">
        <input type="radio" 
               id="colour"
               name="colour"
               #colour
               [checked]="i === 0"
               (change)="setColour(colour)">
        {{colour.description}}</label>
      </div>
      
    </div>
  </form>
  <div class="content-button text-right pt-5">
    <button class="btn confirm bgColor text-center" (click)="next()">
      {{ 'btnContinue' | i18next }}
    </button>
  </div>
</div>
