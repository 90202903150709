import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/storage/storage.service';
import { User } from 'src/app/shared/model/user.model';


@Component({
  selector: 'app-info-petitioner',
  templateUrl: './info-petitioner.component.html',
  styleUrls: ['./info-petitioner.component.scss']
})
export class InfoPetitionerComponent implements OnInit{

  @Input() user!: User;
  hasTelephones = true;

  constructor(private storageService: StorageService) {}
  
  ngOnInit(): void {
    if (this.user?.telephone && this.user?.mobile) {
      this.hasTelephones = true;
    } else {
      this.hasTelephones = false;
    }
    this.setGroupTypeFromStorage();
  }
  private setGroupTypeFromStorage(): void {
    const config: User = this.storageService.get('config');
    if(config ) {
      this.user.groupType = config.groupType;
      this.user.pssFinalDate = config.pssFinalDate;
    }
  }
}
