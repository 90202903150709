<div class="accessLogin">
  <div class="container-logo">
    <img class="container-logo--img" src="../../../assets/img/seat-logo-horizontal.png" alt="{{'altUser' | i18next}}" />
  </div>
  <div class="accessLogin--img"></div>
  <main class="accessLogin--main">
    <section class="accessLogin--section">
      <p class="text">{{'user' |i18next}} <strong>{{'earlyRet' | i18next}}</strong> {{'accessText' | i18next}} </p>
      <button class="btn confirm bgColor accessBtn" type="button" [routerLink]="['/login']">{{'entry' | i18next}}</button>
    </section>
    <section class="accessLogin--section">
      <p class="text">{{'userEmployee' | i18next}} <strong>{{'employee' | i18next}}</strong>  {{'accessText' | i18next}}</p>
      <button class="btn confirm bgColor accessBtn" (click)="activeDirectory()"  type="button">{{'entry' | i18next}}</button>
    </section>
  </main>
</div>
