import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { StorageService } from '../storage/storage.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})

export class IsAdminGuard implements CanActivate {

  constructor( private storageService: StorageService,
               private authorization: AuthenticationService,
               private router: Router
  ) {}
  
  canActivate(): boolean {
    const user = this.storageService.get('config');
    if (user.admin === false || !this.authorization.isAuthorized() ) {
      this.router.navigateByUrl('/landing');
      return false;
    } else {
      return true;
    }
  }
            
}