import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Campaign } from '../../../shared/model/backoffice.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-table-backoffice',
  templateUrl: './table-backoffice.component.html',
  styleUrls: ['./table-backoffice.component.scss']
})
export class TableBackofficeComponent {
  @Input() headersTable!: string[];
  @Input() title!: string;
  @Input() items!: Campaign[];
  @Input() options!: any;
  @Input() edit?: boolean;

  @Output() selectedCampaign: EventEmitter<any> = new EventEmitter();
  @Output() editCampaign: EventEmitter<any> = new EventEmitter();

  actualDate = formatDate(new Date(), 'dd/MM/yy', 'en');

  onToggle(event: boolean, campaign: Campaign) {
    const data = {
      status: event,
      campaign: campaign
    };

    this.selectedCampaign.emit(data);
  }

  onEdit(campaign: Campaign) {    
    this.editCampaign.emit(campaign);
  }
}
