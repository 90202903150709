<div class="header-container">
  <div class="title">
    {{ title }}
  </div>
  <button class="filters-btn"
          (click)="deleteFilters()"
          [ngClass]="{ active: handledOrders === true }"
          [disabled]="!handledOrders"> {{'deleteFilters' | i18next}}
  </button>
</div>
<div class="grid">
  <div class="row content-header">
    <div *ngFor="let th of headersTable">
      <app-basic-header *ngIf="!th.sortable && !th.filterable" [title]="th.name"></app-basic-header>
      <app-advanced-header *ngIf="th.sortable || th.filterable"
          [title]="th.name"
          [key]="th.key"
          [sorted]="th.sortable"
          [filterable]="th.filterable"
          [activeFilterKey]="activeFilterKey"
          (sortedEvent)="sort($event)"
          (filterableEvent)="filter($event)"></app-advanced-header>
    </div>
    <div class="empty-col">&nbsp;</div>
  </div>

  <div *ngFor="let order of items" class="row">
    <div>{{ order.campaignId }}</div>
    <div>{{ order.modelYear }}</div>
    <div>{{ order.tradeName }}</div>
    <div>{{ order.tmaimg }}</div>
    <div>{{ order.nis }}</div>
    <div>{{ order.requestNumber }}</div>
    <div>{{ order.baseFee }}</div>
    <div>{{ order.commissionCode }}</div>
    <div>{{ order.orderCode }}</div>
    <div>{{ order.updateDate }}</div>
    <div class="action-column">
      <div *ngIf="!order.nis" class="icon-container">
        <fa-icon
          class="icon"
          [icon]="iconDelete"
          (click)="delete(order)"
          title="{{'altCancel' | i18next}}"
        ></fa-icon>
      </div>
      <div *ngIf="order.nis" class="icon-container">
        <fa-icon
          class="icon"
          size="sm"
          [icon]="iconDeleteRequestItem"
          (click)="delete(order)"
          title="{{'altDelete' | i18next}}"
        ></fa-icon>
      </div>
    </div>
  </div>
</div>
