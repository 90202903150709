<app-navbar data="isNewRequest"></app-navbar>
<main class="content" [hidden]="spinner">
  <div>
    <app-info-petitioner [user]="user"></app-info-petitioner>
    <app-info-request [user]="user"
                      (vehicleTypeEmitter)="typeEvent($event)"
                      (vehicleReplacementEmitter)="replaceEvent($event)"
                      (requestFormEmitter)="requestFormUpdate($event)"
                      (vehicleFirstAssignEmitter)="isFirstAssingRequest($event)"
                      [vehiclesArr]="vehiclesArr"
                      [retribution]="retribution"
                      [typeTranslation] ='typeTranslation'>
    </app-info-request>
    <app-car-configurator class="car-configurator"
                          (vehicleInfoEmitter)="vehicleInfo($event)"
                          [vehicleInfoData]="vehicleInfodata"
                          [disableVehicle]="disableVehicle"
                          [errorPcc]="errorPcc"
                          [errorPccText]="errorPccText"
                          [spinner]="spinner"
                          [removeData]="removeData">
    </app-car-configurator>
    <div class="btn-container">
      <small class="error" *ngIf="errorKms">{{'requestEstimatedKmsErr' | i18next}}</small>
      <button (click)="openConfirmationModal()" [disabled]="!vehicleInfodata || errorPcc" class="btn">{{'btnSendReq'| i18next }}</button>
    </div>
  </div>
</main>
  <div class="text-center spinner-container" [hidden]="!spinner">
    <div class="spinner-border spinner" role="status"></div>
  </div>
