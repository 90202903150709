export const getDownloadableLink = (
  url: string,
  fileName: string
): HTMLAnchorElement => {
  const link = document.createElement('a');
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
  }

  return link;
};
