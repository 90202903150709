import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../core/services/navigation.service';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { StorageService } from 'src/app/core/storage/storage.service';
import { NgbModal, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { YearModalComponent } from './year-modal/year-modal.component';

@Component({
  selector: 'app-backoffice-header',
  templateUrl: './backoffice-header.component.html',
  styleUrls: ['./backoffice-header.component.scss'],
  standalone: true,
  imports: [
    I18NextModule,
    CommonModule,
  ],
  providers: [NgbTooltipConfig]
})
export class BackofficeHeaderComponent implements OnInit {
  uploadCampaign = false;
  uploadAuthorizers = false;
  page?: string;

  recordsFileUrls: any;
  recordsFileUrlsLoadingMsg = 'Descargar solicitudes';
  username = '';

  iconLogout = faSignOutAlt;

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private storageService: StorageService,
    private tooltipConfig: NgbTooltipConfig,
    private modalConfig: NgbModal
  ) {
    this.tooltipConfig.placement = 'bottom';
  }

  ngOnInit() {
    this.username = this.storageService.get('username');

    this.navigation.data.subscribe((data: any) => {
      this.uploadAuthorizers = data.uploadAuthorizers;
      this.uploadCampaign = data.uploadCampaign;
      this.page = data.page;
    });
  }

  generateOrdersExport() {
    this.modalConfig.open(YearModalComponent,{
      centered: true
    });
  }

  

  navigateTo(url: string) {
    if (url === 'stock') {
      this.router.navigateByUrl('backoffice/backoffice-orders');
    } else if (url === 'campaigns') {
      this.router.navigateByUrl('/backoffice');
    } else if (url === 'addCampaign') {
      this.router.navigateByUrl('/upload/campaigns');
    }  else if (url === 'authorizers') {
      this.router.navigateByUrl('/upload/authorizers');
    }
  }
}
