import { FormControl, Validators } from '@angular/forms';
import { Campaign } from '../../../../shared/model/backoffice.model';
import {
  GroupModalEditCampaignProfilesVisibility,
  GroupModalEditCampaignDates,
  GroupModalEditCampaignInfo
} from './form-group-builder';

export const getFromGroupModalEditCampaignProfilesVisibility = (profiles: {
  [key: string]: boolean;
}): GroupModalEditCampaignProfilesVisibility => {
  return {
    at: new FormControl(profiles['at']),
    mk: new FormControl(profiles['mk']),
    tmk: new FormControl(profiles['tmk']),
    omk: new FormControl(profiles['omk'])
  };
};

export const getFromGroupModalEditCampaignInfo = (
  campaign: Campaign
): GroupModalEditCampaignInfo => {
  return {
    id: new FormControl(campaign?.id),
    title: new FormControl(campaign?.title, [Validators.required]),
    type: new FormControl(campaign?.type, [Validators.required]),
    model: new FormControl(campaign?.model),
    description: new FormControl(campaign?.description, [Validators.required]),
    urlImage: new FormControl(campaign?.urlImage),
    brand: new FormControl(''),
    enabled: new FormControl(campaign?.isEnabled),
    additionalInformation: new FormControl(campaign?.additionalInformation, [
      Validators.required
    ])
  };
};

export const getFromGroupModalEditCampaignDates = (
  startDate: string,
  endDate: string
): GroupModalEditCampaignDates => {
  return {
    startDate: new FormControl(startDate, [Validators.required]),
    endDate: new FormControl(endDate, [Validators.required])
  };
};
