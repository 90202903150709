<div class="center-container animated fadeIn">
  <div class="content-icon">
    <fa-icon [icon]="status === '201 CREATED' ? iconSuccess : iconError" class="icon"></fa-icon>
  </div>
  <div class="content-text">
    <div class="content-message">
      {{message}}
    </div>
  </div>
  <div class="user-container">
    <ul>
      <li>NIS: {{infoUser?.nis}}</li>
      <li>Email: {{infoUser?.email}}</li>
    </ul>
  </div>
  <div class="content-button">
    <div class="btn confirm bgColor" (click)="deleteOrder()">Aceptar</div>
  </div>
</div>
