import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import i18next from 'i18next';
import { CoreService } from 'src/app/core/services/core.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { ControlErrors } from '../../../shared/utils/generateError';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
  @ViewChild('inp', {}) inputElement!: ElementRef;
  
  nis = '';
  forgotPasswordForm: FormGroup = this.fb.group({
    nis: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
  });
  errorMessage = '';
  spinner = false;
  
  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              private coreService: CoreService,
              private router: Router,
              private storageService: StorageService,
              private controlErrors: ControlErrors
            ) { }
  
  ngOnInit(){
    this.inputElement.nativeElement.focus();
  }
  
  closeModal(){
    this.activeModal.close();
  }

  errorMsg(input: string) : string {
    if (this.forgotPasswordForm?.controls[input]?.invalid && this.forgotPasswordForm?.controls[input]?.touched) {
      return 'error-message';
    } else {
      return 'info-text';
    }  
  }

  onSubmit(){
    this.errorMessage = '';
    this.spinner = true;
    this.nis = this.forgotPasswordForm.value.nis || '';
    this.storageService.set('config', this.forgotPasswordForm.value.nis);
    this.coreService.forgotPassword(this.nis)
      .subscribe({
      next: () => {
        this.spinner = false;
        this.activeModal.close();
        this.router.navigateByUrl('confirm-new-password');
      },
      error: (err) => {
        if (err.status === 412) {
          this.spinner = false;
          this.errorMessage = this.controlErrors.generateError(err.error?.message, i18next.t('loginNoPss'));
        } else {
          this.spinner = false;
          this.errorMessage = this.controlErrors.generateError(err.error?.message, i18next.t('genericErr'));
        }  
      }
    });
  }
}
