<main>
  <div class="container-custom content-title">
    {{ campaignTitle }}  
  </div>
  <div class="content-stock animated fadeIn">
    <div class="box-stock" *ngIf="stockSelected">
      <div class="container-custom content-stock-info pb-3" [class.half-width]="stockSelected.urlImage">
        <div class="content-name pb-2">
          {{ stockSelected.model | uppercase }} {{ stockSelected.trimLevelName }}
        </div>
        <div class="content-year pb-3">
          {{ 'stockModelYear' | i18next }} {{ stockSelected.modelYear }}
        </div>
        <div class="content-base-fee">
          <div class="title">{{ 'baseFee' | i18next }}:</div>
          <div class="content-price">
            <div class="price">{{ stockSelected.baseFee | number: '1.2' }}€</div>
            <div class="text-month">
              {{ 'perMonth' | i18next }}
            </div>
            <img src="./assets/icons/info-circle.png" alt="..." [ngbTooltip]="tooltipContent" [autoClose]="'outside'"
              triggers="click" tooltipClass="custom-tooltip" placement="bottom">
            <ng-template #tooltipContent>
              <div class="text py-1"><span>{{ 'increment1' | i18next }}:</span> {{ stockSelected.increaseB | number: '1.2' }}€</div>
              <div class="text py-1"><span>{{ 'increment2' | i18next }}:</span> {{ stockSelected.increaseC | number: '1.2' }}€</div>
              <div class="text py-1"><span>{{ 'primaryRE' | i18next }}:</span>
                {{ stockSelected.primaryRE | number: '1.2' }}€
              </div>
              <div class="text py-1"><span>{{ 'secondaryRE' | i18next }}:</span>
                {{ stockSelected.secondaryRE | number: '1.2' }}€
              </div>
              <div class="text py-1"><span>{{ 'primaryEndowmentRE' | i18next }}:</span>
                {{ stockSelected.primaryEndowmentRE | number: '1.2' }}€
              </div>
            </ng-template>
          </div>
        </div>
        <div class="content-description">
          <div class="content-colour">
            {{ stockSelected.colour?.description }}
          </div>
          <div class="content-motor-info">
            {{ stockSelected.model }} {{ stockSelected.motorInfo }}
          </div>
        </div>
        <div class="content-icons">
          <div class="content-engine">
            <fa-icon class="icon" [icon]="iconTransmision"></fa-icon>
            <div class="info">{{ stockSelected.transmission }}</div>
          </div>
          <div class="content-proof">
            <img src="./assets/icons/proof-svg@3x.png" alt="..." >
            <div class="info">{{ stockSelected.co2 }} g/km</div>
          </div>
        </div>
      </div>
      <div *ngIf="stockSelected.urlImage" class="container-custom content-image ">
        <img class="content-image--img" src={{stockSelected.urlImage}} alt="">
      </div>
    </div>
  </div>
  
  <div *ngIf="stockSelected" class="container-custom content-equipment-vehicle py-4 animated fadeIn">
    <app-equipment-list [equipments]="stockSelected.equipments" [titleUpperCase]="true"></app-equipment-list>
  </div>
  
  <div class="botonera container-custom pt-3">
    <button type="button" class="btn back backBtn" (click)="backToCampaigns()">{{ 'btnBack' | i18next }}</button>
    <button type="button" class="btn confirm bgColor ml-5"
      (click)="openModal()">{{ 'btnApply' | i18next }}</button>
  </div>
</main>
