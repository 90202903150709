import { Component, OnInit } from '@angular/core';
import { Filter, ITableHeaders, Sort, Stock, tableHeaders } from '../../../shared/model/backoffice.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ModalFeedbackComponent } from '../modal-feedback/modal-feedback.component';
import { UserService } from 'src/app/core/services/user.service';
import { CampaignsService } from '../../../core/services/campaigns.service';
import i18next from 'i18next';
import { User } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  orders!: Stock[];

  handleSortOrders = {
    enabled: false,
    type: 'asc',
    key: ''
  };

  handleFilterOrders = {
    enabled: false,
    key: '',
    value: ''
  };

  // Table
  totalOrdersCount = 0;
  ordersByPage = 50;
  actualTablePage = 1;
  headersTable: ITableHeaders[] = tableHeaders;

  constructor(
    private userService: UserService,
    private campaignsService: CampaignsService,
    private modalConfig: NgbModal
  ) {}

  ngOnInit() {
    this.getOrdersByPage(this.actualTablePage - 1, this.ordersByPage);
  }

  changePage(page: number) {
    this.actualTablePage = page;

    if (this.handleSortOrders.enabled) {
      this.getSortedOrders(
        this.handleSortOrders.key,
        this.handleSortOrders.type
      );
    } else if (this.handleFilterOrders.enabled) {
      this.getFilteredOrders(
        this.handleFilterOrders.key,
        this.handleFilterOrders.value
      );
    } else {
      this.getOrdersByPage(page - 1, this.ordersByPage);
    }
  }

  sortTable(sort: Sort) {
    this.resetHandleFilter();
    this.handleSortOrders = {
      enabled: true,
      key: sort.key,
      type: sort.type
    };
    this.actualTablePage = 1;

    this.getSortedOrders(sort.key, sort.type);
  }

  filterTable(filter: Filter) {
    this.resetHandleSort();
    this.handleFilterOrders = {
      enabled: true,
      key: filter.key,
      value: filter.value
    };
    this.actualTablePage = 1;

    this.getFilteredOrders(filter.key, filter.value);
  }

  deleteOrder(stock: Stock) {
    const modalRef = this.modalConfig.open(ModalConfirmComponent, {
      centered: true
    });
    const isCancellation = stock.nis;
    modalRef.componentInstance.data = stock;
    modalRef.componentInstance.message = isCancellation
      ? i18next.t('backofficeAreYouSureToCancelTheOrder')
      : i18next.t('backofficeAreYouSureToDeleteTheOrder');

    modalRef.result.then(resp => {
      if (resp) {
        if (isCancellation) {
          this.userService.getSelectedUser(stock.nis).subscribe({
            next: (resp: User) => {
              this.campaignsService.cancelVehicleOrder(stock.campaignId, stock.id, resp.email, resp.fullName).subscribe({
                next: () => {
                  this.openFeedbackModal('success', i18next.t('backofficeCancelationWasSuccessfullyDone'));
                }
              });
          }, error: () => {
              this.openFeedbackModal(
                'error',
                i18next.t('backofficeAnErrorHasOcurred')
              );
            }
          });
        } else {
          this.campaignsService.deleteVehicleOrder(stock).subscribe({
            next: () => {
              this.openFeedbackModal('success', i18next.t('backofficeDeletingWasSuccessfullyDone'));
            }, error: () => {
                this.openFeedbackModal(
                  'error',
                  i18next.t('backofficeAnErrorHasOcurred')
                );
              }
            });
        }
      }
    });
  }

  openFeedbackModal(type: string, msg: string) {
    const modalRef = this.modalConfig.open(ModalFeedbackComponent, {
      centered: true
    });

    modalRef.componentInstance.type = type;
    modalRef.componentInstance.msg = msg;

    modalRef.result.then(() => {
      this.changePage(this.actualTablePage);
    });
  }

  resetOrders() {
    this.resetHandleSort();
    this.resetHandleFilter();
    this.getOrdersByPage(0, 50);
  }

  getOrdersByPage(pageNumber: number, pageSize: number) {
    this.campaignsService.getVehiclesStock(pageNumber, pageSize).subscribe({
      next: (orders: any) => {
        this.orders = orders.data.content;
        this.totalOrdersCount = orders.data.totalElements;
      }
    });
  }

  getSortedOrders(key: string, type: string) {
    this.campaignsService
      .getSortedVehiclesStock(this.actualTablePage - 1, this.ordersByPage, key, type)
      .subscribe({
        next: (orders: any) => {
          this.orders = orders.data.content;
          this.totalOrdersCount = orders.data.totalElements;
        }
      });
  }

  getFilteredOrders(key: string, value: string) {
    this.campaignsService
      .getFilteredVehiclesStock(
        this.actualTablePage - 1,
        this.ordersByPage,
        key,
        value
      )
      .subscribe({
        next: (orders: any) => {
          this.orders = orders.data.content;
          this.totalOrdersCount = orders.data.totalElements;
        }
      });
  }

  resetHandleSort() {
    this.handleSortOrders = {
      enabled: false,
      key: '',
      type: 'asc'
    };
  }
  resetHandleFilter() {
    this.handleFilterOrders = {
      enabled: false,
      key: '',
      value: ''
    };
  }
}
