import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/core/storage/storage.service';
import { environment } from 'src/environments/environment';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  iconInfo = faExclamationCircle;

  @ViewChild('filePondUpload') filePondUpload: any;

  info: any;
  pondOptions :any;
  errorMessage : any ;
  pondFiles = [];

  btnNoImageStatus = false;
  btnImageStatus = true;
  token = '';
  url = environment.env === 'dev' ? 'https://data-entry-service' + `${environment.API_CONTEXT}/data-entry-service/v1` : `${environment.API_CONTEXT}/data-entry-service/v1`;
  constructor(private storage: StorageService) {}

  ngOnInit() {
    this.storage.get('token') ? this.token = this.storage.get('token').token : this.token = '';

    if(this.storage.get('info')) {
      this.info = this.storage.get('info');
    }

    this.pondOptions = {
      labelIdle: '<b>Arrastra</b> o haz <b>clic</b> para añadir los archivos',
      labelFileProcessing: 'Procesando',
      labelFileProcessingComplete: 'Imagen procesada correctamente',
      labelFileProcessingAborted: 'Subida de la imagen cancelada',
      labelFileProcessingError: 'Error durante la carga de la imagen',
      labelTapToCancel: 'Clic para cancelar',
      labelFileTypeNotAllowed: 'El formato de la imagen no es correcto.',
      labelTapToRetry: 'Clic para reintentar',
      fileValidateTypeLabelExpectedTypes:
        'Los formatos aceptados son: png, jpg, jpeg.',
      labelMaxFileSizeExceeded: 'La imagen es demasiado grande.',
      labelMaxFileSize: 'La imagen no puede ser superior a 5MB.',
      maxFileSize: '5MB',
      allowImageValidateSize: true,
      imageValidateSizeMinWidth: 1200,
      imageValidateSizeMinHeight: 800,
      imageValidateSizeLabelImageSizeTooSmall:
        'La imagen es demasiado pequeña.',
      imageValidateSizeLabelExpectedMinSize:
        'La imagen debe tener un tamaño mínimo de 1200x800 píxeles.',
      allowRevert: 'false',
      maxFiles: 1,
      acceptedFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
      server: {
        process: {
          url: `${this.url}/campaigns/${
            this.info?.campaignId
          }/images`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.token}`,
            user_key: 'everis'
          },
          onload: (response: any) => {
            const respon = JSON.parse(response);
            this.onload(respon);
          },
          onerror: (res : any) => {
            return JSON.parse(res);
          }
        }
      }
    };
  }

  onerror(error: any) {
    if (
      error.error.hasOwnProperty('body') &&
      error.error.body.hasOwnProperty('data') &&
      error.error.body.data.hasOwnProperty('errors')
    ) {
      this.errorMessage = {
        errors: error.error.body.data.errors
      };
    }
  }

  onload(respon: any) {
    this.errorMessage = {};
    respon.data = {
      ...respon.data,
      msgCategory: 'campaign'
    };
    this.storage.set('info', respon.data);
    this.btnImageStatus = false;
    this.btnNoImageStatus = true;
  }
}
