import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppraisalRequest } from 'src/app/shared/model/request-detail.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor( private http : HttpClient ) { }

  url = environment.env === 'dev' ? 'https://notification-service' + `${environment.API_CONTEXT}/notification-service` : `${environment.API_CONTEXT}/notification-service`;
  
  requestAppraisal(appraisalData : AppraisalRequest) {
    return this.http.post(
      `${this.url}/v1/appraisal`,
      appraisalData
    );
  }
}
