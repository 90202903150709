<fieldset class="request">
  <legend class="request--legend float-none w-auto p-2">{{'requestInfo' | i18next}}</legend>
  <form [formGroup]="form" class="form">
    
    <section class="requestType">

      <div class="requestType--selection">
        <p class="requestType--title">{{'requestType' | i18next}}</p>
        <div class="form-check requestType--radioInputs">
          <div class="requestType--input">
            <input class="form-check-input"
                   type="radio"
                   id="arr"
                   value="leasing"
                   [ngClass]="disableArrBtn()"
                   formControlName="type"
                   #typeArr
                   (change)="vehicleType(typeArr.value)">
            <label class="requestType--label" [ngClass]="disableArrBtn()">{{'typeArr' | i18next}}</label>
          </div>
          <div class="requestType--dotationInput">
            <input class="form-check-input"
                   type="radio"
                   id="dotation"
                   value="endowment"
                   [ngClass]="disableDotBtn()"
                   formControlName="type"
                   #typeDot
                   (change)="vehicleType(typeDot.value)">
            <label class="requestType--label" [ngClass]="disableDotBtn()">{{'typeEndowment' | i18next}}</label>
          </div>
        </div>
        <p class="requestType--title">{{'requestSubtype' | i18next}}</p>
        <div class="form-check requestType--radioInputs">
          <div class="requestType--subtypeInput">
            <label class="requestType--label" [ngClass]="disableSubtype()">{{'subtypeSus' | i18next}}</label>
            <input class="form-check-input"
                  type="radio"
                  id="subtype"
                  [ngClass]="disableSubtype()"
                  value="replacement"
                  formControlName="subtype"
                  (change)="changeSubtype('replacement')">
          </div>
          <div class="requestType--subtypeInput">
            <label class="requestType--label" [ngClass]="disableFirstAssign()">{{'subtypeFirstAssign' | i18next}}</label>
            <input class="form-check-input"
                  type="radio"
                  id="subtype"
                  [ngClass]="disableFirstAssign()"
                  value="firstAssign"
                  [disabled]="isFirstAssignNotAvailable()"
                  formControlName="subtype"
                  (change)="changeSubtype('firstAssign')">
          </div>
        </div>
      </div>
      <div class="campaignInfo">
        <div class="campaignInfo--container">
          <p class="campaignInfo--title">{{'requestCampaign' | i18next}}</p>
          <p class="campaignInfo--text">id_campaña</p>
        </div>
        <div class="campaignInfo--container type">
          <p class="campaignInfo--title">{{'requestCampaignType' | i18next}}</p>
          <p class="campaignInfo--text">tipo_campaña</p>
        </div>
      </div>
      <div class="retribution">
        <p class="retribution--title">{{'requestRetributionType' | i18next}}</p>
        <p class="retribution--text">{{retribution?.response}}</p>
      </div>
    </section>

    <section class="requestVehicles" *ngIf="!summaryRequest">
      <p class="requestVehicles--title">{{'requestReplacementVehicle' | i18next}}</p>
      <p class="requestVehicles--msg" [ngClass]="checkVehicles()">{{'requestNoVehicle' | i18next}}</p>
      <div class="form-check requestVehicles--radioInputs">
        <ul class="requestVehicles--list">
          <li class="requestVehicles--list__item" *ngFor="let vehicle of vehiclesArr">
            <input 
                   class="form-check-input"
                   type="radio"
                   id="{{ vehicle.plateNumber }}"
                   value="{{vehicle.plateNumber}}"
                   formControlName="vehicles"
                   [attr.disabled]="vehicle.pendingRequest || firstAssign ? true : null"
                   [checked] = "!firstAssign ? true : null"
                   (change)="vehicleReplacement(vehicle)">
            <label class="requestType--label" [ngClass]="disableVehicle(vehicle.pendingRequest)">{{typeTranslation}}: {{vehicle.subtype}} {{vehicle.model}} {{vehicle.plateNumber}} {{vehicle.deliveryDate}}</label>
          </li>
        </ul>
      </div>
      <div class="requestVehicles--estimatedKms" *ngIf="form.value.subtype === 'replacement'">
        <label class="requestVehicles--title"  for="stimatedKms">{{'requestEstimatedKms' | i18next}}</label>
        <input class="requestVehicles--estimatedKms__input"
               type="text"
               name="estimatedKms"
               formControlName="estimatedKms"
               (change)="changeEstimatedKms()" />
        <small class="error" *ngIf="form.controls['estimatedKms'].invalid && form.controls['estimatedKms'].touched">Debe introducir un número válido.</small>
      </div>
    </section>
  </form>
</fieldset>