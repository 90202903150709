<app-navbar></app-navbar>

<div class="text-center spinner-container" *ngIf="spinner">
  <div class="spinner-border spinner" role="status"></div>
</div>

<header *ngIf="!spinner" class="header">
  <h2 class="header--title">{{title}}</h2>
  <h5 *ngIf="subtitle && orderInfo">{{'bookUpdateSubtitle' | i18next}}</h5>
</header>

<main class="book" *ngIf="!spinner">
  <!-- Info peticinario -->
  <app-info-petitioner *ngIf="!spinner" [user]="user"></app-info-petitioner>

  <!-- Datos de vehículo -->
  <app-vehicle [vehicleInfo]="orderInfo"></app-vehicle>
  <!-- Destinatario de la resrva -->
  <fieldset class="book--recipient">
    <legend class="book--legend float-none w-auto p-2">{{'bookRecipient' | i18next}}</legend>
    <form [formGroup]="form" class="form">
      <div class="form--petitioner">
        <div class="form--input-container">
          <p class="form--text">{{'bookRecipientType' | i18next }}</p>
          <label class="form--label"  for="employee">{{'bookEmployee' |i18next}}</label>
          <input type="radio"
                 formControlName="petitionerType"
                 id="employee"
                 checked
                 (change)="changePetitioner()"
                 value="PETITIONER_TYPE_1">
          <label class="form--label" for="family">{{'bookFamiliar' | i18next}}</label>
          <input type="radio"
                 formControlName="petitionerType"
                 id="family"
                 (change)="changePetitioner()"
                 value="PETITIONER_TYPE_3">
          <label class="form--label" for="other">{{'bookExtern' | i18next}}</label>
          <input type="radio"
                 formControlName="petitionerType"
                 id="other"
                 (change)="changePetitioner()"
                 value="PETITIONER_TYPE_2">
        </div>
        <span class="error-message" *ngIf="inputError">{{'errorDestinataryBooking' | i18next}}</span>
        </div>
      <div class="form--data">
        <div class="column">
          <div class="column--item">
            <label class="form--text label" for="name">{{'name' | i18next}}</label>
            <input type="text"
                   class="form--input"
                   formControlName="name"
                   (change)="showFormError()">
          </div>
          <div  class="column--item">
            <label class="form--text label" for="firsName">{{'firstSurname' | i18next}}</label>
            <input type="text"
                   class="form--input"
                   formControlName="firstSurname"
                   (change)="showFormError()">
          </div>
          <div  class="column--item">
            <label class="form--text label" for="secondName">{{'secondSurname' | i18next}}</label>
            <input type="text"
                   class="form--input"
                   formControlName="secondSurname"
                   (change)="showFormError()">
          </div>
          <small *ngIf="showError || (form.controls['email'].invalid && form.controls['email'].touched)" class="error-message">{{'errorFormBooking' | i18next}}</small>
        </div>
        <div class="column-b">
          <div class="column--item">
            <label class="form--text label" for="phone">{{'phone' | i18next}}</label>
            <input type="text"
                   class="form--input"
                   formControlName="telephone"
                   (change)="showFormError()">
          </div>
          
          <div class="column--item">
            <label class="form--text label" for="email">{{'email' | i18next}}</label>
            <input type="email"
                   class="form--input"
                   formControlName="email"
                   (change)="showFormError()">
          </div>
        </div>
      </div>
    </form>
  </fieldset>

  <div class="btn-container">
    <button type="button" class="btn btn-cancel" [routerLink]="['/list-request']">{{'btnCancel' | i18next}}</button>
    <button type="button" class="btn confirm bgColor btn-submit" (click)="submitBook()" >{{'btnConfirmBook' | i18next}}</button>
    <div class="text-center spinner-submit-container" [hidden]="!spinnerSubmit">
      <div claa="spinner-submit" role="status"></div>
    </div>
  </div>
</main>
