import { Component, Input, OnInit } from '@angular/core';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Equipment } from '../../model/campaign.model';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent implements OnInit {

  @Input() equipments: Equipment[] = [];
  @Input() titleUpperCase = false;

  checkIcon = faCheckSquare;

  constructor() { 
    //
  }

  ngOnInit() {
    //
  }

}
