import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  faSortDown,
  faSortUp,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-advanced-header',
  templateUrl: './advanced-header.component.html',
  styleUrls: ['./advanced-header.component.scss'],
  providers: []
})
export class AdvancedHeaderComponent implements OnInit {
  @Input() title!: string;
  @Input() key!: string;
  @Input() filterable!: boolean;
  @Input() sorted!: boolean;

  @Input() activeFilterKey!: Observable<string>;

  @Output() sortedEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterableEvent: EventEmitter<any> = new EventEmitter();

  sortType = 'des';
  inputSearch = '';

  // Icons
  iconSortDesc = faSortDown;
  iconSortAsc = faSortUp;
  iconFilter = faSearch;

  constructor(private tooltipConfig: NgbTooltipConfig) {}

  ngOnInit() {
    this.tooltipConfig.placement = 'bottom';

    this.activeFilterKey.subscribe((key: string) => {
      if (key !== this.key) {
        this.sortType = 'des';
      }
    });
  }

  sort(type: 'asc' | 'des', key: string) {
    this.sortType = type;
    this.sortedEvent.emit({ type: type, key: key });
  }

  filter(key: any) {
    this.filterableEvent.emit({ value: this.inputSearch, key: key });
  }
}
