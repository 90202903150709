<form [formGroup]="form" class="modal-container">
  <h4 class="modal-container--title">{{'appraisal' | i18next}}</h4>
  <p class="modal-container--text">{{'appraisalText' | i18next}}</p>
  <div class="modal-container--content">
    <div class="modal-container--inputContainer">
      <label class="modal-container--label">{{'appraisalExpectedKm' | i18next}}{{'required' | i18next}}</label>
      <input type="text" 
             formControlName="expectedKms"
             class="modal-container--input kmInput"/>
      <span class="error-message">
        <p *ngIf="errorMsg('expectedKms')">{{'numberError' | i18next}}</p>
      </span>
    </div>
    <div class="modal-container--inputContainer">
      <label class="modal-container--label">{{'appraisalExpectedDate' | i18next}}{{'required' | i18next}}</label>
      <input type="date" 
             formControlName="expectedDate"
             class="modal-container--input"
             (change)="checkDateMin()"/>
      <span class="error-message">
        <p *ngIf="dateError">{{'errorDateGreaterThanToday' | i18next}}</p>
        <p *ngIf="errorMsg('expectedDate')">{{'requiredInput' | i18next}}</p>
      </span>
    </div>
  </div>
  <div class="modal-container--footer-modal">
    <div class="modal-container--btn-container">
      <button type="button" class="btn-cancel" (click)="closeModal()">{{'btnBack' | i18next}}</button>
      <button type="button" class="btn" (click)="confirmAppraisal()" [disabled]="form.invalid || errorMsg('expectedKms') || dateError">{{'btnSend' | i18next}}</button>
    </div>
    <div class="text-center spinner-container" [hidden]="!spinner">
      <div class="spinner-border spinner" role="status"></div>
    </div>
  </div>
</form>