import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackofficeComponent } from './backoffice.component';
import { ModalEditCampaignComponent } from './modal-edit-campaign/modal-edit-campaign.component';
import { OrdersComponent } from './orders/orders.component';
import { BasicHeaderComponent } from './orders/table-orders/basic-header/basic-header.component';
import { AdvancedHeaderComponent } from './orders/table-orders/advanced-header/advanced-header.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalFeedbackComponent } from './modal-feedback/modal-feedback.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TableBackofficeComponent } from './table-backoffice/table-backoffice.component';
import { TableOrdersComponent } from './orders/table-orders/table-orders.component';
import { UploadModule } from './upload/upload.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackofficeHeaderComponent } from 'src/app/layout/backoffice-header/backoffice-header.component';
@NgModule({
  declarations: [
    BackofficeComponent,
    ModalEditCampaignComponent,
    OrdersComponent,
    BasicHeaderComponent,
    AdvancedHeaderComponent,
    ModalConfirmComponent,
    ModalFeedbackComponent,
    TableBackofficeComponent,
    TableOrdersComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbPaginationModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: '#ea5d1a',
      defaultBoColor: '#ea5d1a'
    }),
    UploadModule,
    ReactiveFormsModule,
    FormsModule,
    BackofficeHeaderComponent,
  ]
})
export class BackofficeModule { }
