<div class="image-background image-bg container-custom">
  <div class="container-form">
    <div class="container-logo">
      <img src="../../../assets/img/seat-logo-horizontal.png" alt=" {{'altUser' | i18next}}" />
    </div>
    <div class="container-title">
      {{ 'title' | i18next }}
    </div>
    <form class="form" [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="content-input">
        <input
          type="text"
          required
          autocomplete="new-name"
          formControlName="nis"
          placeholder="{{'nis' | i18next}}"
          #autofocus
        />
        <span class="highlight"></span>
        <span class="border-bottom"></span>
        <input 
          type="password"
          required
          formControlName="password"
          placeholder="{{ 'password' | i18next}}"
        />
        <span class="highlight"></span>
        <span class="border-bottom"></span>
      </div>
      <div class="content-error">
        {{ errorMessage }}
      </div>
      <div class="login-container">
        <button type="submit" 
        class="btn confirm bgColor loginBtn"
        [disabled]="!userForm.valid">
        {{'entry' | i18next}}
        </button>
        <div class="text-center spinner-container" *ngIf="spinner">
          <div class="spinner-border spinner" role="status"></div>
        </div>
      </div>
      <div class="infoBox">
        <p>{{'remindercredentials' | i18next}}</p>
      </div>
      <div class="btn-container">
        <p class="register--text">{{'withoutUser' | i18next}}
          <button class="btn link btn-text"
                  type="button"
                  [routerLink]="['/register']">
          {{'createUser' | i18next}}
        </button>
        </p>
        <p class="register--text forgotPassord" >{{'forgotPasswordQuestion'| i18next}}
          <button class="btn link btn-text"
                  type="button"
                  (click)="forgotPassword()">
                  {{'forgotPassword'| i18next}}
        </button>
        </p>
      </div>
    <router-outlet></router-outlet>
    </form>
  </div>
</div>
