import { Component, OnInit } from '@angular/core';
import { Campaign, updateStatusCampaing } from '../../shared/model/backoffice.model';
import { CampaignsService } from '../../core/services/campaigns.service';
import { pull } from 'lodash';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditCampaignComponent } from './modal-edit-campaign/modal-edit-campaign.component';

const genericHeadersTable = [
  'ID Campaña',
  'Nombre de la campaña',
  'Modelo',
  'Stock disponible',
  'Fecha inicio',
  'Fecha fin',
  'Visible para',
  'Imagen'
];

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss']
})
export class BackofficeComponent implements OnInit {
  // Active campaigns
  activeCampaigns!: Campaign[];
  activeHeadersTable: string[]= genericHeadersTable;

  // Inactive campaigns
  inactiveCampaigns!: Campaign[];
  inactiveHeadersTable = genericHeadersTable;

  // finished campaigns
  finishedCampagins!: Campaign[];
  finishedHeadersTable = genericHeadersTable;

  errorToken = false;
  isBackoffice = 'backoffice';
  constructor(
    private campaignsService: CampaignsService,
    private authentication: AuthenticationService,
    private modalConfig: NgbModal
  ) {}

  ngOnInit() {
    this.getCampaignsBackoffice();
  }

  getCampaignsBackoffice() {
    this.campaignsService.getCampaignsBackoffice('active').subscribe({
      next: (resp) => {
      this.activeCampaigns = resp.data;
      }
  });
    this.campaignsService.getCampaignsBackoffice('inactive').subscribe((resp) => {
      this.inactiveCampaigns = resp.data;
    });
    this.campaignsService.getCampaignsBackoffice('finished').subscribe((resp) => {
      this.finishedCampagins = resp.data;
    });
  }

  onToggle(data: updateStatusCampaing) {
    this.campaignsService
      .updateStatusCampaign(data.campaign.id, data.status)
      .subscribe({
        next: (res) => {
          if (res.code === '200 OK') {
            if (data.status) {
              this.changeStatus(
                this.inactiveCampaigns,
                this.activeCampaigns,
                data.campaign
              );
            } else {
              this.changeStatus(
                this.activeCampaigns,
                this.inactiveCampaigns,
                data.campaign
              );
            }
          }
        },
        error: (error) => {
          if (error.message === 'Acceso denegado') {
            this.authentication.logout();
          }
        }
      });
  }

  changeStatus(listToRemove: Campaign[], listToAdd: Campaign[], campaing: Campaign) {
    pull(listToRemove, campaing);
    campaing.isEnabled = !campaing.isEnabled;
    listToAdd.push(campaing);
  }

  editCampaign(campaign: Campaign) {
    const modalRef = this.modalConfig.open(ModalEditCampaignComponent, {
      centered: true
    });

    modalRef.componentInstance.campaign = campaign;

    modalRef.result.then(updated => {
      if (updated) {
        this.getCampaignsBackoffice();
      }
    });
  }
}
