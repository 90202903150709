import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { StorageService } from '../../core/storage/storage.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UserRegister } from 'src/app/shared/model/register.model';
import { CoreService } from 'src/app/core/services/core.service';
import i18next from 'i18next';
import { ControlErrors } from 'src/app/shared/utils/generateError';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements AfterViewInit{
  @ViewChild('autofocus') autofocus!: ElementRef<HTMLInputElement>;
  passwordsMatch = false;
  userRegister : UserRegister = {
    nis: '',
    password: '',
    repeatPassword: ''
  };
  errorMessage = '';
  pattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{12,60}$';

  registerForm: FormGroup = this.fb.group({
    nis: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    password: ['', [Validators.required, Validators.pattern(this.pattern)]],
    repeatPassword: ['', [Validators.required, Validators.pattern(this.pattern)]],
  });

  passwordTranslate = i18next.t('passwordTranslate');
  spinner = false;
  constructor( private fb: FormBuilder,
               private router: Router,
               private storageService: StorageService,
               private coreService: CoreService,
               private controlErrors: ControlErrors
               ) { }

  ngAfterViewInit() {
    this.autofocus.nativeElement.focus();
  }

  controlInput(input: string) : boolean {
    return this.registerForm?.controls[input]?.invalid && this.registerForm?.controls[input]?.touched;
  }
  errorMsg(input: string) {
    this.spinner = false;
    if (this.registerForm?.controls[input]?.invalid && this.registerForm?.controls[input]?.touched) {
      return 'error-message';
    } else {
      return 'info-text';
    }
  }
  
  onSubmit () {
    this.spinner = true;
    //validar los campos del formulario
    this.userRegister = this.registerForm.value;
    if (this.userRegister.password === this.userRegister.repeatPassword) {
      this.passwordsMatch = true;
      //guardar la info del usuario en el storage
      this.storageService.set('userRegister', this.userRegister);
  
      this.coreService.registerUser(this.userRegister.nis, this.userRegister.password)
        .subscribe({
         next: () => {
          this.spinner = false;
            this.router.navigateByUrl('register/verification-code');
          },
          error: (e: HttpErrorResponse) => {
			      this.spinner = false;  
            if (e.status === 406 && e.error.message === '5003') {
              this.errorMessage = this.controlErrors.generateError(e.error.message, i18next.t('errorNoPhones'));
            } else if (e.status === 406 && e.error.message === '5001')  {
              this.errorMessage = this.controlErrors.generateError(e.error.message, i18next.t('re-verificationError'));
            } else if (e.status === 406 && e.error.message === '5005')  {
              this.errorMessage = this.controlErrors.generateError(e.error.message, i18next.t('categoryError'));
            } else if (e.status === 412) {
              this.errorMessage = this.controlErrors.generateError(e.error.message, i18next.t('loginNoPss'));
            } else {
              this.errorMessage = this.controlErrors.generateError(e.error.message, i18next.t('genericErr'));
            }   
          }
        });
      
    } else {
      this.errorMessage = i18next.t('passwordErr');
    }
  }
}