import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import i18next from 'i18next';
import { PersonalInfo } from 'src/app/shared/model/request-detail.model';
@Component({
  selector: 'app-data-modal',
  templateUrl: './data-modal.component.html',
  styleUrls: ['./data-modal.component.scss']
})
export class DataModalComponent  implements OnInit{
  @Input() modalData? : any;
  @Input() modalCertData? : boolean;

  @Output() dataEmitter = new EventEmitter<PersonalInfo>();

  errorMsg = false;
  type = '';
  form : FormGroup = this.fb.group({
    fullName: ['', [Validators.required]],
    address: ['', [Validators.required]],
    city: ['', [Validators.required]],
    postalCode: [ '', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5), Validators.maxLength(5)]],
  });

  fullNameTraslate = i18next.t('fullname');
  addresTraslate = i18next.t('address');
  cityTraslate = i18next.t('city');
  postalCodeTraslate = i18next.t('postalCode');

  constructor( private fb : FormBuilder,
               private activeModal: NgbActiveModal  
  ) {}

  ngOnInit(): void {
    if (this.modalData) {
      this.form.setValue(this.modalData.filledPersonalInfo);
    }
  }
  onSubmit() {
    if (this.form.invalid) {
      this.errorMsg = true;
    } else {
      this.errorMsg = false;
      this.activeModal.close();
      this.dataEmitter.emit(this.form.value);
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}