<div class="container-equipment-vehicle">
  <div *ngIf="titleUpperCase; else normalTitle" class="title-section">{{ 'extraEquipment' | i18next | uppercase }}</div>
  <ng-template #normalTitle>
    <div class="title-section">{{ 'extraEquipment' | i18next }}</div>
  </ng-template>

  <div *ngFor="let equipment of equipments" class="equipment-item">
    <div class="content-check">
      <fa-icon [icon]="checkIcon" class="icon"></fa-icon>
    </div>
    <div class="text">{{equipment.description}}</div>
  </div>
</div>