import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbAlertModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FinishesCampaignComponent } from './finishes-campaign/finishes-campaign.component';
import { CampaignsComponent } from './campaigns.component';
import { EquipmentFinishesComponent } from './finishes-campaign/equipment-finishes/equipment-finishes.component';
import { ModalColoursComponent } from './finishes-campaign/equipment-finishes/modal-colours/modal-colours.component';
import { ModalEquipmentComponent } from './finishes-campaign/equipment-finishes/modal-equipment/modal-equipment.component';
import { RouterModule } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import { SharedModule } from 'src/app/shared/shared.module';
import { SummaryComponent } from './summary/summary.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalReturnVehicleComponent } from './modal-return-vehicle/modal-return-vehicle.component';
import { FeedbackComponent } from './summary/feedback/feedback.component';



@NgModule({
  declarations: [
    ModalEquipmentComponent,
    ModalColoursComponent,
    CampaignsComponent,
    EquipmentFinishesComponent,
    FinishesCampaignComponent,
    SummaryComponent,
    ModalReturnVehicleComponent,
    FeedbackComponent,
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    NgbAlertModule,
    RouterModule,
    I18NextModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class CampaignsModule { }
