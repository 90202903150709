import { Component, OnInit } from '@angular/core';
import { Location, ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import i18next from 'i18next';

import { environment } from 'src/environments/environment';

import { DataEntryService } from 'src/app/core/services/data-entry.service';
import { OrderService } from 'src/app/core/services/order.service';
import { StorageService } from '../../core/storage/storage.service';

import { AppraisalModalComponent } from './appraisal-modal/appraisal-modal.component';
import { DocumentationModel, Documents } from 'src/app/shared/model/request-detail.model';
import { Status } from 'src/app/shared/model/request-list.model';
import { ControlErrors } from 'src/app/shared/utils/generateError';
import { DeleteUpdateModalComponent } from '../book/delete-update-modal/delete-update-modal.component';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  user = this.storageService.get('config');
  orderInfo!: any;
  documentation!: Documents[];
  requestNumber = '';
  showTable = false;
  spinner = true;
  rejectState = false;
  admin!: boolean;
  requestError!: boolean;
  detailRequestError = false;
  detailRequestErrorText = '';
  alert?: boolean;
  documentTypeTranslation = '';
  certZoneInvalid = false;
  certData!:any;
  prod!: boolean;
  actualStatus = '';
  summaryRequest= 'summaryRequest';
  status!: Status;
  bookingId!: number | null;
  modalContent?: NgbModalRef;
  isCancelAvailable = false;
  actionsAvailable = false;
  hasPlateNumber = false;
  
  constructor(
    private storageService: StorageService,
    private dataEntryService: DataEntryService,
    private orderService: OrderService,
    private location: Location,
    private route: ActivatedRoute,
    private scroller: ViewportScroller,
    private controlErrors: ControlErrors,
    private router: Router,
    private modalConfig: NgbModal,
  ) {}

  ngOnInit(): void {
    environment.env  === 'prod' ? this.prod = true : this.prod = false;
    if (this.prod) {
      this.route.paramMap.subscribe((params) => {
        const reqNumber =  params.get('requestNumber');
        if(!!reqNumber)
        {
          this.requestNumber = reqNumber;
          this.requestError = false;
        }
      });
    } else {
      this.requestNumber = '240159';
    }
    
    this.admin = this.storageService.get('config').admin;
    this.bookingId = this.storageService.get('bookingId');
    this.getDetailInfo();
  }

  getDetailInfo () {
    this.orderService.getDetailRequest(this.requestNumber, this.bookingId).subscribe({
      next: (resp) =>{
        this.detailRequestError = false;
        this.orderInfo = resp;
        this.orderInfo.certificateDTO ? this.certData = this.orderInfo.certificateDTO : this.certData = null;
        this.hasPlateNumber = this.orderInfo.registrationDataDTO.plateNumber !== '';
        if(this.orderInfo && this.orderInfo.orderInfoDTO) {
          if (this.orderInfo.orderInfoDTO.state === 'Nueva Solicitud' || this.orderInfo.orderInfoDTO.state === 'Autoriza RRHH'
            || this.orderInfo.orderInfoDTO.state === 'Autoriza Controller' || this.orderInfo.orderInfoDTO.state === 'No autorizada por controller'
            || this.orderInfo.orderInfoDTO.state === 'Procesa solicitud PV') {
              this.isCancelAvailable = true;
          } else {
            this.isCancelAvailable = false;
          }
          if (this.orderInfo.orderInfoDTO.state === 'Pendiente FAVAS' || this.orderInfo.orderInfoDTO.state === 'Pendiente Fabricación' || this.orderInfo.orderInfoDTO.state === 'Pendiente Fabricacion'
             || this.orderInfo.orderInfoDTO.state === 'Pendiente Expedicion' || this.orderInfo.orderInfoDTO.state === 'Pendiente Matrícula' || this.orderInfo.orderInfoDTO.state === 'Pendiente Matricula'
             || this.orderInfo.orderInfoDTO.state === 'Pendiente Entrega' || this.orderInfo.orderInfoDTO.state === 'Cancelada') {
              this.actionsAvailable = false;
          } else {
            this.actionsAvailable = true;
          }
        }
        this.orderService.getDetailStates(this.requestNumber).subscribe({
          next: (resp) => {
            this.spinner = false;
            this.status = resp;
          },
          error: () => {
            this.spinner = false;
          }
        });
        this.getDocumentation();
      },
      error: (e: HttpErrorResponse) => {
        this.detailRequestError = true;
        this.detailRequestErrorText = this.controlErrors.generateError(e.error.message, i18next.t('errorDetail'));
      }
    });
  }

  //trae la documentación asociada a esta solicitud
  getDocumentation(modalContent?: any) {
    if(this.orderInfo) { 
      this.dataEntryService.getDocumentation( this.user.admin, this.orderInfo.petitionerInfo.nis, this.requestNumber)
         .subscribe({
          next: (resp: DocumentationModel) => {
            this.documentation = resp.documents.map((document : Documents) => {
              return {
                ...document,
                showName : document.fileName.split('/').pop()
              };
            });
            this.alert = this.documentation.some((docu) => {
              return (docu.personalInfo === null || docu.personalInfo === undefined) && docu.documentType === 'TRAFFIC_FINE';
            });
            
            this.certZoneInvalid = this.documentation.some((docu) => {
              return docu.documentType === 'GREEN_ZONE_CERTIFICATE';
            });
            if(modalContent) {
              modalContent.close();
            }
          },
        });
    }
  }

  requestAppraisal(requestNumber: string) {
    this.modalContent = this.modalConfig.open(AppraisalModalComponent, {
      windowClass: 'order-modal',
      centered: true,
      backdrop: 'static'
    });
    this.modalContent.componentInstance.modalData = requestNumber;
  }

  newBook( requestNumber: string) {

    const bookInfo = {
      requestNumber: requestNumber,
      bookingId: this.bookingId
    };
    this.storageService.set('bookInfo', bookInfo);
    this.router.navigateByUrl('booking');
  }

  deleteBook(requestNumber: string, vehicleModel: string) {
    this.modalContent = this.modalConfig.open(DeleteUpdateModalComponent, {windowClass: 'modal-block', centered: true, backdrop : 'static'});
    this.modalContent.componentInstance.modalData = {bookId: this.bookingId, requestNumber: requestNumber, vehicleModel: vehicleModel};
    this.modalContent.componentInstance.type = 'delete';
  }

  cancelRequest(requestNumber: string, nis: string) {
    this.modalContent = this.modalConfig.open(FeedbackModalComponent, { windowClass: 'order-modal', centered: true, backdrop: 'static'});
    this.modalContent.componentInstance.text = i18next.t('cancelRequest');
    this.modalContent.componentInstance.cancelOrder = true;
    this.modalContent.componentInstance.confirmCancelOrderEmitter.subscribe({
      next: () => {
        this.orderService.cancelRequest(requestNumber, nis).subscribe({
          next: () => {
            this.modalContent = this.modalConfig.open(FeedbackModalComponent, { windowClass: 'order-modal', centered: true, backdrop: 'static'});
            this.modalContent.componentInstance.text = i18next.t('cancelRequestOk');
            this.getDetailInfo();
          },
          error: () => {
            this.modalContent = this.modalConfig.open(FeedbackModalComponent, { windowClass: 'order-modal', centered: true, backdrop: 'static'});
            this.modalContent.componentInstance.text = i18next.t('errorTryLater');
          }
        });
      }
    });
    
  }

  reloadDocuments(modalContent: any) {
    this.getDocumentation(modalContent);
  }
 
  scrollToDocuments() {
    this.scroller.scrollToAnchor('documentation');
  }

  goBack() {
    this.location.back();
  }
}
