import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignsService } from '../../../core/services/campaigns.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { CampaignStock, Order, } from 'src/app/shared/model/campaign.model';
import { LeaseInfoRestDTO } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-modal-order-status',
  templateUrl: './modal-order-status.component.html',
  styleUrls: ['./modal-order-status.component.scss']
})
export class ModalOrderStatusComponent implements OnInit {
  @Input() modalData! : string;
  @Input() vehicleType! : string;

  order!: Order;
  clicked = false;
  infoUser!: {
    nis: string;
    email: string;
    leasedVehicleRest: LeaseInfoRestDTO;
  };
  leasedVehicleRest?: any;
  requestType = '';
  data! : CampaignStock;
  constructor(
    public activeModal: NgbActiveModal,
    private storage: StorageService,
    private campaignService: CampaignsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.infoUser = this.storage.get('config');
    this.leasedVehicleRest = this.storage.get('leasedVehicleRest');
  }

  createOrderData() {
    if (this.storage.get('stock')) {
      this.data = this.storage.get('stock');
    }
    if (!!this.data) {
      this.order = {
        idCampaign: this.data.idCampaign,
        tmaimg: this.data.tmaimg,
        co2Extimated: this.data.co2,
        descExternalColour: this.data.colour?.description,
        externalColour: this.data.colour?.code,
        fuelType: this.data.fuelType,
        incrementB: this.data.increaseB,
        incrementC: this.data.increaseC,
        model: this.data.motorInfo,
        modelYear: this.data.modelYear,
        optionals: this.data.equipments,
        quota: this.data.baseFee,
        primaryRE: this.data.primaryRE,
        secondaryRE: this.data.secondaryRE,
        primaryEndowmentRE: this.data.primaryEndowmentRE,
        referenceValue: this.data.referenceValue,
        retributionCalcPct: this.data.retributionCalcPct,
        profReductorEnd: this.data.profReductorEnd,
        profReductorLea: this.data.profReductorLea,
        requestNumber: this.infoUser!.nis,
        vehicleType: this.vehicleType
      };

    }
    this.createOrder();
  }

  createOrder() {
    this.campaignService
      .generateOrder(this.order, this.infoUser, this.leasedVehicleRest)
      .subscribe({
        next: (resp) => {
          this.redirectToFinally(resp.code, resp.message);
        },
        error: (error: HttpErrorResponse) => {
          this.redirectToFinally(error?.status, error?.error.message);
        },
      });

    this.activeModal.close();
  }

  cancelOperation() {
    this.storage.removeItem('leasedVehicleRest');
    this.activeModal.close();
  }

  redirectToFinally(code: number, message: string) {
    const data = {
      code: code,
      message: message,
    };

    this.storage.removeItem('leasedVehicleRest');
    this.storage.removeItem('requestType');
    this.storage.set('order', data);
    this.router.navigateByUrl('/summary/finally');
  }

}
