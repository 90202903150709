import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import i18next from 'i18next';
import { OrderService } from 'src/app/core/services/order.service';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';

@Component({
  selector: 'app-delete-update-modal',
  templateUrl: './delete-update-modal.component.html',
  styleUrls: ['./delete-update-modal.component.scss']
})
export class DeleteUpdateModalComponent implements OnInit {

  @Input() modalData! : {bookId: string, requestNumber: string, vehicleModel: string};
  @Input() type!: string;

  @Output() updateEmitter = new EventEmitter<boolean>();

  requestNumber!: string;
  title = '';
  text = '';

  constructor( private activeModal: NgbActiveModal,
               private modalConfig: NgbModal,
               private orderService: OrderService)
  { }

  ngOnInit(): void {
    this.requestNumber = this.modalData?.requestNumber;
    
    if (this.type === 'delete') { 
      this.title = i18next.t('bookCancelTitle');
      this.text = i18next.t('bookCancelText');
    } else {
      this.title = i18next.t('bookUpdateTitle');
      this.text = i18next.t('bookUpdateText');
    }
  }

  actionBook() {
    this.activeModal.close();
    if (this.type === 'delete' && this.modalData) {
      this.orderService.deleteBookedVehicle(this.modalData.bookId, this.requestNumber).subscribe({
        next: () => {        
          const modalRef = this.modalConfig.open(FeedbackModalComponent, {
            centered: true
          });
          modalRef.componentInstance.text = `La reserva del vehículo de la solicitud ${this.modalData.vehicleModel} ha sido cancelada`;
          modalRef.componentInstance.list = true;
          modalRef.componentInstance.redirect = true;
        },
        
        error: () => {
          const modalRef = this.modalConfig.open(FeedbackModalComponent, {
            centered: true
          });
          modalRef.componentInstance.text = i18next.t('confirmationModalTextKo');
        }
      });
    } else {
      this.updateEmitter.emit(true);
    }
  }

  close () {
    this.activeModal.close();
  } 

}
