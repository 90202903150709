import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/shared/model/user.model';
import { Vehicles, Retribution } from '../../../shared/model/vehicle.model';
import { StorageService } from '../../../core/storage/storage.service';
import { NewRequestForm } from 'src/app/shared/model/order.model';

@Component({
  selector: 'app-info-request',
  templateUrl: './info-request.component.html',
  styleUrls: ['./info-request.component.scss']
})
export class InfoRequestComponent implements OnInit, OnChanges {
  @Input() user!: User;
  @Input() vehiclesArr? : Vehicles[];
  @Input() retribution? : Retribution;
  @Input() typeTranslation = '';
  @Input() summaryRequest? : string;
  
  @Output() vehicleTypeEmitter = new EventEmitter<string>();
  @Output() vehicleReplacementEmitter = new EventEmitter<Vehicles>();
  @Output() requestFormEmitter = new EventEmitter<NewRequestForm>();
  @Output() vehicleFirstAssignEmitter = new EventEmitter<boolean>();

  selectedVehicle? : Vehicles[];
  form : FormGroup = this.fb.group({});
  firstAssign = false;

  constructor( private fb : FormBuilder,
               private storageService : StorageService) { }

  ngOnInit(): void {
    this.initForm();
    this.form.value.vehicles = this.vehiclesArr;
    
    if (this.disableArrBtn()) {
      this.form.get('type')?.setValue('endowment');
    } else if (this.disableDotBtn()) {
      this.form.get('type')?.setValue('leasing');
    }
    if((this.vehiclesArr?.length === 0 || this.allVehiclesHasPendingRequest()) && !this.isFirstAssignNotAvailable()) {
      this.form.get('subtype')?.setValue('firstAssign');
      this.changeSubtype('firstAssign');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
     if(!!changes['vehiclesArr'] && !!this.vehiclesArr && this.vehiclesArr.length > 0){
      if(!this.allVehiclesHasPendingRequest()) {
        this.form.get('subtype')?.setValue('replacement');
        this.changeSubtype('replacement');
      } else if((this.vehiclesArr?.length === 0 || this.allVehiclesHasPendingRequest())  && !this.isFirstAssignNotAvailable()) {
        this.form.get('subtype')?.setValue('firstAssign');
        this.changeSubtype('firstAssign');
      } else {
        this.form.get('subtype')?.reset();
        this.changeSubtype(null);
      }
      
      this.selectedVehicle = this.vehiclesArr;
      for (let i = 0; i < this.vehiclesArr.length; i++) {
        if(!this.vehiclesArr[i].pendingRequest){
         this.form.get('vehicles')?.setValue(this.vehiclesArr[i].plateNumber);
         this.vehicleReplacement(this.vehiclesArr[i]);
         break;
        }
      }
    }  
  }

  changeSubtype(data: string | null) { 
    data === 'firstAssign' ? this.firstAssign = true : this.firstAssign = false;
    this.vehicleFirstAssignEmitter.emit(this.firstAssign);
    this.form.value.vehicles = [];
    this.requestFormEmitter.emit(this.form.value);
  }

  changeEstimatedKms() {
    this.form.controls['estimatedKms'].valid ? this.requestFormEmitter.emit(this.form.value) : this.requestFormEmitter.emit();
  }

  isFirstAssignNotAvailable = () : boolean => {
    const max = this.form.value.type === 'leasing' ? this.user.leaseInfo.maxVehiclesArrendamiento : this.user.leaseInfo.maxVehiclesDotacion;    
    if (this.vehiclesArr) {
      return this.vehiclesArr.length >= max;
    } else {
      return true;
    }
  };
  
  vehicleType(value: string) {
    this.vehicleTypeEmitter.emit(value);
  }

  vehicleReplacement(value: Vehicles) {
    this.storageService.set('vehicleSustitution', value);
    this.form.value.vehicles = value;
    
    this.vehicleReplacementEmitter.emit(value);
  }


  checkBtn () {
     if(this.user.leaseInfo.maxVehiclesArrendamiento > 0 && this.user.leaseInfo.maxVehiclesDotacion === 0 || 
      this.user.leaseInfo.maxVehiclesArrendamiento > 0  && this.user.leaseInfo.maxVehiclesDotacion > 0) {
      this.vehicleType('leasing');
      return 'leasing';
    } else if (this.user.leaseInfo.maxVehiclesDotacion > 0 && this.user.leaseInfo.maxVehiclesArrendamiento === 0 ) {
      this.vehicleType('endowment');
      return 'endowment';
    }  else {
      return '';
    }
  }


  private checkSubtype () :string {
    return (this.user.leaseInfo.maxVehiclesArrendamiento === 0 && this.user.leaseInfo.maxVehiclesDotacion === 0) || this.allVehiclesHasPendingRequest()  ? 'firstAssign' : 'replacement';
  }

  checkVehicles() {
    if (this.vehiclesArr?.length === 0) {
      return 'show';
    } else {
      return 'requestVehicles--msg';
    }
  }

  initForm () : void {
    this.form = this.fb.group({
      type: [this.checkBtn()],
      subtype: [this.checkSubtype()],
      vehicles: [],
      estimatedKms: [, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]]
    });
  }

  disableArrBtn(){
    return this.user.leaseInfo.maxVehiclesArrendamiento === 0 ?'disable-radio' : '';
  }

  disableDotBtn(){
    return this.user.leaseInfo.maxVehiclesDotacion === 0 ?'disable-radio' : '';
  }
  
  disableSubtype() {
    return (this.user.leaseInfo.maxVehiclesArrendamiento === 0 && this.user.leaseInfo.maxVehiclesDotacion === 0) || this.allVehiclesHasPendingRequest() ? 'disable-radio' : '';
  }

  allVehiclesHasPendingRequest = () => this.vehiclesArr?.every((vehicle) => vehicle.pendingRequest === 1);

  disableFirstAssign() {
    return this.isFirstAssignNotAvailable() ? 'disable-radio' : '';
  }

  disableVehicle(data: number): string {
    return data === 1 ? 'disable-radio' : '';
  }

}