<app-navbar></app-navbar>
<div class="text-center spinner-container" *ngIf="spinner">
  <div class="spinner-border spinner" role="status"></div>
</div>
<main *ngIf="!requestError && !spinner" class="detail">
  <!-- Alerta si hay documentación -->
  <div *ngIf="!spinner && alert" class="alert alert-danger" (click)="scrollToDocuments()" role="alert" >
    <div class="alert-icon-container">
      <img class="alert-icon" src="../../../assets/icons/warning-24.svg" alt="{{'altAlert' | i18next}}">
    </div>
    <p class="alert-text">
      {{'fineAlert' | i18next}}
      <span class="alert-text--bold">{{'fineAlertLink' | i18next }}</span>
    </p>
  </div>
  <!--  Header -->
  <header class="header" *ngIf="!spinner">
    <h2 class="header--title">{{'detailRequestTitle' | i18next}}</h2>
    <div class="header--info">
      <div class="header--infoContainer">
        <p class="header--title">{{'summaryReqNumber' | i18next}}</p>
        <p class="header--text">{{orderInfo.orderNumber}}</p>
      </div>
      <div class="header--infoContainer">
        <p class="header--title">{{'state' | i18next}}: </p>
        <p class="header--text">{{status.actualState | uppercase}}</p>
      </div>
    </div>
  </header>
  <!-- Acciones disponibles -->
    <div *ngIf="actionsAvailable" class="d-inline-block" id="dropdown" autoClose="true" ngbDropdown #myDrop="ngbDropdown">
      <button
        type="button"
        class="btn btn-light me-2 dropdown--btn"
        id="dropdownManual"
        ngbDropdownToggle>{{'requestTableActions' | i18next}}
      </button>
      <div id="dropDownOptions" ngbDropdownMenu aria-labelledby="dropdownManual" class="dropdown--options">
        <button ngbDropdownItem
                (click)="requestAppraisal(orderInfo.orderNumber)"
                *ngIf="(orderInfo.orderInfoDTO.state === 'Vehiculo Entregado' || orderInfo.orderInfoDTO.state === 'Vehículo Entregado' )">{{'appraisal' | i18next}}
        </button>
        <button ngbDropdownItem
                (click)="newBook(orderInfo.orderNumber)"
                *ngIf="((orderInfo.orderInfoDTO.state === 'Vehiculo Entregado' || orderInfo.orderInfoDTO.state === 'Vehículo Entregado' ) && !orderInfo.bookedOrderDataDTO)">{{'btnBook' | i18next}}
        </button>
        <button ngbDropdownItem 
                (click)="newBook(orderInfo.orderNumber)"
                *ngIf="orderInfo.bookedOrderDataDTO">{{'btnModifyBooking' | i18next}}
        </button>
        <button ngbDropdownItem
                (click)="deleteBook(orderInfo.orderNumber, orderInfo.requestedVehicleDTO.model)"
                *ngIf="orderInfo.bookedOrderDataDTO?.orderId">{{'btnDeleteBook' | i18next}}
        </button>
        <button ngbDropdownItem
                (click)="cancelRequest(orderInfo.orderNumber, orderInfo.petitionerInfo.nis)"
                *ngIf="isCancelAvailable || user.admin">{{'btnCancel' | i18next}}
        </button>
      </div>
    </div>   
  <!-- Error al cargar detalle -->
  <div class="error" *ngIf="detailRequestError && !spinner">
    <p>{{detailRequestErrorText}}</p>
  </div>
  <!-- marca reserva  -->
  <p class="bookMark" *ngIf="bookingId">{{'bookMark' | i18next}}</p>
  <!-- Info peticinario -->
  <app-info-petitioner *ngIf="!spinner" [user]="orderInfo.petitionerInfo"></app-info-petitioner>
  <!-- Datos del PCC -->
  <app-car-configurator *ngIf="!spinner"
                        [summaryRequest]="summaryRequest" 
                        [vehicleInfoData]="orderInfo.requestedVehicleDTO">
  </app-car-configurator>
  <!-- Matriculación y seguro -->
  <app-frame-insurance-data *ngIf="!spinner && orderInfo.registrationDataDTO" 
                            [vehicleData]="orderInfo.registrationDataDTO">
  </app-frame-insurance-data>
  <!-- Regularización de cuota -->
  <app-quota-info  *ngIf="!spinner"
                   [quotaData]="orderInfo.quotaRegularizationDTO">

  </app-quota-info>
  <!-- Reserva -->
  <app-book-data *ngIf="orderInfo.bookedOrderDataDTO" 
                 [bookData]="orderInfo.bookedOrderDataDTO">
  </app-book-data>
  <!-- Seguimiento estados -->
  <app-tracking [oldStatus]="status.oldStatus"></app-tracking>
  <!-- Datos solicitud -->
  <app-info-replacement-vehicle *ngIf="!spinner"
                                [vehicle]="orderInfo.replacementVehicleDTO"
                                [orderInfo]="orderInfo.orderInfoDTO">
  </app-info-replacement-vehicle>
   <!-- Documentación -->
  <div *ngIf="!spinner">
    <app-documentation id="documentation"
                       [documentation]="documentation"
                       [orderInfo]="orderInfo"
                       [admin]="admin"
                       [requestNumber]="requestNumber"
                       [certZoneInvalid]="certZoneInvalid"
                       (getDocumentsEmitter)="reloadDocuments($event)">
    </app-documentation>
  </div>
  <div class="btnContainer" *ngIf="!spinner">
    <button type="button" class="btn back" (click)="goBack()">{{'btnBack' | i18next}}</button>
  </div>
</main>
<p class="errorMsg" *ngIf="requestError">{{'detailError' | i18next}}</p>