import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/core/storage/storage.service';
import { CoreService } from '../../core/services/core.service';
import i18next from 'i18next';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ControlErrors } from 'src/app/shared/utils/generateError';

@Component({
  selector: 'app-confirm-new-password',
  templateUrl: './confirm-new-password.component.html',
  styleUrls: ['./confirm-new-password.component.scss']
})
export class ConfirmNewPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('autofocus') autofocus!: ElementRef<HTMLInputElement>;

  pattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{12,60}$';
  errorMessage = '';
  userPassword = {
    nis : '',
    code : '',
    password: ''
  };
  resendCodeText = '';
  codeTranslate = i18next.t('code');
  passwordTranslate = i18next.t('passwordTranslate');
  spinner = false;

  constructor(private fb: FormBuilder,
              private modalConfig: NgbModal,
              private storageService: StorageService,
              private coreService: CoreService,
              private controlErrors: ControlErrors ) { }

  ngOnInit(): void {
    this.userPassword.nis = this.storageService.get('config'); 
  }

  ngAfterViewInit() {
    this.autofocus.nativeElement.focus();
  }

  formPassword: FormGroup = this.fb.group({
    code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    password: ['', [Validators.required, Validators.pattern(this.pattern)]],
    repeatPassword: ['', [Validators.required, Validators.pattern(this.pattern)]],
  });

  controlInput(input: string) : boolean {
    return this.formPassword?.controls[input]?.invalid && this.formPassword?.controls[input]?.touched;
  }

  resendCode() {
    this.coreService.forgotPassword(this.userPassword.nis).subscribe({
      next: () => {
        this.resendCodeText = i18next.t('emailVerificationResendFeedbackOk');
      },
      error: () => {
        this.resendCodeText = i18next.t('emailVerificationResendFeedbackKo');
      }
    });
  }

  onSubmit() {
    
    this.userPassword.code = this.formPassword.value.code;
    this.userPassword.password = this.formPassword.value.password;
    if (this.formPassword.value.password === this.formPassword.value.repeatPassword) {
      this.spinner = true;
      this.coreService.confirmPassword(this.userPassword.nis, this.userPassword.code, this.userPassword.password)
        .subscribe({
          next: () => {
            this.spinner = false;
            const modalRef = this.modalConfig.open(FeedbackModalComponent, { windowClass: 'password-modal', centered: true, backdrop : 'static'});
            modalRef.componentInstance.text = i18next.t('passwordChangeSuccess');
            modalRef.componentInstance.redirect = true;
          },
          error: (err: HttpErrorResponse) => {
            this.spinner = false;
            this.errorMessage = this.controlErrors.generateError(err.error.message, i18next.t('genericErr'));
          }
        });
      } else {
        this.errorMessage = i18next.t('passwordErr');
      }

    }

  errorMsg(input: string) {
    if (this.formPassword?.controls[input]?.invalid && this.formPassword?.controls[input]?.touched) {
      return 'error-message';
    } else {
      return 'info-text';
    }
  }
}
