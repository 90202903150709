import { Component } from '@angular/core';
import i18next from 'i18next';

@Component({
  selector: 'app-authorizers',
  templateUrl: './authorizers.component.html'
})
export class AuthorizersComponent {
  serverURL = 'https://authorization-service/v1/authorizers/upload';
  msgCategory = 'authorizers';
  header = i18next.t('backofficeAddAuthorizersExcel');
}
