import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { AuthHeaderInterceptor } from './core/authentication/auth-header-interceptor';

import { ITranslationService, I18NEXT_SERVICE, I18NextModule } from 'angular-i18next';
import i18nextXHRBackend from 'i18next-http-backend';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';

import { registerLocaleData } from '@angular/common';

import localeDeAt from '@angular/common/locales/es';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ViewsModule } from './views/views.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginModule } from './views/login/login.module';
import { RegisterModule } from './views/register/register.module';
import { HeaderComponent } from './layout/header/header.component';
import { YearModalComponent } from './layout/backoffice-header/year-modal/year-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

registerLocaleData(localeDeAt);

const i18nextOptions = {
  whitelist: ['en', 'es'],
  fallbackLng: 'es',
  ns: [
    'translation'
  ],
  backend: {
    loadPath: '/locales/{{lng}}.{{ns}}.json'
  },
  detection: {
    order: ['navigator', 'querystring'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
  }
};

export function appInit(i18next: ITranslationService) {
  return () =>
    i18next
      .use(i18nextXHRBackend)
      .use(i18nextLanguageDetector)
      .init(i18nextOptions);
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }];
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    YearModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    I18NextModule.forRoot(),
    ViewsModule,
    NgbModule,
    LoginModule,
    RegisterModule, 
    FormsModule,
    ReactiveFormsModule 
  ],
  providers: [
    I18N_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
