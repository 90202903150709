import { NgModule } from '@angular/core';

import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { ViewsRoutingModule } from './views-routing.module';
import { CommonModule } from '@angular/common';
import { NewRequestModule } from './new-request/new-request.module';
import { I18NextModule } from 'angular-i18next';
import { ConfidentialityComponent } from './confidentiality/confidentiality.component';
import { AccessLoginComponent } from './access-login/access-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ListRequestComponent } from './list-request/list-request.component';
import { LandingComponent } from './landing/landing.component';
import { SharedModule } from '../shared/shared.module';
import { ConfirmModalComponent } from './order-summary/documentation/confirm-modal/confirm-modal.component';
import { DataModalComponent } from './order-summary/documentation/data-modal/data-modal.component';
import { ModalFeedbackComponent } from './order-summary/documentation/data-modal/modal-feedback/modal-feedback.component';
import { AdministratorModule } from './administrator/administrator.module';
import { CampaignsModule } from './campaigns/campaigns.module';
import { ModalOrderStatusComponent } from './campaigns/modal-order-status/modal-order-status.component';
import { BackofficeModule } from './backoffice/backoffice.module';
import { OrderSummaryModule } from './order-summary/order-summary.module';
import { BookComponent } from './book/book.component';
import { VehicleComponent } from './book/vehicle/vehicle.component';
import { DeleteUpdateModalComponent } from './book/delete-update-modal/delete-update-modal.component';
import { DocumentationInfoComponent } from './documentation-info/documentation-info.component';

@NgModule({
    declarations: [
        ConfidentialityComponent,
        AccessLoginComponent,
        ListRequestComponent,
        LandingComponent,
        ConfirmModalComponent,
        DataModalComponent,
        ModalFeedbackComponent,
        ModalOrderStatusComponent,
        BookComponent,
        VehicleComponent,
        DeleteUpdateModalComponent,
        DocumentationInfoComponent
    ],
    exports: [
        I18NextModule
    ],
    imports: [
        CommonModule,
        PageNotFoundModule,
        ViewsRoutingModule,
        NewRequestModule,
        I18NextModule,
        ReactiveFormsModule,
        SharedModule,
        AdministratorModule,
        CampaignsModule,
        BackofficeModule,
        OrderSummaryModule
    ]
})
export class ViewsModule { }
