<main class="modal-container" id="filledInfo" >
  <h3 *ngIf="!modalCertData" class="modal-container--title">{{'fineModalTitle' | i18next}}</h3>
  <p *ngIf="!modalCertData && !modalData" class="modal-container--text">{{'fineModalText1' | i18next}}<span class="hightlighted">{{'fineModalTextHighligthed' | i18next}}</span>{{'fineModalText2' | i18next}}</p>
  <h3 *ngIf="modalCertData" class="modal-container--title">{{'parkZoneCertTitle' | i18next}}</h3>
  <p *ngIf="modalCertData && !modalData">{{'parkZoneCertText' | i18next}}</p>
  <form class="modal-container--form" id="formFilled" [formGroup]="form">
    <div   class="form-container">
      <input class="form-container--input" 
             type="text" 
             [placeholder]="fullNameTraslate"
             formControlName="fullName"
             [readOnly]="modalData">
      <input class="form-container--input" 
             type="text"
             [placeholder]="addresTraslate"
             formControlName="address"
             [readOnly]="modalData">
      <input class="form-container--input" 
             type="text"
             [placeholder]="cityTraslate"
             formControlName="city"
             [readOnly]="modalData">
      <input class="form-container--input"
             type="text"
             [placeholder]="postalCodeTraslate"
             formControlName="postalCode"
             [readOnly]="modalData">
      <span class="error" *ngIf="form.controls['postalCode'].status === 'INVALID' && form.controls['postalCode'].touched === true">{{'numberError' | i18next}}</span>
      <p *ngIf="errorMsg" class="error">{{'requiredInputsErr' | i18next}}</p>
    </div>
     <div   class="modal-container--btn-container" >
      <button id="backBtn" class="modal-container--btn-container__btn-cancel" (click)="closeModal()" type="button">{{'btnBack' |i18next}}</button>
      <button *ngIf="!modalCertData && !modalData" (click)="onSubmit()" class="modal-container--btn-container__btn" type="submit">{{'btnSend' | i18next}}</button>
      <button *ngIf="modalCertData && !modalData" class="modal-container--btn-container__btn" (click)="onSubmit()" type="submit">{{'btnSend' | i18next}}</button>
    </div>
  </form>
</main>