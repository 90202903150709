import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import i18next from 'i18next';
import { NotificationService } from 'src/app/core/services/notification.service';
import { OrderService } from 'src/app/core/services/order.service';
import { AppraisalRequest } from 'src/app/shared/model/request-detail.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-appraisal-modal',
  templateUrl: './appraisal-modal.component.html',
  styleUrls: ['./appraisal-modal.component.scss'],
})
export class AppraisalModalComponent {
  @Input() modalData! : string;

  appraisalData?: AppraisalRequest;
  expectedKmsForm = '';
  modalContent? : NgbModalRef;
  requestInfo!: any;
  today?: Date | number;
  selectedDate?: number;
  dateError = false;
  spinner = false;
  form: FormGroup = this.fb.group({
    expectedKms: ['',[Validators.required, Validators.pattern('^[0-9]*$')]],
    expectedDate: [this.today, [Validators.required]]
  });

  constructor( private notificationService : NotificationService,
               private orderService : OrderService,
               private activeModal : NgbActiveModal,
               private modalConfig: NgbModal,
               private fb : FormBuilder) 
  {}

  checkDateMin () {
    this.today = Date.now();
    this.selectedDate = new Date(this.form.value.expectedDate).getTime();
    if ( this.selectedDate < this.today) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  errorMsg (invalidForm: string) : string {
    if (this.form.controls[invalidForm].invalid && this.form.controls[invalidForm].touched) {
      return ('error-message');
    } else {
      return '';
    }
  }

  setAppraisalObj() {   
    this.appraisalData = {
      fullName: this.requestInfo.petitionerInfo.fullName,
      email: environment.env === 'prod' ?  this.requestInfo.petitionerInfo.email : 'seat.code.sigeva.lite@nttdata.com',
      telephone: this.requestInfo.petitionerInfo.telephone,
      mobile: this.requestInfo.petitionerInfo.mobile,
      frameNumber: this.requestInfo.requestedVehicleDTO.frameNumber ? this.requestInfo.requestedVehicleDTO.frameNumber : '',
      registrationDate: this.requestInfo.petitionerInfo.registrationDate,
      deliveryDate: this.requestInfo.registrationDataDTO.deliveryDate,
      plateNumber: this.requestInfo.registrationDataDTO.plateNumber,
      tmaimg: this.requestInfo.requestedVehicleDTO.model,
      my: this.requestInfo.requestedVehicleDTO.modelYear,
      color: this.requestInfo.requestedVehicleDTO.descColor,
      optionals: this.requestInfo.requestedVehicleDTO.optionalCodes.toString(),
      expectedKms: this.form.value.expectedKms,
      expectedDevolutionDate: this.form.value.expectedDate,
      codColor: this.requestInfo.requestedVehicleDTO.codColor
    };
  }

  confirmAppraisal() {
    this.spinner = true;
    this.orderService.getDetailRequest(this.modalData).subscribe({
      next: (resp) => {
        this.requestInfo = resp;
        this.setAppraisalObj();
        this.expectedKmsForm = this.form.value.expectedKms.toString();
        if (this.appraisalData) {
          this.form.controls['expectedKms'].status ==='VALID' ? this.appraisalData.expectedKms = this.expectedKmsForm : this.appraisalData.expectedKms = '';
          this.notificationService.requestAppraisal(this.appraisalData).subscribe({
            next: () => { 
              this.spinner = false;
              this.activeModal.close();
              this.modalContent = this.modalConfig.open(ConfirmationModalComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
              this.modalContent.componentInstance.text = i18next.t('confirmationModalTextOk');
            },
            error: () => {
              this.spinner = false;
              this.activeModal.close();
              this.modalContent = this.modalConfig.open(ConfirmationModalComponent, { windowClass: 'order-modal', centered: true, backdrop : 'static'});
              this.modalContent.componentInstance.text = i18next.t('confirmationModalTextKo');
            }
          });
        }
      },
      error: () => {
        this.spinner = false;
      }
    });
    
  }
  closeModal() { 
    this.activeModal.close();
  }
}