<fieldset class="documentation">
  <legend class="documentation--legend float-none w-auto p-2">{{'documentationTitle' | i18next}}</legend>
  <span class="no-documents" *ngIf="!!documentation && documentation.length === 0">{{'noDocuments' | i18next}}</span>
  <div class="documentation--container" *ngIf="!!documentation && documentation.length > 0">
    <table class="table table-striped table-hover"  >
      <thead>
        <tr>
          <th scope="col">{{'documentationTableDate' | i18next}}</th>
          <th scope="col">Tipo de documento</th>
          <th scope="col">{{'documentationTableDescription' | i18next}}</th>
        </tr>
      </thead>
  
      <tbody>
        <ng-container *ngFor="let document of documentation">
          <tr>
            <td>{{document.lastUpdate | date}}</td>
            <td>{{document.documentType | i18next}}</td>
            <td>{{document.showName}}</td>
            <td class="btn-container">
              <button *ngIf="document.documentType === 'TRAFFIC_FINE' || document.documentType === 'GREEN_ZONE_CERTIFICATE'" [ngClass]="filledInfo(document.personalInfo)" (click)="showInfo(document)" class="icon-btn" id="greenZone" type="button">
                <img class="icon" title="{{'seeDataTooltip' | i18next}}" src="../../../../assets/icons/eye.png" alt="{{'altSee' | i18next}}">
              </button>
              <button *ngIf="document.documentType === 'TRAFFIC_FINE'" [ngClass]="notFilled(document.personalInfo)" (click)="editDocument(document)" class="icon-btn" type="button">
                <img class="icon" title="{{'editDataTooltip' | i18next}}" src="../../../../assets/icons/pencil-16.svg" alt="{{'altEdit' | i18next}}">
              </button>
              <button class="icon-btn" type="button" (click)="downloadDocument(document.fileName)">
                <img class="icon" title="{{'downloadTooltip' | i18next}}" src="../../../../assets/icons/download-16.svg" alt="{{'altDownload' | i18next}}">
              </button>
              <button *ngIf="admin" class="icon-btn" type="button" (click)="deleteDocument(document)">
                <img class="icon" title="{{'deleteTooltip' | i18next}}" src="../../../../assets/icons/trash-16.svg" alt="{{'altDelete' | i18next}}">
              </button>
            </td>
          </tr>
        </ng-container>
        
      </tbody>
    </table>
  </div>
  <div class="documentation--btns">
    <div>
      <button (click)="requestZoneCertificate()" [disabled]="certZoneInvalid || certData || !hasPlateNumber" class="btn confirm bgColor" type="button">{{'parkZoneCertBtn' | i18next}}</button>
      <div class="certData" *ngIf="certData && !certZoneInvalid">
        <p class="certData--title">{{'certZoneData' | i18next}}</p>
        <div class="certData--container">
          <p class="certData--label">{{'fullnameResume' | i18next}}</p>
          <p class="certData--text">{{certData.fullName}}<p>
        </div>
        <div class="certData--container">
          <p class="certData--label">{{'addressResume' | i18next}}</p>
          <p class="certData--text">{{certData.address}}</p>
        </div>
        <div class="certData--container">
          <p class="certData--label">{{'cityResume' | i18next}}</p>
          <p class="certData--text">{{certData.city}}</p>
        </div>
        <div class="certData--container">
          <p class="certData--label">{{'postalCodeResume' | i18next}} </p>
          <p class="certData--text">{{certData.postalCode}}</p>
        </div>
      </div>
    </div>
    <button *ngIf="admin && addBtn" class="btn confirm bgColor" (click)="openAddDocument()">{{'addDocuments' | i18next}}</button>
  </div>
</fieldset>