<div class="title">
  {{ title }}
</div>
<div class="grid">
  <div class="row content-header">
    <div class="th" *ngFor="let th of headersTable">
      {{ th }}
    </div>
    <div *ngIf="options?.actionButton">
      {{ options?.actionText }}
    </div>
    <div *ngIf="edit"></div>
  </div>
  <div *ngFor="let campaign of items" class="row">
    <div>{{ campaign.id }}</div>
    <div class="fixed-size">{{ campaign.title }}</div>
    <div>{{ campaign.model }}</div>
    <div>{{ campaign.remainingStock }}</div>
    <div>{{ campaign.startDate | date }}</div>
    <div>{{ campaign.endDate | date }}</div>
    <div>{{ campaign.profiles }}</div>
    <div class="icon-btn" *ngIf="campaign.urlImage !== null">
      <img class="icon" src="../../../../assets/icons/check-16.svg" alt="check icon" />
    </div>
    <div class="icon-btn" *ngIf="campaign.urlImage === null">
      <img class="icon" src="../../../../assets/icons/minus-16.svg" alt="minus icon" />
    </div>
    <div *ngIf="options?.actionButton">
      <ui-switch
        class="switch"
        [checked]="campaign.isEnabled"
        (change)="onToggle($event, campaign)"
        [disabled]="campaign.isOutOfTimeRange && campaign.isEnabled === false"
      >
      </ui-switch>
    </div>
    <div class="icon-btn" *ngIf="edit"  (click)="onEdit(campaign)" title="Editar">
      <img class="icon" src="../../../../assets/icons/pencil-16.svg" alt="edit icon">
    </div>
  </div>
</div>
