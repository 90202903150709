import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private storageService: StorageService,
    private router: Router,
  ) { }
  
  isAuthorizedUser = false;

  isAuthorized() : boolean {
    this.isAuthorizedUser = this.storageService.get('token') ? true : false;
    return this.isAuthorizedUser;
  }

  logout(): void {
    this.storageService.removeItem('token');
    this.router.navigateByUrl('/login');
  }
}
