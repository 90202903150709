import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent  {
  @Input() text! : string;
  @Input() redirect? : boolean;
  @Input() list? = false;
  @Input() cancelOrder = false;
 
  @Output() confirmCancelOrderEmitter = new EventEmitter<boolean>();
  constructor( private activeModal : NgbActiveModal) { }

  closeModal() {
    this.activeModal.close();
  }

  confirmCancel() {
    this.activeModal.close();
    this.confirmCancelOrderEmitter.emit(true);
  }
}
