<div class="confidentiality">
  <section class="confidentiality--container">
    <h1 class="home--info__title">{{'title' | i18next}}</h1>
    <p class="confidentiality--text">{{'confidentialityText' | i18next}}<span class="confidentiality--text__highligthed">{{'confidentialityHighligthed' | i18next}}</span> <br> {{'confidentialityText2' | i18next}}</p>
    <button routerLink="/landing" autofocus class="confidentiality--btn btn" type="button" >{{'btnContinue' | i18next}}</button>
  </section>

  <section class="confidentiality--image">
    <div class="img"></div>
  </section>
</div>