<section class="section-listTable">
  <div class="text-center spinner-container" *ngIf="spinner">
    <div class="spinner-border spinner" role="status"></div>
  </div>
  <table class="table table-responsive table-striped table-hover" *ngIf="!empty && requests && !spinner">
    <thead>
      <tr>
        <th scope= "col" class="header"></th>
        <th scope= "col" class="header" *ngIf="admin">{{ 'requestTableNumberRequest' | i18next }}</th>
        <th scope= "col" class="header" *ngIf="admin">{{ 'requestTableTmaimg' | i18next }}</th>
        <th scope= "col" class="header" *ngIf="admin">{{ 'requestTableFrameNumber' | i18next }}</th>
       <!--  <th scope= "col" class="header">{{ 'requestTableRequestState' | i18next }}</th> -->
        <th scope= "col" class="header">{{ 'requestTableVehicleModel' | i18next }}</th>
        <th scope= "col" class="header">{{ 'plateNumber' | i18next }}</th>
        <th scope= "col" class="header">{{ 'requestTableRequestType' | i18next }}</th>
        <th scope= "col" class="header">{{ 'requestTableRequestRetribution' | i18next }} </th>
        <th scope= "col" class="header">{{ 'requestTableRequestDate' | i18next }}</th>
        <th scope= "col" class="header">{{ 'requestTableDeliveryDate' | i18next }}</th>
        <th scope= "col" class="header"></th>
      </tr>
    </thead>
  
    <tbody>
      <ng-container *ngFor="let request of requests | paginate: {itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems}">
        <tr>
          <td class="alert-container">
            <div class="alert" >
              <img *ngIf="request.alert" title="{{'pendingInfoTooltip' | i18next}}" class="alert--icon" src="../../../../assets/icons/error-16.svg" alt="{{'altAlert' | i18next}}">
            </div>
          </td>
          <td  *ngIf="admin">{{ request.requestNumber || '-' }}</td>
          <td  *ngIf="admin">{{ request.tmaimg || '-' }}</td>
          <td  *ngIf="admin">{{ request.frame || '-' }}</td>
          <!-- <td>{{ request.requestState || '-'}}</td> -->
          <td>{{ request.vehicleModel || 'Modelo en proceso'}}</td>
          <td>{{ request.plateNumber || '-' }}</td>
          <td>{{ request.requestType || '-'}}</td>
          <td>{{ request.retribution || '-'}}</td>
          <td>{{ (request.creationDate | date) || '-'}}</td>	
          <td>{{ (request.deliveryDate | date) || '-'}}</td>
          <td>
            <button *ngIf="request.requestNumber" class="detailBtn" (click)="getOrder(request.requestNumber, request.nis, request.certificate, request.phone, request.bookingId)">{{'requestTableDetail' | i18next}}</button> 
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <pagination-controls *ngIf="!empty && requests && pagination && !spinner" class="pagination" nextLabel="Siguiente" previousLabel="Anterior" (pageChange)="changePage($event)"></pagination-controls>

</section>