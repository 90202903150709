import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import i18next from 'i18next';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Output() confirmModalEmitter = new EventEmitter<boolean>();
  
  form : FormGroup = this.fb.group({
    sendEmailCheck : false
  });

  modalText = '';

  constructor(private activeModal: NgbActiveModal,
              private router : Router,
              private fb : FormBuilder) { }

  ngOnInit(): void {
    this.modalText = i18next.t('orderDoubleCheck');
  }
  openModal() {    
    this.activeModal.close();
    this.confirmModalEmitter.emit(this.form.value.sendEmailCheck);
  }
  closeModal() {
    this.activeModal.close();
    this.router.navigateByUrl('/new-request');
  }
}
