import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'src/app/core/services/order.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { User } from 'src/app/shared/model/user.model';
import i18next from 'i18next';
import { Router } from '@angular/router';
import { DeleteUpdateModalComponent } from './delete-update-modal/delete-update-modal.component';
import { FeedbackModalComponent } from 'src/app/shared/components/feedback-modal/feedback-modal.component';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  spinnerSubmit = false;
  spinner = true;
  user!: User;
  bookInfo: any;
  pattern = '[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[.]+[a-zA-Z]{2,4}$';
  inputError = false;
  showError = false;
  my!: number;
  tmaimg!: string;
  requestNumber!: string;
  orderInfo?: any;

  title = i18next.t('bookTitle');
  subtitle = false;

  constructor( private storageService : StorageService,
               private orderService : OrderService,
               private router: Router,
               private fb: FormBuilder,
               private modalConfig: NgbModal) 
  { }

  ngOnInit(): void {
    if (this.storageService.get('bookInfo')) {
      this.bookInfo = this.storageService.get('bookInfo');
      
    } else {
      setTimeout(() => {
        this.router.navigateByUrl('/list-request');
      },0);
    }
    this.requestNumber= this.bookInfo?.requestNumber;

    this.getDetail();
  }

  form: FormGroup = this.fb.group({});

  initForm () : void {
    if (this.storageService.get('bookInfo') && this.orderInfo.bookedOrderDataDTO) {
      this.title = i18next.t('bookUpdateTitle');
      this.subtitle = true;
    } else {
      this.subtitle = false;
    }
    if(this.orderInfo?.bookedOrderDataDTO) {
      this.form = this.fb.group({
        petitionerType: ['', [Validators.required]],
        name: [this.orderInfo?.bookedOrderDataDTO.name, [Validators.required]],
        firstSurname: [this.orderInfo?.bookedOrderDataDTO.firstSurname, [Validators.required]],
        secondSurname: [this.orderInfo?.bookedOrderDataDTO.secondSurname, [Validators.required]],
        telephone: [this.orderInfo?.bookedOrderDataDTO.telephone, [Validators.required, Validators.minLength(9)]],
        email: [this.orderInfo?.bookedOrderDataDTO.email, [Validators.required, Validators.pattern(this.pattern)]]
      });
      if (this.orderInfo.bookedOrderDataDTO.petitionerType === 'Empleado SEAT') {
        this.form.controls['petitionerType'].setValue('PETITIONER_TYPE_1');
      }else if (this.orderInfo.bookedOrderDataDTO.petitionerType === 'Externo') {
        this.form.controls['petitionerType'].setValue('PETITIONER_TYPE_2');
      } else {
        this.form.controls['petitionerType'].setValue('PETITIONER_TYPE_3');
      }
    } else {
      this.form = this.fb.group({
        petitionerType: ['', [Validators.required]],
        name: ['', [Validators.required]],
        firstSurname: ['', [Validators.required]],
        secondSurname: ['', [Validators.required]],
        telephone: ['', [Validators.required, Validators.minLength(9)]],
        email: ['', [Validators.required, Validators.pattern(this.pattern)]]
      });
    }
  }


  showFormError () {
    if ((this.form.controls['name'].touched && this.form.controls['name'].invalid) ||
        (this.form.controls['firstSurname'].touched && this.form.controls['firstSurname'].invalid) || 
        (this.form.controls['secondSurname'].touched && this.form.controls['secondSurname'].invalid) ||
        (this.form.controls['telephone'].touched && this.form.controls['telephone'].invalid) ||
        (this.form.controls['email'].touched && this.form.controls['email'].invalid)) {
          this.showError = true;
    } else {
      this.showError = false;
    }
  }

  changePetitioner() {
    this.inputError = false;
  }

  getDetail() {
    if (this.bookInfo) {
      this.orderService.getDetailRequest(this.requestNumber, this.bookInfo.bookingId).subscribe({
        next: (resp) => { 
          this.spinner = false;
          this.orderInfo = resp;
          this.user = this.orderInfo.petitionerInfo; 
          this.initForm();
        },
        error: () => {
          this.spinner = false;
        }
      });
    }
  }

  submitBook() {
    this.form.markAllAsTouched();

    if ( this.form.valid) {
      this.spinnerSubmit = true;
      this.inputError = false;
      this.showError = false;
      if(!this.bookInfo?.bookingId) {
        this.orderService.bookVehicle(this.requestNumber, this.form.value).subscribe({
          next: () => {
            const modalRef = this.modalConfig.open(FeedbackModalComponent, {
              centered: true
            });
            modalRef.componentInstance.text = i18next.t('bookFeedbackOk');
            modalRef.componentInstance.redirect = true;
            modalRef.componentInstance.list = true;
            this.spinnerSubmit = false;
          },
          error: () => {
            const modalRef = this.modalConfig.open(FeedbackModalComponent, {
              centered: true
            });        
            modalRef.componentInstance.text = i18next.t('bookFeedbackKo');
            this.spinnerSubmit = false;
          }
        });
      } else {
        const bookObj = {
          ...this.form.value,
          creationDate: this.orderInfo.bookedOrderDataDTO.creationDate,
          id:this.bookInfo.bookingId
        };
        const modalRef = this.modalConfig.open(DeleteUpdateModalComponent, { centered: true, });
        modalRef.componentInstance.type = 'update';
        modalRef.componentInstance.updateEmitter.subscribe((resp: boolean) => {
          if (resp === true) {
            this.orderService.bookVehicle(this.requestNumber, bookObj).subscribe({
              next: () => {
                const modalRef = this.modalConfig.open(FeedbackModalComponent, {
                  centered: true
                });
                modalRef.componentInstance.text = i18next.t('bookUpdateOk');
                modalRef.componentInstance.redirect = true;
                modalRef.componentInstance.list = true;
                this.spinnerSubmit = false;
              },
              error: () => {
                const modalRef = this.modalConfig.open(FeedbackModalComponent, {
                  centered: true
                });
                modalRef.componentInstance.text = i18next.t('bookUpdateKo');
                this.spinnerSubmit = false;
              }
            });
        }
      });
      }
    } else {
      this.form.controls['petitionerType'].invalid ? this.inputError = true : this.inputError = false;
    }
  }
}
