import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import i18next from 'i18next';
@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.scss']
})


export class OrderModalComponent implements OnInit {
  
  @Input() modalData = false;
  @Output() closeModalEmitter = new EventEmitter<void>();
  @Output() spinnerModalEmitter = new EventEmitter<boolean>(false);

  modalText = '';
  
  constructor(public activeModal: NgbActiveModal,
              private router : Router) { }

  ngOnInit(): void {
    this.openModal();
  }

  openModal() {
    this.modalData === true ? this.modalText = i18next.t('orderSuccess') : this.modalText = i18next.t('orderFailed'); 
  }
  
  closeModal(){
    this.activeModal.close();
    if (this.modalData === false) {
      this.router.navigateByUrl('/new-request');
    } else {
      this.closeModalEmitter.emit();
      this.spinnerModalEmitter.emit(true);
    }
  }

}
