import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-feedback',
  templateUrl: './modal-feedback.component.html',
  styleUrls: ['./modal-feedback.component.scss']
})
export class ModalFeedbackComponent {
  @Input() modalData! : string;
  
  constructor( private activeModal : NgbActiveModal) { }

  closeModal() {
    this.activeModal.close();
  }
}
