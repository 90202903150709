<div class="center-container">
  <div class="content-icon">
    <fa-icon [icon]="iconCheck" class="icon"></fa-icon>
  </div>
  <div *ngIf="info.msgCategory === 'campaign'" class="content-text">
    <div class="content-message">
      {{'backofficeFinnalyTitle1' | i18next}} <span class="bold">{{ info.title }}</span> {{'backofficeFinnalyTitle2' | i18next}}
    </div>
    <div class="content-id">{{'id' | i18next}}{{ info.campaignId }}</div>
  </div>
  <div *ngIf="info.msgCategory === 'users'" class="content-text">
    <div class="content-message">
      {{'backofficeUsersSuccess' | i18next}}
    </div>
  </div>
  <div *ngIf="info.msgCategory === 'authorizers'" class="content-text">
    <div class="content-message">
      {{'backofficeAuthorisedSuccess' | i18next}}
    </div>
  </div>
  <div class="content-button">
    <div class="btn confirm bgColor" [routerLink]="['/backoffice']">{{'btnAccept' | i18next}}</div>
  </div>
</div>
