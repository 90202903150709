import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18NextModule } from 'angular-i18next';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';

import { AutoFocusDirective } from './directives/auto-focus.directive';

import { TableComponent } from './components/table/table.component';
import { DragDropComponent } from './components/drag-drop/drag-drop.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { EquipmentListComponent } from './components/equipment-list/equipment-list.component';
import { InfoPetitionerComponent } from './components/info-petitioner/info-petitioner.component';
import { CarConfiguratorComponent } from './components/car-configurator/car-configurator.component';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18NextModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    NgbTooltipModule,
    NgxPaginationModule,
    DragDropComponent,      
    FormsModule,
    ReactiveFormsModule, 
    TableComponent                        
  ],
  declarations: [
    AutoFocusDirective,
    NavbarComponent,
    EquipmentListComponent,
    InfoPetitionerComponent,
    CarConfiguratorComponent,
    FeedbackModalComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    I18NextModule,
    NgxSpinnerModule,
    NgbTooltipModule,
    FontAwesomeModule,
    TableComponent,
    NavbarComponent,
    InfoPetitionerComponent,
    EquipmentListComponent,
    CarConfiguratorComponent
  ]
})
export class SharedModule { }
