import { Component } from '@angular/core';
import { NavigationService } from './core/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  header = true;
  footer = true;

  constructor(navigation: NavigationService) {
    navigation.data.subscribe(data => {
      if ('header' in data) { this.header = data['header']; }
      if ('footer' in data) { this.footer = data['footer']; }
    });
  }
}
