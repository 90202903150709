import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { StorageService } from 'src/app/core/storage/storage.service';
import getCommonPondOptions from './config/common-pond-options';

@Component({
  selector: 'app-uploader-file',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @ViewChild('filePondUpload') filePondUpload: any;
  @Input() serverURL!: string;
  @Input() msgCategory!: string;
  @Input() header!: string;

  @Output() errorEmmitter = new EventEmitter<ErrorCustom[]>();

  errorMessage?: ErrorCustom[];
  errors = [];
  pondOptions : any;
  pondFiles = [];
  isProcessFile = true;
  btnCancelStatus = false;
  token = '';

  constructor(private storage: StorageService) {}

  ngOnInit() {
    this.storage.get('token')? this.token =  this.storage.get('token').token : this.token = '';
    this.pondOptions = getCommonPondOptions(
      this.serverURL,
      this.onload.bind(this),
      this.token
    );
  }

  onload(resp:any) {
    resp.data = {
      ...resp.data,
      msgCategory: this.msgCategory
    };
    this.storage.set('info', resp.data);
    this.errorMessage = [];
    this.isProcessFile = false;
    this.btnCancelStatus = true;
  }

  onprocessfilestart() {
    this.btnCancelStatus = true;
  }

  onerror(error: any) {
    this.errorMessage = error.error.body.data.errors;
    this.btnCancelStatus = false;
    this.errorEmmitter.emit(this.errorMessage);
  }
}

interface ErrorCustom {
  name: string,
  value: string;
}
