import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Output() getDocumentsEmitter = new EventEmitter<boolean>();
  
  constructor(private activeModal : NgbActiveModal) {}
  
  closeModal() { 
    this.activeModal.close();
  }
  confirmDelete() {
    this.activeModal.close();
    this.getDocumentsEmitter.emit(true);
  }
}
