import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, Observable } from 'rxjs';
import { OrderResponse, Status } from 'src/app/shared/model/request-list.model';
import { FilterSearch, AdminSearch } from 'src/app/shared/model/admin.model';
import { RequestCertificateData } from 'src/app/shared/model/documentation.model';

@Injectable({
  providedIn: 'root'
})

export class OrderService {  
  urlOrdersLegacy = environment.env === 'dev' ? 'https://order-service' + `${environment.API_CONTEXT}/order-service/v1/orders-legacy` : `${environment.API_CONTEXT}/order-service/v1/orders-legacy`;
  urlOrdersLite = environment.env === 'dev' ? 'https://order-service' + `${environment.API_CONTEXT}/order-service/v1/orders-lite` : `${environment.API_CONTEXT}/order-service/v1/orders-lite`;
  urlOrderService = environment.env === 'dev' ? 'https://order-service' + `${environment.API_CONTEXT}/order-service/v1` : `${environment.API_CONTEXT}/order-service/v1`;
  constructor( private http : HttpClient) {}

  

  showOrdersList(requestGroup: string): Observable<OrderResponse[]>{
    const params = new HttpParams().set('requestGroup', requestGroup);
    const headers=  new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.http.get<OrderResponse[]>(
      `${this.urlOrdersLegacy}`,
      {params: params,  headers}
      ); 
  } 

  getDetailRequest(requestNumber: string, bookingId?: number | null) {
    const bodyParams = {
      requestNumber,
      bookingId
    };
    return this.http.post(
      `${this.urlOrdersLegacy}/order-info`,
      bodyParams
    );
  }

  getDetailStates(requestNumber: string): Observable<Status> {
    const params = new HttpParams().set('requestNumber', requestNumber);
    return this.http.get<Status>(
      `${this.urlOrdersLegacy}/order-info/states`,
      {params: params}
    );
  }

  getFilteredData(filterObj: FilterSearch, data: boolean) : Observable<AdminSearch> {
    const headers=  new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Content-Type': 'application/json; charset=utf-8'
    });
    const params = new HttpParams().set('export', data);
   return this.http.post<AdminSearch>(
      `${this.urlOrdersLite}/filters`,
      filterObj,
      {params: params, headers},
    ).pipe(
      tap(resp =>  {
        return resp;
      })
    );
  }

  getRequestWithAlert() {
    return this.http.get(
      `${this.urlOrderService}/orders-alert`
    );
  }

  requestCertificate(requestCertificateData: RequestCertificateData) {
    const bodyParams = requestCertificateData;
    return this.http.post(
      `${this.urlOrderService}/certificates`,
      bodyParams
    );
  }

  bookVehicle(requestNumber: string, bookObj: any) {
    const params = new HttpParams().set('requestNumber', requestNumber);
    return this.http.post(
      `${this.urlOrderService}/books`,
      bookObj,
      {params}
    );
  }

  deleteBookedVehicle(bookingId: string, requestNumber: string) {
    const bodyParams = { bookingId, requestNumber};
    return this.http.delete(
      `${this.urlOrderService}/books`,
      {body: bodyParams}
    );
  }

  cancelRequest(requestNumber: string, nis: string) {
    const bodyParams = {requestNumber, nis};
    return this.http.post(
      `${this.urlOrdersLegacy}/cancel-pss`,
      bodyParams
    );
  }
}