import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import i18next from 'i18next';
import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';

@Component({
  selector: 'app-modal-blocked',
  templateUrl: './modal-blocked.component.html',
  styleUrls: ['./modal-blocked.component.scss']
})
export class ModalBlockedComponent implements OnInit {
  @Input() modalData! : string;
  
  isPss = true;
  hasNoTelf = false;
  isBlocked = false;
  constructor( private activeModal: NgbActiveModal,
               private modalConfig: NgbModal) { }

  ngOnInit(): void {
    if (this.modalData === 'Código de error 5003: No se puede continuar ya que no dispone de ningún teléfono informado. Póngase en contacto con RRHH.') {
      this.hasNoTelf = true;
      this.isBlocked = false;
    } else if (this.modalData === 'Código de error 5010: Se ha superado el número máximo de intentos de acceso a tu usuario y se ha bloqueado. Debes reestablecer la contraseña para desbloquear tu usuario pulsando.') {
      this.isBlocked = true;
    } else {
      this.hasNoTelf = false;
      this.modalData === i18next.t('loginNoPss') ? this.isPss = false : this.isPss = true;
    }
  }
  navigateToResetPass() {
    this.activeModal.close();
    this.modalConfig.open(ForgotPasswordModalComponent, { windowClass: 'forgot-password', centered: true, backdrop : 'static'});
  }
  closeModal(){
    this.activeModal.close();
  }
}
