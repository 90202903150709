import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IsNotAuthorizedGuard } from '../core/guard/is-not-authorized.guard';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { RegisterComponent } from './register/register.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { EmailVerificationComponent } from './register/email-verification/email-verification.component';
import { MfaAuthenticationComponent } from './register/mfa-authentication/mfa-authentication.component';
import { ConfirmNewPasswordComponent } from './confirm-new-password/confirm-new-password.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { ConfidentialityComponent } from './confidentiality/confidentiality.component';
import { AccessLoginComponent } from './access-login/access-login.component';
import { ListRequestComponent } from './list-request/list-request.component';
import { AdministratorComponent } from './administrator/administrator.component';
import { IsAdminGuard } from '../core/guard/is-admin.guard';
import { HandleAdminUsersComponent } from './administrator/handle-admin-users/handle-admin-users.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { SummaryComponent } from './campaigns/summary/summary.component';
import { FeedbackComponent } from './campaigns/summary/feedback/feedback.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { OrdersComponent } from './backoffice/orders/orders.component';
import { UploadComponent } from './backoffice/upload/upload.component';
import { CampaignComponent } from './backoffice/upload/campaign/campaign.component';
import { AuthorizersComponent } from './backoffice/upload/authorizers/authorizers.component';
import { FinallyComponent } from './backoffice/upload/finally/finally.component';
import { ImageComponent } from './backoffice/upload/image/image.component';
import { UsersComponent } from './backoffice/upload/users/users.component';
import { BookComponent } from './book/book.component';
import { SmsAuthenticationComponent } from './register/sms-authentication/sms-authentication.component';
import { DocumentationInfoComponent } from './documentation-info/documentation-info.component';
@Component({ template: '' })
export class InitComponent {}

const routes: Routes = [
  { path: 'access-login', component: AccessLoginComponent, data: { header: false, footer: false } },
  { path: 'administrator', component: AdministratorComponent, canActivate: [IsAdminGuard], data:{ header: true, footer: true} },
  { path: 'backoffice', component: BackofficeComponent, canActivate: [IsAdminGuard], data:{ header: true, footer: true, uploadCampaign: true, uploadAuthorizers: true , page: 'campaigns'} },
  { path: 'backoffice/backoffice-orders', component: OrdersComponent, canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true, uploadCampaing: false, uploadAuthorizers: false, page: 'orders'}},
  { path: 'upload', component: UploadComponent, canActivate: [IsNotAuthorizedGuard], 
    children: [
      { path: 'campaigns', redirectTo: 'campaign', pathMatch: 'full' },
      { path: 'authorizers', redirectTo: 'authorizers', pathMatch: 'full' },
      {
        path: 'campaign',
        component: CampaignComponent,
        data: { url: 'campaign' }
      },
      {
        path: 'authorizers',
        component: AuthorizersComponent,
        data: { url: 'authorizers' }
      },
      {
        path: 'image',
        canActivate: [IsNotAuthorizedGuard],
        component: ImageComponent,
        data: { url: 'image' }
      },
      {
        path: 'users',
        component: UsersComponent,
        data: { url: 'users' }
      },
      {
        path: 'finally',
        canActivate: [IsNotAuthorizedGuard],
        component: FinallyComponent,
        data: { url: 'finally' }
      }
    ]
  },
  { path: 'campaigns', component: CampaignsComponent, data: {header: true, footer: true}},
  { path: 'campaigns-summary', component: SummaryComponent, data: {header: true, footer: true}},
  { path: 'confidentiality', component: ConfidentialityComponent, canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true}},
  { path: 'confirm-new-password', component: ConfirmNewPasswordComponent, data: { header: false, footer: false }},
  { path: 'handleAdminUsers', component: HandleAdminUsersComponent, canActivate: [IsAdminGuard], data:{ header: true, footer: true} },
  { path: 'landing', component: LandingComponent , canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true}  },
  { path: 'login', component: LoginComponent, data: { header: false, footer: false } },
  { path: 'logout', redirectTo: '/login', pathMatch: 'full' },
  { path: 'new-request', component: NewRequestComponent , canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true} },
  { path: 'register', component: RegisterComponent, data: { header: false, footer: false }},
  { path: 'register/verification-code', component: EmailVerificationComponent, data: { header: false, footer: false } },
  { path: 'register/mfa-authentication', component: MfaAuthenticationComponent, data: {header: false, footer: false} },
  { path: 'register/sms-authentication', component: SmsAuthenticationComponent, data: {header: false, footer: false} },
  { path: 'new-request', component: NewRequestComponent , canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true} },
  { path: 'list-request', component: ListRequestComponent, canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true} },
  { path: 'order-summary/:requestNumber', component: OrderSummaryComponent, canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true}},
  { path: 'booking', component: BookComponent, canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true}},
  { path: 'confidentiality', component: ConfidentialityComponent, canActivate: [IsNotAuthorizedGuard], data: { header: true, footer: true}},
  { path: 'administrator', component: AdministratorComponent, canActivate: [IsAdminGuard], data:{ header: true, footer: true} },
  { path: 'handleAdminUsers', component: HandleAdminUsersComponent, canActivate: [IsAdminGuard], data:{ header: true, footer: true} },
  { path: 'campaigns', component: CampaignsComponent, data: {header: true, footer: true}},
  { path: 'campaigns-summary', component: SummaryComponent, data: {header: true, footer: true}},
  { path: 'summary/finally', component: FeedbackComponent, data: {header: true, footer: true}},
  { path: 'documentation', component: DocumentationInfoComponent,  data: {header: false, footer: true}},
  { path: '', redirectTo: '/access-login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [ InitComponent ],
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [ RouterModule ]
})
export class ViewsRoutingModule { }
