import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/core/storage/storage.service';
import { User } from '../../model/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() data!: string;
  user! : User;
  prod!: boolean;
  isAd?: boolean;
  isMasterUser = false;
  masterUser = '000000';
  

  constructor( private router : Router,
               private storageService: StorageService
  ) {}

  ngOnInit(): void {
    if (this.storageService.get('config')) {
     this.user = this.storageService.get('config'); 
     this.isAd = this.storageService.get('config').isAd;
     this.isMasterUser = this.user.nis === this.masterUser;
    } 
    environment.env === 'prod' ? this.prod = true : this.prod = false; 
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

}
