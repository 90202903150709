import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import i18next from 'i18next';
import { CampaignsService } from 'src/app/core/services/campaigns.service';
import { download } from 'src/utils/Files';

@Component({
  selector: 'app-year-modal',
  templateUrl: './year-modal.component.html',
  styleUrls: ['./year-modal.component.scss']
})
export class YearModalComponent implements OnInit {

  selectedYear?: number;
  years: number[] = [];  
  actualYear!: number;
  year = '';
  constructor( private fb: FormBuilder,
               private campaignsService: CampaignsService,
               private modalConfig: NgbActiveModal) 
  { }
  
  form!: FormGroup;
  

  ngOnInit(): void {
    this.actualYear = new Date().getFullYear();
    this.selectedYear = this.actualYear;
    for (let year = this.selectedYear; year >= 2000; year--) {
      this.years.push(year);
    }
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      year: [this.actualYear],
      all: false
    });
    this.year = this.form.value.year;
  }

  changeCheckInput() {
    if(this.form.value.all) {
      this.form.value.year = '';
      this.year = i18next.t('all');
    } else {
      this.year = this.form.value.year;
    }
  }

  changeYearInput() {
    this.form.value.all = false;
    this.year = this.form.value.year;
  }

  downloadFile(data: ArrayBuffer) {
    let filename = '';
    this.form.value.year ? filename = `Stock${this.form.value.year}_${new Date().toLocaleDateString().slice(0,10)}` : filename = `Stock_TODOS_${new Date().toLocaleDateString().slice(0,10)}`;
    const blob = new Blob([new Uint8Array(data)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    download(blob, 'xlsx', filename);
  }

  downloadStock() {
    const value = this.form.value.all ? null : this.form.value.year;
    this.campaignsService.downloadVehiclesOrders(value).subscribe({
      next: (file) => {
        this.downloadFile(file);
      }
    });
    
    this.modalConfig.close();
  }

  close() {
    this.modalConfig.close();
  }
}
