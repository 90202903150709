<div class="box" *ngFor="let stock of trimLevels">
  <div class="content-name">
    {{ trimLevelName }}
    <span class="model-year">{{ 'stockModelYear' | i18next }} {{ stock.modelYear }}</span>
  </div>
  <div class="content-info">
    <div class="content-motor mb-1">{{ stock.motorInfo }}</div>
    <div class="content-icons mb-1">
      <div class="content-engine">
       <fa-icon class="icon" [icon]="iconTransmision"></fa-icon>
        <div class="info">{{ stock.transmission }}</div>
      </div>
      <div class="content-proof">
        <img src="./assets/icons/proof.svg" alt="..." >
        <div class="info">{{ stock.co2 }} g/km</div>
      </div>
    </div>
    <div class="content-base-fee mb-1">
      <div class="title">{{ 'baseFee' | i18next }}:</div>
      <div class="content-price">
        <div class="price">{{ stock.baseFee | number: '1.2' }}€</div>
        <div class="text-month">
          {{ 'perMonth' | i18next }}
        </div>
        <img src="./assets/icons/info-circle.png" [ngbTooltip]="tooltipContent" [autoClose]="'outside'" triggers="click"
          tooltipClass="custom-tooltip" alt="...">
        <ng-template #tooltipContent>
          <div class="text py-1"><span>{{ 'increment1' | i18next }}:</span> {{ stock.increaseB | number: '1.2' }}€</div>
          <div class="text py-1"><span>{{ 'increment2' | i18next }}:</span> {{ stock.increaseC | number: '1.2' }}€</div>
          <div class="text py-1"><span>{{ 'primaryRE' | i18next }}:</span>
            {{ stock.primaryRE | number: '1.2' }}€
          </div>
          <div class="text py-1"><span>{{ 'secondaryRE' | i18next }}:</span>
            {{ stock.secondaryRE | number: '1.2' }}€
          </div>
          <div class="text py-1"><span>{{ 'primaryEndowmentRE' | i18next }}:</span>
            {{ stock.primaryEndowmentRE | number: '1.2' }}€
          </div>
        </ng-template>
      </div>
    </div>
    <div class="content-equipment">
      <div class="content-colours" *ngIf="stock.colours">
        {{ stock.colours.length > 1 ? ('countAvailableColours_plural' | i18next: { count: stock.colours.length }) : stock.colours[0].description }}
      </div>
      <ul class="equipment-list">
        <li *ngFor="let equipment of stock.equipmentsVisible" class="equipment-item">{{ equipment.description }}</li>
      </ul>
      <div class="content-more">
        <div class="num-packs">
          <span *ngIf="stock.equipmentsVisible && stock.equipments.length > 6">+ {{ stock.equipments.length - stock.equipmentsVisible.length }}
            packs</span>
        </div>
       <div class="btn-more" (click)="openEquipment(stock)">
          {{ 'seeMore' | i18next }}
        </div> 
      </div>
    </div>
    <div *ngIf="stock.colours" class="btn confirm btn-details" (click)="checkCarLease(stock.colours, stock)">{{ 'vehicleDetail' | i18next }}</div>
  </div>
</div>
