<header>
  <img class="logo" src="assets/img/seat-logo-horizontal.png" alt="{{'altLogo' | i18next}}">
</header>
<main class="register">
  <h2 class="title">{{'userCreation' | i18next}}</h2>
  <form [formGroup]="registerForm" class="form">
    <label  class="form--label" for="nis">{{'nis' | i18next}}</label>
    <input class="form--input" 
           type="text"
           required
           placeholder="{{'nis' | i18next}}"
           formControlName="nis"
           #autofocus/>
    <span *ngIf="controlInput('nis')" class="error-message">
      {{'inputInvalid' | i18next}}
    </span>  
    
    <label  class="form--label" for="password">{{'password' | i18next}}</label>
    <input class="form--input"
           type="password"
           required
           [placeholder]="passwordTranslate"
           formControlName="password"/>
    <p [ngClass]="errorMsg('password')" class="error-text text">{{'passwordPattern' | i18next}}</p> 
    <label  class="form--label" for="repeatPassword">{{'repeatPassword' | i18next}}</label>
    <input class="form--input"
           type="password"
           required
           [placeholder]="passwordTranslate"
           formControlName="repeatPassword"/>
    <p [ngClass]="errorMsg('repeatPassword')" class="error-text text">{{'passwordPattern' | i18next}}</p> 
    <span class="error-message"> {{errorMessage}} </span>
    <div class="btn-container">
      <button type="button" class="btn btn--cancel" [routerLink]="['/login']">{{'btnCancel' | i18next}}</button>
      <button type="submit" class="btn" (click)="onSubmit()" [disabled]="!registerForm.valid">{{'btnAccept' | i18next}}</button>
      <div class="text-center spinner-container" *ngIf="spinner">
        <div class="spinner-border spinner" role="status"></div>
      </div>
    </div>
  </form>
</main>

